import { useEffect, useState } from 'react';

import { DeviceData, DeviceType } from '../../../services/types';
import { createGatawayFields, createRepeatersFields, createTagsFields } from './helpers';
import { LabeledField } from './types';

export function useDeviceDetails(deviceType: DeviceType, data: DeviceData) {
  const [fields, setFields] = useState<LabeledField[] | null>(null);
  useEffect(() => {
    if (deviceType === DeviceType.gateway) {
      setFields(createGatawayFields(data));
    }
    if (deviceType === DeviceType.tag) {
      setFields(createTagsFields(data));
    }
    if (deviceType === DeviceType.repeater) {
      setFields(createRepeatersFields(data));
    }
  }, [data, deviceType]);
  return fields;
}
