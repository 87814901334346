import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { AppButton } from '../../../common/buttons/buttons';
import { TextFieldController } from '../../../common/form-controllers/form-controllers';
import { Modal } from '../../../common/modal/modal';
import { RequestQuery } from '../../../services/react-query-request-names';
import { ErrorResponse, User } from '../../../services/types';
import { addUser, updateUser } from '../api';

const schema = yup
  .object()
  .shape({
    fullName: yup.string().required('required full name'),
    email: yup
      .string()
      .email()
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invlid email address')
      .required('required email'),
  })
  .required();

export interface AddUserModalProps {
  open: boolean;
  initialValues?: {
    email?: string;
    fullName?: string;
  };
  handleClose: () => void;
}
export type UserProps = yup.InferType<typeof schema>;

export const AddUserModal: FC<AddUserModalProps> = ({ handleClose, open, initialValues }) => {
  const { control, handleSubmit, reset } = useForm<UserProps>({
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  const handleUserUpdate = (promise: Promise<User>, successMessage: string) => {
    promise
      .then(async () => {
        enqueueSnackbar(successMessage, { variant: 'success' });
        await queryClient.invalidateQueries(RequestQuery.User);

        handleClose();
      })
      .catch(err => {
        if (err.response) {
          const error = err.response.data as ErrorResponse;
          enqueueSnackbar(error.errors[0].description, { variant: 'error' });
        }
      });
  };

  const onSubmit = (values: UserProps) => {
    if (initialValues) {
      handleUserUpdate(updateUser(values), 'Successfully update user');
    } else {
      handleUserUpdate(addUser(values), 'Successfully add user');
    }
  };
  return (
    <Modal
      handleClose={handleClose}
      open={open}
      title={`${initialValues ? 'Edit' : 'Add new'} user`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <TextFieldController
            control={control}
            disabled={initialValues ? true : false}
            label="E-mail"
            name="email"
          />
          {!initialValues ? (
            <Typography sx={{ fontSize: '12px' }}>
              User password will be sent to this email address
            </Typography>
          ) : null}
          <TextFieldController control={control} label="Full name" name="fullName" />
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
            <AppButton type="submit">Submit</AppButton>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};
