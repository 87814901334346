import { Box } from '@mui/material';

export const StepWrapper = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      aria-labelledby={`step-wrapper-${index}`}
      height="100%"
      hidden={value !== index}
      id={`step-wrapper-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Box sx={{ py: 1, height: '100%' }}>{children}</Box>}
    </Box>
  );
};
