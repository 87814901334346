import { useEffect } from 'react';

import { setInstallationMode } from '../../services/api/device';

export const useDeviceInstallationMode = (deviceId: string | undefined) => {
  useEffect(() => {
    if (deviceId) {
      setInstallationMode(deviceId, true);
    }
    return () => {
      if (deviceId) {
        setInstallationMode(deviceId, false);
      }
    };
  }, [deviceId]);
};
