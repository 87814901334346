import { apiClient } from '../../services/client/axios-client';
import { Filters, Pagination, Sorts, Users } from '../../services/types';

export type GetUsersProps = {
  filters?: Filters;
  sorts?: Sorts;
  pagination?: Pagination;
};

export const getUsers = async ({ sorts, pagination }: GetUsersProps) => {
  const res = await apiClient
    .get<Users>('/users', {
      params: {
        orderBy: sorts?.orderBy?.field?.name,
        orderByDirection: sorts?.orderBy?.direction,
        offset: pagination?.offset,
        limit: pagination?.limit,
      },
    })
    .then(response => response.data);
  return res;
};
