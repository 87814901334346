import { FC } from 'react';
import { Control, Controller, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

interface TextFieldWrapperProps {
  textFieldProps: TextFieldProps;
  error: FieldError | undefined;
  label: string;
  disabled?: boolean;
}

export const TextFieldWrapper: FC<TextFieldWrapperProps> = ({
  textFieldProps,
  error,
  label,
  disabled,
}) => {
  return (
    <TextField
      {...textFieldProps}
      disabled={disabled}
      error={error ? true : false}
      helperText={error?.message}
      InputLabelProps={{ shrink: textFieldProps.value !== undefined ? true : false }}
      label={label}
      variant="outlined"
    />
  );
};

interface TextFieldControllerProps<T extends FieldValues> {
  name: FieldPath<T>;
  label: string;
  control: Control<T>;
  disabled?: boolean;
}

export const TextFieldController = <T extends FieldValues>(props: TextFieldControllerProps<T>) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState: { error } }) => (
        <TextFieldWrapper
          disabled={props.disabled}
          error={error}
          label={props.label}
          textFieldProps={field}
        />
      )}
    />
  );
};
