import { apiClient } from '../client/axios-client';
import {
  GwConfigsInput,
  GwConfigsOutput,
  RepeaterConfigsInput,
  RepeaterConfigsOutput,
  ServerResponse,
  TagConfigsInput,
  TagConfigsOutput,
} from '../types';

const getDeviceConfigs = <T>(deviceId: string) =>
  apiClient
    .get<ServerResponse<T>>(`/devices/${deviceId}/configurations`)
    .then(response => response.data.data);

export const getGwConfigs = (deviceId: string) => getDeviceConfigs<GwConfigsOutput>(deviceId);

export const getTagConfigs = (deviceId: string) => getDeviceConfigs<TagConfigsOutput>(deviceId);

export const getRepeaterConfigs = (deviceId: string) =>
  getDeviceConfigs<RepeaterConfigsOutput>(deviceId);

const setDeviceConfigs = <T>(deviceId: string, configs: T) =>
  apiClient.put(`/devices/${deviceId}/configurations`, configs);

export const setGwConfigs = (deviceId: string, configs: GwConfigsInput) =>
  setDeviceConfigs(deviceId, configs);

export const setTagConfigs = (deviceId: string, configs: TagConfigsInput) =>
  setDeviceConfigs(deviceId, configs);

export const setRepeaterConfigs = (deviceId: string, configs: RepeaterConfigsInput) =>
  setDeviceConfigs(deviceId, configs);
