import { Route, Routes } from 'react-router';

import { DashboardPage } from '../../components/devices/dashboard/dashboard';
import { GatewaysPage } from '../../components/devices/gateways/gateways';
import { RepeatersPage } from '../../components/devices/repeaters/repeaters';
import { TagsPage } from '../../components/devices/tags/tags';
import { SettingsPage } from '../../components/settings/settings';
import { UsersPage } from '../../components/users/users';
import { VersionsManagementPage } from '../../components/versions-management/versions-management';
import { NotFound } from './404-page';
import { PageLayout } from './page-layout';

export const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route element={<PageLayout />} path="/">
          <Route element={<NotFound />} path="*" />
          <Route element={<DashboardPage />} path="/" />
          <Route element={<GatewaysPage />} path="/devices/gateways" />
          <Route element={<TagsPage />} path="/devices/tags" />
          <Route element={<RepeatersPage />} path="/devices/repeaters" />
          <Route element={<VersionsManagementPage />} path="/versions" />
          <Route element={<UsersPage />} path="/users" />
          <Route element={<SettingsPage />} path="/settings" />
        </Route>
      </Routes>
    </>
  );
};
