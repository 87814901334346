import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { FeatureGroup, useMap, useMapEvents } from 'react-leaflet';
import { FeatureGroup as FeatureGroupType } from 'leaflet';

import { MapEntity } from '../map-entity';
import { MapProps, MapRef } from '../types';

type MapEntitiesLayerProps = Required<Pick<MapProps, 'mapEntities' | 'mapConfig'>>;

export const MapEntitiesLayer = forwardRef<MapRef, MapEntitiesLayerProps>(
  ({ mapEntities, mapConfig }, ref) => {
    const featureGroupRef = useRef<FeatureGroupType>(null);
    const [zoomLevel, setZoomLevel] = useState<number>(0);
    const map = useMap();
    const mapEvents = useMapEvents({
      zoomend: () => {
        setZoomLevel(mapEvents.getZoom());
      },
    });

    useImperativeHandle(ref, () => ({
      map,
      featureGroup: featureGroupRef.current as FeatureGroupType,
    }));

    return (
      <FeatureGroup ref={featureGroupRef}>
        {mapEntities.map(mapEntity => (
          <MapEntity
            key={mapEntity.entityKey}
            map={map}
            mapConfig={mapConfig}
            zoomLevel={zoomLevel}
            {...mapEntity}
          />
        ))}
      </FeatureGroup>
    );
  },
);
