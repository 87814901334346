import { GridColDef } from '@mui/x-data-grid';

import {
  batvoltFieldShort,
  inputSignalFieldShort,
  outputSignalFieldShort,
  plotFieldShort,
  statusFieldShort,
} from './common';
import { repeaterIdField } from './repeaters';

const repeatersIdFieldShort: GridColDef = {
  ...repeaterIdField,
  width: 150,
  type: 'customString',
  sortable: false,
  filterable: false,
  align: 'center',
  headerAlign: 'center',
};

export const repeatersColumnsShort: GridColDef[] = [
  repeatersIdFieldShort,
  plotFieldShort,
  statusFieldShort,
  batvoltFieldShort,
  inputSignalFieldShort,
  outputSignalFieldShort,
];
