import { FC, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { AppButton } from '../../../../common/buttons/buttons';
import { TextFieldController } from '../../../../common/form-controllers/form-controllers';
import { LocationButton } from '../../../../common/location-button/location-button';
import { DeviceIdContext } from '../../../../common/store/device-id-context';
import { numberOrEmptyString } from '../../../../schemas/schemas';
import { getDevice } from '../../../../services/api/device';
import { RequestQuery } from '../../../../services/react-query-request-names';
import { ErrorResponse } from '../../../../services/types';
import { updateGateway } from '../api';

type AddGatewayThirdStepProps = {
  handleBack: () => void;
  closeModal: () => void;
};

interface ThirdStepSchema {
  lat?: number;
  long?: number;
}

const schema: yup.SchemaOf<ThirdStepSchema> = yup.object().shape(
  {
    lat: numberOrEmptyString(yup.number()).when('long', (long, schema) => {
      return long ? schema.required('lat is required') : schema;
    }),

    long: numberOrEmptyString(yup.number()).when('lat', (lat, schema) => {
      return lat ? schema.required('long is required') : schema;
    }),
  },
  [['lat', 'long']],
);

export const AddGatewayThirdStep: FC<AddGatewayThirdStepProps> = ({ handleBack, closeModal }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { deviceId } = useContext(DeviceIdContext);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm<ThirdStepSchema>({
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (deviceId) {
      getDevice(deviceId).then(res => {
        setValue('lat', res?.data?.location?.lat);
        setValue('long', res?.data?.location?.long);
      });
    }
  }, [deviceId, setValue]);

  const onSubmit = (values: ThirdStepSchema) => {
    if (deviceId) {
      if (isDirty) {
        updateGateway({
          gateway: { id: deviceId },
          location: { long: values.long, lat: values.lat },
        })
          .then(() => {
            queryClient.invalidateQueries(RequestQuery.DeviceByType);
            enqueueSnackbar('Successfully update coordinates', { variant: 'success' });
            closeModal();
          })
          .catch(err => {
            if (err.response) {
              const error = err.response.data as ErrorResponse;
              enqueueSnackbar(error?.errors ? error?.errors[0]?.description : 'Error 500', {
                variant: 'error',
              });
            }
          });
      } else {
        closeModal();
      }
    }
  };

  const handleLocationClick = useCallback(
    (latitude: number, longitude: number) => {
      setValue('lat', latitude, { shouldDirty: true });
      setValue('long', longitude, { shouldDirty: true });
    },
    [setValue],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 0.1fr' }}>
          <TextFieldController control={control} label="Latitude" name="lat" />
          <TextFieldController control={control} label="Longitude" name="long" />
          <LocationButton onClick={handleLocationClick} />
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
          <AppButton startIcon={<NavigateBeforeIcon />} onClick={handleBack}>
            Back
          </AppButton>
          <AppButton type="submit">Update GW</AppButton>
        </Box>
      </form>
    </Box>
  );
};
