import { FC, useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { CircularProgress, Input, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as yup from 'yup';

import { AppButton } from '../../common/buttons/buttons';
import { TextFieldController } from '../../common/form-controllers/form-controllers';
import { Modal } from '../../common/modal/modal';
import { useFormWithSubmitter } from '../../hooks/use-submit';
import { RequestQuery } from '../../services/react-query-request-names';
import { uploadGatewayVersion } from './api';
import { requiredFileSchema, versionSchema } from './schemas';

export interface UploadVersionModalProps {
  open: boolean;
  title: string;
  currentVersion?: string;
  handleClose: () => void;
}

interface SchemaType {
  versionNumber: string;
  fwFiles: any;
  chipFiles: any;
}

const buildSchema = (currentVersion: string | undefined) => {
  return yup.object({
    versionNumber: versionSchema(currentVersion),
    fwFiles: requiredFileSchema,
    chipFiles: requiredFileSchema,
  });
};

export const UploadGwVersionModal: FC<UploadVersionModalProps> = ({
  open,
  handleClose,
  title,
  currentVersion,
}) => {
  const [schema, setSchema] = useState<yup.SchemaOf<SchemaType>>(buildSchema(currentVersion));

  useEffect(() => {
    setSchema(buildSchema(currentVersion));
  }, [currentVersion]);

  const [isUploading, setIsUploading] = useState(false);
  const queryClient = useQueryClient();
  const submitter = useCallback(
    async (input: SchemaType) => {
      setIsUploading(true);
      try {
        await uploadGatewayVersion(input.fwFiles[0], input.chipFiles[0], input.versionNumber);
        await queryClient.invalidateQueries(RequestQuery.FwVersions);
      } finally {
        setIsUploading(false);
      }
    },
    [queryClient, setIsUploading],
  );
  const { control, register, handleSubmit, onSubmit } = useFormWithSubmitter(
    open,
    handleClose,
    schema,
    submitter,
    'Version successfuly added',
  );

  return (
    <Modal handleClose={handleClose} open={open} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={2} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <TextFieldController control={control} label="Version Number" name="versionNumber" />
          <Box mt={2}>
            <Typography mr={2}>Cellular Firmware</Typography>
            <Input {...register('fwFiles')} type="file" />
          </Box>
          <Box mt={2}>
            <Typography>Radio Firmware</Typography>
            <Input {...register('chipFiles')} type="file" />
          </Box>

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
            <AppButton disabled={isUploading} type="submit">
              {!isUploading ? 'Submit' : <CircularProgress size={20} />}
            </AppButton>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};
