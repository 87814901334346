import { FC, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { SignalBatteryInfo } from '../../../components/devices/tags/add-edit-tag/signal-battery';
import { updateTag } from '../../../components/devices/tags/api';
import { numberOrEmptyString } from '../../../schemas/schemas';
import { getDevice } from '../../../services/api/device';
import { RequestQuery } from '../../../services/react-query-request-names';
import { Device, DeviceType, ErrorResponse, TagsMetadata } from '../../../services/types';
import { AppButton } from '../../buttons/buttons';
import { useDeviceInstallationMode } from '../../hooks/instalation-mode';
import { LabelField } from '../../label-field/label-field';
import { TagGwIdsContext } from '../../store/tag-gw-device-id-context';
import { convertRawDataToSecondStepFormFields } from './helpers';

type AssociateTagSecondStepProps = {
  handleBack: () => void;
  closeModal: () => void;
};

enum SecondStepFieldName {
  'plot' = 'metadata.plot',
  'comment' = 'metadata.comment',
  'lat' = 'location.lat',
  'long' = 'location.long',
}
const secondStepFields = [
  { label: 'Latitude', fieldName: SecondStepFieldName.lat },
  { label: 'Longitude', fieldName: SecondStepFieldName.long },
  { label: 'Plot', fieldName: SecondStepFieldName.plot },
  { label: 'Comment', fieldName: SecondStepFieldName.comment },
];

const schema = yup
  .object()
  .shape({
    gateway: yup.object().shape({
      id: yup.string().optional(),
    }),
    location: yup.object().shape({
      lat: numberOrEmptyString(yup.number().optional()),
      long: numberOrEmptyString(yup.number().optional()),
    }),
    metadata: yup.object().shape({
      plot: yup.string().optional(),
      comment: yup.string().optional(),
    }),
  })
  .required();

export type SecondStepProps = yup.InferType<typeof schema>;

export const AssociateTagSecondStep: FC<AssociateTagSecondStepProps> = ({ closeModal }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    tagGwIds: { tagId, gwId },
  } = useContext(TagGwIdsContext);
  const [rowDeviceData, setRowDeviceData] = useState<Device>();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<SecondStepProps>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (tagId) {
      getDevice(tagId).then(res => {
        setRowDeviceData(res);
        const initialValues = convertRawDataToSecondStepFormFields(res);
        reset({ ...initialValues, gateway: { id: gwId } });
      });
    }
  }, [tagId, reset, setValue, gwId]);

  const onSubmit = (values: SecondStepProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { gateway, ...data } = values;
    if (tagId) {
      if (isDirty) {
        updateTag(data, tagId)
          .then(() => {
            queryClient.invalidateQueries(RequestQuery.DeviceByType);
            enqueueSnackbar('Successfully associate', { variant: 'success' });
            closeModal();
          })
          .catch(err => {
            if (err.response) {
              const error = err.response.data as ErrorResponse;
              enqueueSnackbar(error?.errors ? error?.errors[0]?.description : 'Error 500', {
                variant: 'error',
              });
            }
          });
      } else {
        closeModal();
      }
    }
  };

  useDeviceInstallationMode(tagId);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <LabelField label="GW id"> {gwId} </LabelField>
          <LabelField label="Tag id"> {tagId} </LabelField>
          <SignalBatteryInfo deviceId={tagId} deviceType={DeviceType.tag} />
          <LabelField label="Digital">
            {(rowDeviceData?.data.metadata as TagsMetadata)?.digital}
          </LabelField>
          <LabelField label="Analog1">
            {(rowDeviceData?.data.metadata as TagsMetadata)?.analog1}
          </LabelField>
          <LabelField label="Analog2">
            {(rowDeviceData?.data.metadata as TagsMetadata)?.analog2}
          </LabelField>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {secondStepFields.map(({ label, fieldName }, index) => (
            <Controller
              key={index}
              control={control}
              name={fieldName}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={error ? true : false}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: field.value !== undefined ? true : false }}
                  label={label}
                  variant="outlined"
                />
              )}
            />
          ))}

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
            <AppButton type="submit">Update</AppButton>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
