/* eslint-disable @typescript-eslint/naming-convention */
import { GwConfigsInput, RepeaterConfigsInput, TagConfigsInput } from '../../services/types/config';
import {
  InputOption,
  SensorSelectTypeOptions,
} from '../store/context-components/sensor-context/sensor-context';

export const gatewayDefaultConfigurations: GwConfigsInput = {
  telemetry_int: 3,
  nw_id: 21,
  bat_lvl_th: 10800,
};

export const repeaterDefaultConfigurations: RepeaterConfigsInput = {
  telemetry_int: 3,
  nw_id: 21,
  fac: [0, 0, 0],
  bias: [0, 0, 0],
  trans_win: 0,
};

export function getTagInitialDefaultConfigs(
  sensorOptions: SensorSelectTypeOptions,
  analog1: string | undefined,
  analog2: string | undefined,
  digital: string | undefined,
): TagConfigsInput {
  return {
    trans_win: 1200,
    smplsNum: 3,
    space: 1000,
    telemetry_int: 3,
    exicitation: 1000,
    ...getTagAnalogInputDefaults(
      sensorOptions,
      { type: analog1 },
      { type: analog2 },
      { type: digital },
    ),
  };
}

const InactiveValue = 0;

function getValue(
  wasInputChanged: boolean,
  defaultValue: number | undefined,
  currentValue: number | undefined,
): number {
  if (wasInputChanged) {
    return defaultValue != undefined ? defaultValue : InactiveValue;
  } else {
    return currentValue != undefined ? currentValue : InactiveValue;
  }
}

export interface InputStatus {
  type: string | undefined;
  previousType?: string;
  currentBias?: number;
  currentFactor?: number;
}

function findConfigDefaults(options: InputOption[], name: string | undefined) {
  return options.find(x => x.value == name)?.configDefaults;
}

export function getTagAnalogInputDefaults(
  sensorOptions: SensorSelectTypeOptions,
  analog1: InputStatus,
  analog2: InputStatus,
  digital: InputStatus,
): TagConfigsInput {
  return {
    fac: [
      getValue(
        analog1.type != analog1.previousType,
        findConfigDefaults(sensorOptions.analogOptions, analog1.type)?.tagFactor,
        analog1.currentFactor,
      ),
      getValue(
        analog2.type != analog2.previousType,
        findConfigDefaults(sensorOptions.analogOptions, analog2.type)?.tagFactor,
        analog2.currentFactor,
      ),
      getValue(
        digital.type != digital.previousType,
        findConfigDefaults(sensorOptions.digitalOptions, digital.type)?.tagFactor,
        digital.currentFactor,
      ),
    ],
    bias: [
      getValue(
        analog1.type != analog1.previousType,
        findConfigDefaults(sensorOptions.analogOptions, analog1.type)?.tagBias,
        analog1.currentBias,
      ),
      getValue(
        analog2.type != analog2.previousType,
        findConfigDefaults(sensorOptions.analogOptions, analog2.type)?.tagBias,
        analog2.currentBias,
      ),
      getValue(
        digital.type != digital.previousType,
        findConfigDefaults(sensorOptions.digitalOptions, digital.type)?.tagBias,
        digital.currentBias,
      ),
    ],
  };
}

export function getTagAnalogInputLocalDefaults(
  sensorOptions: SensorSelectTypeOptions,
  analog1: InputStatus,
  analog2: InputStatus,
  digital: InputStatus,
): TagConfigsInput {
  return {
    fac: [
      getValue(
        analog1.type != analog1.previousType,
        findConfigDefaults(sensorOptions.analogOptions, analog1.type)?.factor,
        analog1.currentFactor,
      ),
      getValue(
        analog2.type != analog2.previousType,
        findConfigDefaults(sensorOptions.analogOptions, analog2.type)?.factor,
        analog2.currentFactor,
      ),
      getValue(
        digital.type != digital.previousType,
        findConfigDefaults(sensorOptions.digitalOptions, digital.type)?.factor,
        digital.currentFactor,
      ),
    ],
    bias: [
      getValue(
        analog1.type != analog1.previousType,
        findConfigDefaults(sensorOptions.analogOptions, analog1.type)?.bias,
        analog1.currentBias,
      ),
      getValue(
        analog2.type != analog2.previousType,
        findConfigDefaults(sensorOptions.analogOptions, analog2.type)?.bias,
        analog2.currentBias,
      ),
      getValue(
        digital.type != digital.previousType,
        findConfigDefaults(sensorOptions.digitalOptions, digital.type)?.bias,
        digital.currentBias,
      ),
    ],
  };
}
