import React, { CSSProperties, forwardRef, useMemo } from 'react';
import { MapContainer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import Box, { BoxProps } from '@mui/material/Box';

import { defaultMapConfig } from './constants';
import { MapEntitiesLayer } from './map-entities-layer';
import { MapProps, MapRef } from './types';

const containerStyles: BoxProps['sx'] = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

const mapContainerStyles: CSSProperties = {
  height: '100%',
  width: '100%',
};

export const Map = forwardRef<MapRef, MapProps>(
  ({ mapEntities, mapConfig = {}, googleLayerProps, mapContainerProps }, ref) => {
    const config = useMemo(() => ({ ...defaultMapConfig, ...mapConfig }), [mapConfig]);

    return (
      <Box sx={containerStyles}>
        <MapContainer {...mapContainerProps} style={mapContainerStyles}>
          <ReactLeafletGoogleLayer {...googleLayerProps} />
          <MapEntitiesLayer ref={ref} mapConfig={config} mapEntities={mapEntities} />
        </MapContainer>
      </Box>
    );
  },
);
