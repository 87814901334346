import { Device, TagsMetadata } from '../../../services/types';
import { CreateStepsProps, Step } from '../../types';
import { AssociateFirstStep } from '../associate/first-step-associate';
import { AssociateRepeaterSecondStep, SecondStepProps } from './second-step-associate';

export const createAssociateRepeaterSteps = ({
  handleBack,
  handleNext,
  handleCloseAndReset,
  backButtonVisible,
  nextButtonVisible,
}: CreateStepsProps): Step[] => [
  {
    label: 'Associate Repeater',
    component: (
      <AssociateFirstStep
        backButtonVisible={backButtonVisible}
        closeModal={handleCloseAndReset}
        handleBack={handleBack}
        handleNext={handleNext}
        nextButtonVisible={nextButtonVisible}
      />
    ),
  },
  {
    label: 'Update & Monitor Repeater',
    component: (
      <AssociateRepeaterSecondStep closeModal={handleCloseAndReset} handleBack={handleBack} />
    ),
  },
];

export const convertRawDataToSecondStepFormFields = (data: Device): SecondStepProps => {
  return {
    location: {
      long: data.data?.location?.long,
      lat: data.data?.location?.lat,
    },
    metadata: {
      plot: (data.data.metadata as TagsMetadata)?.plot,
      comment: (data.data.metadata as TagsMetadata)?.comment,
    },
  };
};
