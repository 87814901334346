import { useMemo } from 'react';

import { MapEntityProps } from '../../../../common/map/map-entity/types';
import { MapProps } from '../../../../common/map/types';
import { DeviceData } from '../../../../services/types';
import { getDeviceMapEntities } from './utils';

interface UseDeviceMapEntitiesProps {
  devices: DeviceData[];
}

interface UseDeviceMapEntitiesValue {
  mapEntities: MapProps['mapEntities'];
}

export const useDeviceMapEntities = ({
  devices,
}: UseDeviceMapEntitiesProps): UseDeviceMapEntitiesValue => {
  const mapEntities: MapEntityProps[] = useMemo(
    () => getDeviceMapEntities(devices ?? []),
    [devices],
  );

  return {
    mapEntities,
  };
};
