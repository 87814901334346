import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { formatDistance, parseISO } from 'date-fns';

import { LabelField } from '../../../../common/label-field/label-field';
import theme from '../../../../common/theme';
import { getDevice } from '../../../../services/api/device';
import { DeviceAlertType, DeviceType, TagsStatus } from '../../../../services/types';
import { transformBatteryToIcon, transformBatvoltToBattery } from '../..//gateways/helper';

type SignalBatteryInfoProps = {
  deviceId?: string;
  deviceType?: DeviceType;
};

interface DeviceStatusData {
  inputSignal?: number | undefined;
  outputSignal?: number | undefined;
  state?: 'install' | 'active' | undefined;
  batVolt?: number | undefined;
  isConnected: boolean;
  statusUpdated?: string;
}

export const SignalBatteryInfo: FC<SignalBatteryInfoProps> = ({ deviceId, deviceType }) => {
  const [deviceData, setDeviceData] = useState<DeviceStatusData>();

  const updateDeviceData = (deviceIdInput: string) => {
    getDevice(deviceIdInput).then(res => {
      const status = res.data.status as TagsStatus;

      setDeviceData({
        inputSignal: status?.gwRssi,
        outputSignal: status?.rfRssi,
        batVolt: status?.batvolt,
        state: status?.state,
        isConnected:
          res?.data?.gatewayConnectionState == 'online' &&
          res?.data?.alerts &&
          !res.data.alerts.includes(DeviceAlertType.outOfNetwork),
        statusUpdated: res?.data?.statusUpdateTime,
      });
    });
  };
  useEffect(() => {
    if (deviceId) {
      updateDeviceData(deviceId);
      const polling = setInterval(() => {
        updateDeviceData(deviceId);
      }, 2000);
      return () => {
        clearTimeout(polling);
      };
    }
  }, [deviceId]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <LabelField
        color={
          deviceData?.inputSignal && deviceData?.inputSignal < -100
            ? theme.color.error
            : theme.color.black
        }
        label="Input Signal"
      >
        {deviceData?.inputSignal}
      </LabelField>
      <LabelField
        color={
          deviceData?.outputSignal && deviceData?.outputSignal < -100
            ? theme.color.error
            : theme.color.black
        }
        label="Output Signal"
      >
        {deviceData?.outputSignal}
      </LabelField>
      <LabelField label="Battery">
        {deviceData?.batVolt && deviceType
          ? transformBatteryToIcon(
              transformBatvoltToBattery(deviceType, deviceData.batVolt),
              '24px',
              deviceData.batVolt,
            )
          : ''}
      </LabelField>
      <LabelField label="Mode">
        {deviceData?.state
          ? deviceData.state == 'install'
            ? 'Installation'
            : 'Active'
          : undefined}
      </LabelField>
      <LabelField label="Connection">{deviceData?.isConnected ? 'Online' : 'Offline'}</LabelField>
      <LabelField label="Status Update Time">
        {deviceData?.statusUpdated
          ? formatDistance(Date.now(), parseISO(deviceData.statusUpdated))
          : ''}
      </LabelField>
    </Box>
  );
};
