import L, { LatLngExpression } from 'leaflet';

import GatewayIcon from '../../../../assets/icons/gateway-icon.svg';
import RepeaterIcon from '../../../../assets/icons/repeater-icon.svg';
import TagIcon from '../../../../assets/icons/tag-icon.svg';
import { MapEntityProps, MarkerEntityProps } from '../../../../common/map/map-entity/types';
import { DeviceData, DeviceType } from '../../../../services/types';

const devicesIcons: Record<DeviceType, string> = {
  [DeviceType.gateway]: GatewayIcon,
  [DeviceType.repeater]: RepeaterIcon,
  [DeviceType.tag]: TagIcon,
};

export const getDeviceDefaultMarker = (device: DeviceData): MarkerEntityProps => {
  const { lat, long } = device.location ?? {};
  return {
    position: [lat, long] as LatLngExpression,
    tooltipContent: `${device.type} - ${device.deviceId}`,
    icon: new L.Icon({
      iconUrl: devicesIcons[device.type],
      iconAnchor: [16, 60],
    }),
  };
};

export const getDeviceMapEntities = (devices: DeviceData[]): MapEntityProps[] => {
  const mapEntities: MapEntityProps[] = [];
  devices.forEach(device => {
    if (device.location) {
      mapEntities.push({
        entityKey: device.deviceId?.toString(),
        marker: getDeviceDefaultMarker(device),
      });
    }
  });

  return mapEntities;
};
