import { useQuery } from 'react-query';

import { RequestQuery } from '../../../services/react-query-request-names';
import { Alert, DeviceAlertType, Devices, DeviceType } from '../../../services/types';
import { getAlerts, getGwOfflineDevices } from './api';

type UseAlertCountersValues = {
  gwLowBattery?: number;
  gwLowLte?: number;
  gwOffline?: number;
  tagLowBattery?: number;
  tagLowRssi?: number;
  tagGwOffline?: number;
  tagOutOfNetwork?: number;
  repeaterLowBattery?: number;
  repeaterLowRssi?: number;
  repeaterGwOffline?: number;
  repeaterOutOfNetwork?: number;
};

const getAlertCount = (alertsResponse: Alert, type: DeviceAlertType) => {
  return alertsResponse.data.filter(a => a.alert.type == type).length;
};

const getOfflineCount = (devicesResponse: Devices, deviceType: DeviceType) => {
  return devicesResponse.data.data.filter(dev => dev.type == deviceType).length;
};

export const useAlertCounters = (): UseAlertCountersValues => {
  const gwAlerts = useQuery([RequestQuery.GwAlerts], async () => {
    //const alerts = await getAlerts(DeviceType.gateway, DeviceAlertType.lowBattery);
    const alerts = await getAlerts(DeviceType.gateway);
    return {
      gwLowBattery: getAlertCount(alerts, DeviceAlertType.lowBattery),
      gwLowLte: getAlertCount(alerts, DeviceAlertType.lowLTE),
    };
  });

  const gwOfflineDevices = useQuery([RequestQuery.GwOffline], async () => {
    const offlineDevices = await getGwOfflineDevices();
    return {
      gateways: getOfflineCount(offlineDevices, DeviceType.gateway),
      tags: getOfflineCount(offlineDevices, DeviceType.tag),
      repeaters: getOfflineCount(offlineDevices, DeviceType.repeater),
    };
  });

  const tagAlerts = useQuery([RequestQuery.TagAlerts], async () => {
    //return await getAlerts(DeviceType.tag, DeviceAlertType.lowBattery);
    const alerts = await getAlerts(DeviceType.tag);
    return {
      tagLowBattery: getAlertCount(alerts, DeviceAlertType.lowBattery),
      tagLowRssi: getAlertCount(alerts, DeviceAlertType.lowRssi),
      tagOutOfNetwork: getAlertCount(alerts, DeviceAlertType.outOfNetwork),
    };
  });

  const repeaterAlerts = useQuery([RequestQuery.RepeaterAlerts], async () => {
    //return await getAlerts(DeviceType.tag, DeviceAlertType.lowBattery);
    const alerts = await getAlerts(DeviceType.repeater);
    return {
      repeaterLowBattery: getAlertCount(alerts, DeviceAlertType.lowBattery),
      repeaterLowRssi: getAlertCount(alerts, DeviceAlertType.lowRssi),
      repeaterOutOfNetwork: getAlertCount(alerts, DeviceAlertType.outOfNetwork),
    };
  });

  return {
    gwLowBattery: gwAlerts.data?.gwLowBattery,
    gwLowLte: gwAlerts.data?.gwLowLte,
    gwOffline: gwOfflineDevices.data?.gateways,
    tagLowBattery: tagAlerts.data?.tagLowBattery,
    tagLowRssi: tagAlerts.data?.tagLowRssi,
    tagGwOffline: gwOfflineDevices.data?.tags,
    tagOutOfNetwork: tagAlerts.data?.tagOutOfNetwork,
    repeaterLowBattery: repeaterAlerts.data?.repeaterLowBattery,
    repeaterLowRssi: repeaterAlerts.data?.repeaterLowRssi,
    repeaterGwOffline: gwOfflineDevices.data?.repeaters,
    repeaterOutOfNetwork: repeaterAlerts.data?.repeaterOutOfNetwork,
  };
};
