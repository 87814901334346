import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { transformSignalQualityToIcon } from '../../../components/devices/gateways/helper';
import { batvoltField, fwVersionField, growerField, gwStatusField, tsField } from './common';

const defaultGatewaysColumnFields: Partial<GridColDef> = {
  align: 'center',
  headerAlign: 'center',
};

const gwIdField: GridColDef = {
  ...defaultGatewaysColumnFields,
  field: 'deviceId',
  headerName: 'GW Id',
  type: 'customString',
  width: 120,
};

const typeField: GridColDef = {
  ...defaultGatewaysColumnFields,
  field: 'type',
  headerName: 'Type',
  type: 'customString',
  minWidth: 70,
  sortable: false,
  filterable: false,
};

const resellerField: GridColDef = {
  ...defaultGatewaysColumnFields,
  field: 'reseller',
  headerName: 'Reseller',
  type: 'customString',
  minWidth: 110,
};

const distanceField: GridColDef = {
  ...defaultGatewaysColumnFields,
  field: 'distance',
  headerName: 'Distance',
  width: 120,
  type: 'customNumberSmallerBigger',
};

const locationField: GridColDef = {
  ...defaultGatewaysColumnFields,
  field: 'location',
  headerName: 'Location',
  type: 'customString',
  minWidth: 200,
  sortable: false,
  filterable: false,
};

const signalField: GridColDef = {
  ...defaultGatewaysColumnFields,
  field: 'signal',
  headerName: 'Signal',
  type: 'customNumberSmallerBigger',
  minWidth: 70,
  filterable: false,
  sortable: false,
  renderCell: (params: GridRenderCellParams) => (
    <Box alignItems="center" display="flex" justifyContent="center">
      {transformSignalQualityToIcon(params.value, '24px')}
    </Box>
  ),
};

const networkGenerationField: GridColDef = {
  ...defaultGatewaysColumnFields,
  field: 'cellGen',
  headerName: 'Cell Network',
  type: 'customNumberSmallerBigger',
  minWidth: 70,
  filterable: false,
  sortable: false,
  renderCell: (params: GridRenderCellParams) => (
    <Box alignItems="center" display="flex" justifyContent="center">
      {params.value ? `${params.value}G` : ''}
    </Box>
  ),
};

const signalRSSIField: GridColDef = {
  ...defaultGatewaysColumnFields,
  field: 'cellRSSI',
  headerName: 'Signal RSSI',
  type: 'customNumberSmallerBigger',
  minWidth: 70,
  filterable: false,
  sortable: false,
};

export const gatewayColumns: GridColDef[] = [
  gwIdField,
  // typeField, // uncomment this to display Type field in gateway table
  fwVersionField,
  growerField,
  resellerField,
  distanceField,
  locationField,
  gwStatusField,
  signalField,
  networkGenerationField,
  signalRSSIField,
  batvoltField,
  tsField,
];
