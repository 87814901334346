import { FC, useCallback, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { AppButton } from '../../../../common/buttons/buttons';
import { useDeviceInstallationMode } from '../../../../common/hooks/instalation-mode';
import { LocationButton } from '../../../../common/location-button/location-button';
import { DeviceIdContext } from '../../../../common/store/device-id-context';
import { numberOrEmptyString } from '../../../../schemas/schemas';
import { getDevice } from '../../../../services/api/device';
import { RequestQuery } from '../../../../services/react-query-request-names';
import { DeviceType, ErrorResponse } from '../../../../services/types';
import { updateTag } from '../api';
import { convertRawDataToThirdStepFormFields } from '../helpers';
import { SignalBatteryInfo } from './signal-battery';

type AddTagThirdStepProps = {
  handleBack: () => void;
  closeModal: () => void;
};

enum SecondStepFieldName {
  'plot' = 'metadata.plot',
  'comment' = 'metadata.comment',
  'lat' = 'location.lat',
  'long' = 'location.long',
}
const secondStepFields = [
  { label: 'Latitude', fieldName: SecondStepFieldName.lat },
  { label: 'Longitude', fieldName: SecondStepFieldName.long },
  { label: 'Plot', fieldName: SecondStepFieldName.plot },
  { label: 'Comment', fieldName: SecondStepFieldName.comment },
];

const schema = yup
  .object()
  .shape({
    location: yup.object().shape({
      lat: numberOrEmptyString(yup.number()),
      long: numberOrEmptyString(yup.number()),
    }),
    metadata: yup.object().shape({
      plot: yup.string().optional(),
      comment: yup.string().optional(),
    }),
  })
  .required();

export type SecondStepProps = yup.InferType<typeof schema>;

export const AddTagThirdStep: FC<AddTagThirdStepProps> = ({ handleBack, closeModal }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { deviceId } = useContext(DeviceIdContext);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<SecondStepProps>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (deviceId) {
      getDevice(deviceId).then(res => {
        const initialValues = convertRawDataToThirdStepFormFields(res);
        reset(initialValues);
      });
    }
  }, [deviceId, reset, setValue]);

  useDeviceInstallationMode(deviceId);

  const onSubmit = (values: SecondStepProps) => {
    if (deviceId) {
      if (isDirty) {
        updateTag(values, deviceId)
          .then(() => {
            queryClient.invalidateQueries(RequestQuery.DeviceByType);
            enqueueSnackbar('Successfully associate', { variant: 'success' });
            closeModal();
          })
          .catch(err => {
            if (err.response) {
              const error = err.response.data as ErrorResponse;
              enqueueSnackbar(error?.errors ? error?.errors[0]?.description : 'Error 500', {
                variant: 'error',
              });
            }
          });
      } else {
        closeModal();
      }
    }
  };

  const handleLocationClick = useCallback(
    (latitude: number, longitude: number) => {
      setValue(SecondStepFieldName.lat, latitude, { shouldDirty: true });
      setValue(SecondStepFieldName.long, longitude, { shouldDirty: true });
    },
    [setValue],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <SignalBatteryInfo deviceId={deviceId} deviceType={DeviceType.tag} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <LocationButton
            sx={{ cursor: 'pointer', alignSelf: 'flex-end' }}
            onClick={handleLocationClick}
          />
          {secondStepFields.map(({ label, fieldName }, index) => (
            <Controller
              key={index}
              control={control}
              name={fieldName}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={error ? true : false}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: field.value !== undefined ? true : false }}
                  label={label}
                  variant="outlined"
                />
              )}
            />
          ))}
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
          <AppButton startIcon={<NavigateBeforeIcon />} onClick={handleBack}>
            Back
          </AppButton>
          <AppButton type="submit">Associate</AppButton>
        </Box>
      </form>
    </Box>
  );
};
