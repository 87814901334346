import { apiClient } from '../../../../services/client/axios-client';
import { Devices, DeviceType } from '../../../../services/types';

type UpdateGatewayDevicesFWProps = {
  id?: string;
  gatewaysVersion?: string;
  repeatersVersion?: string;
  tagsVersion?: string;
};

export type GetGatewayLastFWVersionsReturn = {
  gatewayLastVersion?: string;
  tagsLastVersion?: string;
  repeatersLastVersion?: string;
};

export const updateGatewayDevicesFW = async ({
  id,
  gatewaysVersion,
  repeatersVersion,
  tagsVersion,
}: UpdateGatewayDevicesFWProps) => {
  const versions = [];
  if (gatewaysVersion) {
    versions.push({
      versionNumber: gatewaysVersion,
      deviceType: DeviceType.gateway,
    });
  }

  if (repeatersVersion) {
    versions.push({
      versionNumber: repeatersVersion,
      deviceType: DeviceType.repeater,
    });
  }

  if (tagsVersion) {
    versions.push({
      versionNumber: tagsVersion,
      deviceType: DeviceType.tag,
    });
  }

  return await apiClient
    .put<Devices>(`/gateways/${id}/versions`, {
      versions,
    })
    .then(response => response.data);
};
