import { AxiosResponse } from 'axios';

import { apiClient } from '../../../services/client/axios-client';
import { Device } from '../../../services/types';

export const associateDevice = (id: string, gatewayId?: string) => {
  return apiClient
    .put<any, AxiosResponse<Device, Device>, any>(`/devices/${id}`, {
      gateway: {
        id: gatewayId,
      },
    })
    .then(response => response.data);
};
