/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext, FC, useEffect, useState } from 'react';

import config from '../../../../helpers/csvFileHelpers';

export type SensorType = {
  uid: string;
  type: string;
  group: string;
  family: string;
  parent_type_id: string;
  name: string;
  sensor_type_id: string;
  identifier: string;
  manufacturer: string;
  platform: string;
  temperature: string;
  factor: string;
  bias: string;
  excitation: string;
  measures: string;
  required_config: string;
  physical: string;
  pn: string;
  description: string;
  comments: string;
  npi: string;
  active: string;
  talgil_enum: string;
  converted_unit_min: string;
  converted_unit_max: string;
  expected_unit_min: string;
  expected_unit_max: string;
  expected_unit: string;
  converted_unit: string;
  linear: string;
  iot_factor: string;
  iot_bias: string;
  bean_name: string;
};

export interface Option {
  label: string;
  value?: string;
}

export interface ConfigDefaults {
  bias: number;
  factor: number;
  tagFactor: number;
  tagBias: number;
}

export interface InputOption extends Option {
  configDefaults?: ConfigDefaults;
}

export interface SensorSelectTypeOptions {
  digitalOptions: InputOption[];
  analogOptions: InputOption[];
}

export type InitialContext = {
  iopSensorSelectOptions: SensorSelectTypeOptions;
  setIopSensorSelectOptions: (iopSensorSelectOptions: SensorSelectTypeOptions) => void;
};

const SensorTypesSelectContext = createContext({
  iopSensorSelectOptions: { digitalOptions: [], analogOptions: [] },
  setIopSensorSelectOptions: () => {},
} as InitialContext);

interface AppContextProviderProps {
  children: JSX.Element;
}

const SensorTypeContextProvider: FC<AppContextProviderProps> = ({ children }) => {
  const [sensorTypesSelectValue, setIopSensorSelectOptions] = useState({
    iopSensorSelectOptions: {
      digitalOptions: [{ label: 'None', value: 'none' }],
      analogOptions: [{ label: 'None', value: 'none' }],
    } as SensorSelectTypeOptions,
    setIopSensorSelectOptions: (newState: SensorSelectTypeOptions) => {
      setIopSensorSelectOptions(prev => {
        return {
          ...prev,
          ...newState,
        };
      });
    },
  });

  useEffect(() => {
    config.getSensorSelectOptions().then(data => {
      if (data) {
        setIopSensorSelectOptions(prev => ({
          ...prev,
          iopSensorSelectOptions: data,
        }));
      }
    });
  }, []);

  return (
    // the Provider gives access to the context to its children
    <SensorTypesSelectContext.Provider value={sensorTypesSelectValue}>
      {children}
    </SensorTypesSelectContext.Provider>
  );
};

export { SensorTypeContextProvider, SensorTypesSelectContext };
