import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { Typography } from '@mui/material';

import { forgotPasswordRequest, loginRequest } from '../../authConfig';

const ErrorComponent = ({ error }: any) => {
  const resetPasswordCode = 'AADB2C90118';
  const cancelButtonCode = 'AADB2C90091';
  const { instance } = useMsal();
  if (error.errorMessage.includes(resetPasswordCode)) {
    instance.loginRedirect(forgotPasswordRequest);
  }
  if (error.errorMessage.includes(cancelButtonCode)) {
    instance.loginRedirect(loginRequest);
  }

  return <Typography variant="h6">An Error Occurred: {error.errorCode}</Typography>;
};

export const withMsalAuth = (component: JSX.Element) => {
  const AuthedComponent = () => (
    <MsalAuthenticationTemplate
      errorComponent={ErrorComponent}
      interactionType={InteractionType.Redirect}
    >
      {component}
    </MsalAuthenticationTemplate>
  );

  return AuthedComponent;
};
