import { FC } from 'react';
import { Link } from 'react-router-dom';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import SignalCellularOffIcon from '@mui/icons-material/SignalCellularOff';
import { Box, CircularProgress, Typography } from '@mui/material';

import { DeviceTableAlertQuery, DeviceTypeRoutes } from '../../../services/types';

type LinkAlertItemProps = {
  value?: number;
  label: string;
  alertType: DeviceTableAlertQuery;
  deviceType: DeviceTypeRoutes;
};

const IconStyles = {
  fontSize: '52px',
};

export const LinkAlertItem: FC<LinkAlertItemProps> = ({ value, label, deviceType, alertType }) => {
  return (
    <Link
      style={{ textDecoration: 'none', color: 'black' }}
      to={`/devices/${deviceType}?alert=${alertType}`}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        sx={{
          '&:hover': {
            opacity: 0.8,
          },
        }}
      >
        <Typography fontWeight="bold" height="40px" variant="h5">
          {value !== undefined ? value : <CircularProgress />}
        </Typography>
        {alertType === DeviceTableAlertQuery.lowBattery && <BatteryAlertIcon sx={IconStyles} />}
        {alertType === DeviceTableAlertQuery.lowLTE && <SignalCellularOffIcon sx={IconStyles} />}
        {alertType === DeviceTableAlertQuery.lowRssi && <SignalCellularOffIcon sx={IconStyles} />}
        {alertType === DeviceTableAlertQuery.outOfNetwork && <SensorsOffIcon sx={IconStyles} />}
        {alertType === DeviceTableAlertQuery.gwOffline && <CloudOffIcon sx={IconStyles} />}
        <Typography variant="body1">{label}</Typography>
      </Box>
    </Link>
  );
};
