import { createContext } from 'react';

export type TagGwIds = {
  tagId?: string;
  gwId?: string;
};

type InitialContext = {
  tagGwIds: TagGwIds;
  setTagGwIds: (tagGwId: TagGwIds) => void;
};

export const TagGwIdsContext = createContext({
  tagGwIds: {},
  setTagGwIds: () => {},
} as InitialContext);
