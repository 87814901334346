import { useCallback, useMemo, useState } from 'react';
import { LatLngExpression, LeafletEventHandlerFnMap } from 'leaflet';

import { MapEntityComponentProps, MapEntityDisplayMode } from '../types';

interface UseEventHandlersValue {
  polygonEventHandlers: LeafletEventHandlerFnMap;
  markerEventHandlers: LeafletEventHandlerFnMap;
  displayMode: MapEntityDisplayMode;
}

export const useEventHandlers = ({
  map,
  mapConfig,
  marker,
  polygon,
  zoomLevel,
}: MapEntityComponentProps): UseEventHandlersValue => {
  const [displayMode, setDisplayMode] = useState<MapEntityDisplayMode>(
    MapEntityDisplayMode.Default,
  );

  const onMapEntityHover = useCallback(() => {
    setDisplayMode(MapEntityDisplayMode.Hover);
  }, []);

  const resetDisplayMode = useCallback(() => {
    setDisplayMode(MapEntityDisplayMode.Default);
  }, []);

  const moveToSelectedEntity = useCallback(() => {
    map.flyTo(marker.position as LatLngExpression, mapConfig.selectedPolygonZoomLevel, {
      duration: mapConfig.moveToEntityAnimationDuration,
    });
  }, [marker.position, map, mapConfig]);

  const onMapEntityClick = useCallback(() => {
    moveToSelectedEntity();
  }, [moveToSelectedEntity]);

  const polygonEventHandlers: LeafletEventHandlerFnMap = useMemo(
    () => ({
      ...(polygon?.eventHandlers ?? {}),
      mouseover: onMapEntityHover,
      mouseout: resetDisplayMode,
    }),
    [polygon?.eventHandlers, onMapEntityHover, resetDisplayMode],
  );

  const markerEventHandlers: LeafletEventHandlerFnMap = useMemo(
    () => ({
      ...marker.eventHandlers,
      click:
        zoomLevel >= mapConfig.showPolygonZoomLevel
          ? marker.eventHandlers?.click
          : onMapEntityClick,
    }),
    [marker.eventHandlers, zoomLevel, mapConfig, onMapEntityClick],
  );

  return {
    polygonEventHandlers,
    markerEventHandlers,
    displayMode,
  };
};
