import { DeepPartial, UnpackNestedValue } from 'react-hook-form';

import {
  GwConfigsInput,
  GwConfigsOutput,
  RepeaterConfigsInput,
  RepeaterConfigsOutput,
  TagConfigsOutput,
} from '../../../services/types/config';
import { TagConfigsFormInput } from './types';

export function transformGwInitialConfigs(
  initialConfigs: GwConfigsOutput | undefined,
): UnpackNestedValue<DeepPartial<GwConfigsInput>> | undefined {
  if (!initialConfigs) {
    return undefined;
  }
  return {
    apn: initialConfigs.apn?.pending ?? initialConfigs.apn?.reported,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dbg_log: initialConfigs.dbg_log?.pending ?? initialConfigs.dbg_log?.reported,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dbg_port: initialConfigs.dbg_port?.pending ?? initialConfigs.dbg_port?.reported,
    user: initialConfigs.user?.pending ?? initialConfigs.user?.reported,
    pass: initialConfigs.pass?.pending ?? initialConfigs.pass?.reported,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    telemetry_int: initialConfigs.telemetry_int?.pending ?? initialConfigs.telemetry_int?.reported,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    nw_id: initialConfigs.nw_id?.pending ?? initialConfigs.nw_id?.reported,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    bat_lvl_th: initialConfigs.bat_lvl_th?.pending ?? initialConfigs.bat_lvl_th?.reported,
  };
}

function tryGetIndex(arr: number[] | undefined, index: number) {
  if (arr && index < arr.length) {
    return arr[index];
  }
  return undefined;
}

export function transformTagInitialConfigs(
  initialConfigs: TagConfigsOutput | undefined,
): UnpackNestedValue<DeepPartial<TagConfigsFormInput>> | undefined {
  if (!initialConfigs) {
    return undefined;
  }
  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    trans_win: initialConfigs.trans_win?.pending ?? initialConfigs.trans_win?.reported,
    smplsNum: initialConfigs.smplsNum?.pending ?? initialConfigs.smplsNum?.reported,
    space: initialConfigs.space?.pending ?? initialConfigs.space?.reported,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    telemetry_int: initialConfigs.telemetry_int?.pending ?? initialConfigs.telemetry_int?.reported,
    fac1:
      tryGetIndex(initialConfigs.fac?.pending, 0) ?? tryGetIndex(initialConfigs.fac?.reported, 0),
    fac2:
      tryGetIndex(initialConfigs.fac?.pending, 1) ?? tryGetIndex(initialConfigs.fac?.reported, 1),
    fac3:
      tryGetIndex(initialConfigs.fac?.pending, 2) ?? tryGetIndex(initialConfigs.fac?.reported, 2),
    bias1:
      tryGetIndex(initialConfigs.bias?.pending, 0) ?? tryGetIndex(initialConfigs.bias?.reported, 0),
    bias2:
      tryGetIndex(initialConfigs.bias?.pending, 1) ?? tryGetIndex(initialConfigs.bias?.reported, 1),
    bias3:
      tryGetIndex(initialConfigs.bias?.pending, 2) ?? tryGetIndex(initialConfigs.bias?.reported, 2),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    nw_id: initialConfigs.nw_id?.pending ?? initialConfigs.nw_id?.reported,
    exicitation: initialConfigs.exicitation?.pending ?? initialConfigs.exicitation?.reported,
  };
}

export function transformRepeaterInitialConfigs(
  initialConfigs: RepeaterConfigsOutput | undefined,
): UnpackNestedValue<DeepPartial<RepeaterConfigsInput>> | undefined {
  if (!initialConfigs) {
    return undefined;
  }
  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    telemetry_int: initialConfigs.telemetry_int?.pending ?? initialConfigs.telemetry_int?.reported,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    nw_id: initialConfigs.nw_id?.pending ?? initialConfigs.nw_id?.reported,
  };
}
