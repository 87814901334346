import { FC } from 'react';

import { DeviceData, DeviceType } from '../../../services/types';
import { LabelField } from '../../label-field/label-field';
import { ColumnView } from '../common/column-view';
import { useDeviceDetails } from './hooks';

type MainTabProps = {
  data: DeviceData;
  deviceType: DeviceType;
};

export const MainTab: FC<MainTabProps> = ({ data, deviceType }) => {
  const fields = useDeviceDetails(deviceType, data);

  return (
    <ColumnView>
      {fields?.map(field => (
        <LabelField key={field.label + field.value} label={field.label}>
          {field.value}
        </LabelField>
      ))}
    </ColumnView>
  );
};
