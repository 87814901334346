import compareVersions from 'compare-versions';
import * as yup from 'yup';

export const versionSchema = (currentVersion: string | undefined) => {
  return yup
    .string()
    .matches(/^[0-9]+\.[0-9]+\.[0-9]+$/, 'Expected version format: "major.minor.build"')
    .required()
    .test(
      'greater-version',
      'Version number must be greater than current',
      versionNum =>
        !currentVersion ||
        !versionNum ||
        !compareVersions.validate(versionNum) ||
        !compareVersions.compare(versionNum, currentVersion, '<='),
    );
};

export const requiredFileSchema = yup
  .mixed()
  .test('filesLength', 'File must be uploaded', (fileList: FileList[]) => {
    return fileList.length == 1;
  });
