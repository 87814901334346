import { Devices, DeviceSelectAlertType, DeviceTableAlertQuery } from '../../../services/types';

export const getCurrentDevice = (devices: Devices, id: string) => {
  return devices.data.data.find(device => device.deviceId === id);
};

export const transformsAlertsFromQuery = (alert: DeviceTableAlertQuery | undefined | null) => {
  if (alert === DeviceTableAlertQuery.lowBattery) {
    return DeviceSelectAlertType.lowBattery;
  }
  if (alert === DeviceTableAlertQuery.lowLTE) {
    return DeviceSelectAlertType.lowLTE;
  }
  if (alert === DeviceTableAlertQuery.outOfNetwork) {
    return DeviceSelectAlertType.outOfNetwork;
  }
  if (alert === DeviceTableAlertQuery.gwOffline) {
    return DeviceSelectAlertType.gwOffline;
  }
  if (alert === DeviceTableAlertQuery.lowRssi) {
    return DeviceSelectAlertType.lowRssi;
  }
};
