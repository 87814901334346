import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridRowModel,
  GridSortModel,
  GridState,
} from '@mui/x-data-grid';
import { cloneDeep } from 'lodash';

import { RequestQuery } from '../../services/react-query-request-names';
import {
  DeviceData,
  Devices,
  DeviceTableAlertQuery,
  DeviceType,
  Filters,
  Pagination,
  Sorts,
} from '../../services/types';
import { getCurrentDevice, transformsAlertsFromQuery } from './helpers/common';
import { TransformFilters } from './helpers/filters-transform';
import { transformSorts } from './helpers/sorts-transform';
import { transformDataForTable } from './helpers/transform-data-for-table';
import { getDevices } from './api';
import {
  customContainOperator,
  customData,
  customNumberOperators,
  customSelectBiggerThanSmallerThen,
  customStringOperatorEqual,
  customStringOperators,
  smallerThanBiggerThanOperators,
} from './filter-types';

type DeviceTableProps = {
  columns: GridColDef[];
  deviceType: DeviceType[];
  onRowClick: (device?: DeviceData) => void;
  gatewayId?: string;
  alertFilter?: DeviceTableAlertQuery;
};

const pageSize = 10;

export const DeviceTable: FC<DeviceTableProps> = ({
  alertFilter,
  columns,
  deviceType,
  onRowClick,
  gatewayId,
}) => {
  const [tableData, setTableData] = useState<GridRowModel[]>([]);
  const [filters, setFilters] = useState<Filters | undefined>();
  const currentColumnsLookup = useMemo(() => cloneDeep(columns), [columns]);
  const [sorts, setSorts] = useState<Sorts | undefined>();
  const [selectedDevice, setSelectedDevice] = useState<string | undefined>(undefined);
  const [pagination, setPagination] = useState<Pagination>({
    limit: pageSize,
    offset: 0,
  });
  const [page, setPage] = useState<number>(0);
  const [coordinates, setCoordinates] = useState<{ lat?: number; long?: number }>();

  const { isLoading, data } = useQuery<Devices>(
    [RequestQuery.DeviceByType, pagination, filters, deviceType, sorts, coordinates, gatewayId],
    async () => {
      return await getDevices({ pagination, filters, deviceType, sorts, coordinates, gatewayId });
    },
    {
      refetchInterval: 5000,
    },
  );

  const initialFilterModal = useMemo(() => {
    if (alertFilter) {
      return {
        items: [
          {
            columnField: 'status',
            operatorValue: 'is',
            value: transformsAlertsFromQuery(alertFilter),
          },
        ],
      };
    } else {
      return null;
    }
  }, [alertFilter]);

  useEffect(() => {
    let mounted = true;
    navigator.geolocation.getCurrentPosition(position => {
      if (mounted) {
        setCoordinates({ lat: position.coords.latitude, long: position.coords.longitude });
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (data) {
      const transformedData = transformDataForTable(data, deviceType);
      setTableData(transformedData);
    }
  }, [data, deviceType]);

  useEffect(() => {
    if (initialFilterModal) {
      setFilters(TransformFilters(initialFilterModal, deviceType[0]));
    }
  }, [deviceType, initialFilterModal]);

  useEffect(() => {
    if (data && selectedDevice) {
      const deviceData = getCurrentDevice(data, selectedDevice);
      onRowClick(deviceData);
    } else {
      onRowClick(undefined);
    }
  }, [data, selectedDevice, onRowClick]);

  const onFilterChange = useCallback(
    (filterModel: GridFilterModel) => {
      const transformedFilters = TransformFilters(filterModel, deviceType[0]);
      setFilters(transformedFilters);
    },
    [deviceType],
  );

  const onSortChange = useCallback((sortModel: GridSortModel) => {
    const transformedSorts = transformSorts(sortModel);
    setSorts(transformedSorts);
  }, []);

  const onPageChange = useCallback(
    (page: number) => {
      if (!isLoading) {
        setPage(page);
        setPagination({
          offset: page * pageSize,
          limit: pageSize,
        });
      }
    },
    [isLoading],
  );

  const onCellClick = useCallback((params: GridCellParams<any, any, any>) => {
    if (params.tabIndex === -1) {
      setSelectedDevice(params.row.deviceId);
    }
    if (params.tabIndex === 0) {
      setSelectedDevice(undefined);
    }
  }, []);

  return (
    <DataGrid
      columns={currentColumnsLookup}
      columnTypes={{
        customNumber: customNumberOperators,
        customString: customStringOperators,
        customNumberSmallerBigger: smallerThanBiggerThanOperators,
        customSingleSelectContain: customContainOperator,
        customStringOperatorEqual: customStringOperatorEqual,
        customSelectBiggerThanSmallerThen: customSelectBiggerThanSmallerThen,
        customData: customData,
      }}
      filterMode="server"
      initialState={{
        filter: {
          filterModel: initialFilterModal || undefined,
        },
      }}
      loading={isLoading}
      page={page}
      pageSize={pageSize}
      pagination
      paginationMode="server"
      rowCount={data?.data.pagination.totalCount}
      rows={tableData}
      rowsPerPageOptions={[pageSize]}
      sortingMode="server"
      onCellClick={onCellClick}
      onFilterModelChange={onFilterChange}
      onPageChange={onPageChange}
      onSortModelChange={onSortChange}
      onStateChange={(state: GridState) => {
        currentColumnsLookup.forEach(col => {
          col.hide = state.columns.lookup[col.field].hide;
        });
      }}
    />
  );
};
