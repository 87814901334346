import { AxiosResponse } from 'axios';

import { apiClient } from '../client/axios-client';
import { Device } from '../types';

export const getDevice = (deviceId: string) =>
  apiClient.get<Device>(`/devices/${deviceId}`).then(response => response.data);

interface ActionsBody<T> {
  actionId: string;
  payload: T;
}

enum ActionId {
  SetState = 'SetState',
  GetTelemetry = 'GetTelemetry',
}

enum SetStatePayload {
  Install = 'install',
  Active = 'active',
}

interface GatewayStatusBody {
  isEnabled: boolean;
}

export const setInstallationMode = async (deviceId: string, installation: boolean) => {
  await sendDeviceAction<string>(
    deviceId,
    ActionId.SetState,
    installation ? SetStatePayload.Install : SetStatePayload.Active,
  );
};

export const sendGetTelemetryCommand = async (deviceId: string) => {
  await sendDeviceAction(deviceId, ActionId.GetTelemetry, undefined);
};

export const setGatewayActive = async (deviceId: string) => {
  await apiClient.post<any, AxiosResponse, GatewayStatusBody>(`/gateways/${deviceId}/status`, {
    isEnabled: true,
  });
};

async function sendDeviceAction<T>(deviceId: string, actionId: string, payload: T) {
  await apiClient.post<any, AxiosResponse, ActionsBody<T>>(
    `/devices/${deviceId}/actions?isSynchronous=false`,
    {
      actionId: actionId,
      payload: payload,
    },
  );
}
