import { LatLngBounds } from 'leaflet';

import { DeviceData } from '../../../services/types';

export const getVisibleDevices = (devices: DeviceData[], bounds: LatLngBounds): DeviceData[] => {
  return devices.filter(device => {
    const { lat, long } = device.location ?? {};
    if (!lat || !long) {
      return false;
    }

    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    const isWithinLat = lat >= southWest.lat && lat <= northEast.lat;
    const isWithinLng = long >= southWest.lng && long <= northEast.lng;
    return isWithinLat && isWithinLng;
  });
};
