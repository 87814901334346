import { GridSortModel } from '@mui/x-data-grid';

import { OrderBySource, Sorts } from '../../../services/types';
import { commonFields, metadataFields, statusFields } from '../api';

export const transformSorts = (sorts: GridSortModel): Sorts | undefined => {
  let notEmpty = false;
  // eslint-disable-next-line no-var
  var res: Sorts = {
    orderBy: {
      field: {},
    },
  };

  sorts.forEach(sort => {
    const isMetaField = metadataFields.includes(sort.field);
    const isStatusField = statusFields.includes(sort.field);
    const isCommonField = commonFields.includes(sort.field);
    res = {
      orderBy: {
        field: {
          source: 'common',
          name: sort.field,
        },
        direction: !sort.sort ? undefined : sort.sort == 'desc' ? 'descending' : 'ascending',
      },
    };
    if (isMetaField) {
      res.orderBy.field.source = OrderBySource.Metadata;
      notEmpty = true;
    }
    if (isStatusField) {
      res.orderBy.field.source = OrderBySource.Status;
      notEmpty = true;
    }
    if (isCommonField) {
      res.orderBy.field.source = OrderBySource.Common;
      if (sort.field === 'fwVersion') {
        res.orderBy.field.name = 'currentVersion';
      }
      if (sort.field === 'gwId') {
        res.orderBy.field.name = 'gateway';
      }
      notEmpty = true;
    }
  });
  if (notEmpty) {
    return res;
  } else {
    return undefined;
  }
};
