import { Box, Typography } from '@mui/material';

export const NotFound = () => {
  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography color="gray" variant="h1">
        404{' '}
      </Typography>
      <Typography variant="h2"> Page not found</Typography>
    </Box>
  );
};
