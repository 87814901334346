import { AxiosResponse } from 'axios';

import { apiClient } from '../../../services/client/axios-client';
import { Device, DeviceType, TagAddBody, TagUpdateBody } from '../../../services/types';

export const addTag = async (props: TagAddBody, id: string) => {
  const response = await apiClient.post<Device, AxiosResponse<Device, TagAddBody>, any>(
    `/devices/${id}`,
    {
      ...props,
      type: DeviceType.tag,
      metadata: {
        analog1: props.metadata?.analog1 !== 'none' ? props.metadata?.analog1 : null,
        analog2: props.metadata?.analog2 !== 'none' ? props.metadata?.analog2 : null,
        digital: props.metadata?.digital !== 'none' ? props.metadata?.digital : null,
      },
    },
  );
  return response.data;
};

export const updateTag = async (props: TagUpdateBody, id: string) => {
  const response = await apiClient.put<Device, AxiosResponse<Device, TagUpdateBody>, any>(
    `/devices/${id}`,
    {
      ...props,
      metadata: {
        ...props.metadata,
        analog1: props.metadata?.analog1 !== 'none' ? props.metadata?.analog1 : null,
        analog2: props.metadata?.analog2 !== 'none' ? props.metadata?.analog2 : null,
        digital: props.metadata?.digital !== 'none' ? props.metadata?.digital : null,
      },
    },
  );
  return response.data;
};

export const getLastMeasurement = async (deviceId: string) => {
  const lastWeekDate = new Date();
  lastWeekDate.setDate(lastWeekDate.getDate() - 7);
  const currentDateISO = new Date().toISOString();
  const from = currentDateISO.replace(/T.*$/, 'T00:00:00.000Z');
  const to = currentDateISO.replace(/T.*$/, 'T23:59:59.999Z');
  const response = await apiClient.get<any, AxiosResponse<any>, any>(
    `/devices/${deviceId}/history/volts?from=${from}&to=${to}`,
  );

  return response.data.data[0];
};
