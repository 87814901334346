export const devicePageWrapper = {
  width: '100%',
  display: 'grid',
  gridTemplateRows: '1fr 10fr 10fr',
  bgcolor: 'background.default',
  p: 3,
  gap: 1,
  height: '100vh',
} as const;

export const deviceMenuWrapper = { width: '100%', height: 52, padding: 1, display: 'flex', gap: 2 };
