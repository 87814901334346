import { SensorsOff } from '@mui/icons-material';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery80Icon from '@mui/icons-material/Battery80';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalCellularOffIcon from '@mui/icons-material/SignalCellularOff';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

import { CustomTooltip } from '../../../common/tooltip/tooltip';
import { CreateStepsProps, Step } from '../../../common/types';
import {
  Device,
  DeviceAddBody,
  DeviceAlertType,
  DeviceType,
  GatewayMetadata,
  GatewayStatus,
} from '../../../services/types';
import { AddGatewayFirstStep, GatewayProps } from './add-gateway/first-step';
import { AddGatewaySecondStep } from './add-gateway/second-step';
import { AddGatewayThirdStep } from './add-gateway/third-step';

export const createGatewaySteps = ({
  handleBack,
  handleNext,
  handleCloseAndReset,
  backButtonVisible,
  nextButtonVisible,
}: CreateStepsProps): Step[] => [
  {
    label: 'Gateway',
    component: (
      <AddGatewayFirstStep
        backButtonVisible={backButtonVisible}
        closeModal={handleCloseAndReset}
        handleBack={handleBack}
        handleNext={handleNext}
        nextButtonVisible={nextButtonVisible}
      />
    ),
  },
  {
    label: 'SIM',
    component: <AddGatewaySecondStep handleBack={handleBack} handleNext={handleNext} />,
  },
  {
    label: 'Configure',
    component: <AddGatewayThirdStep closeModal={handleCloseAndReset} handleBack={handleBack} />,
  },
];

export const isInRange = (value: number, min: number, max: number): boolean => {
  if (value > min && value <= max) {
    return true;
  } else {
    return false;
  }
};

export const transformCellRSSIToSignalQuality = (CellGen?: number, CellRSSI?: number) => {
  if (CellGen && CellRSSI) {
    switch (CellGen) {
      case 2: {
        if (CellRSSI >= -70) {
          return 5;
        }
        if (CellRSSI >= -85) {
          return 4;
        }
        if (CellRSSI >= -95) {
          return 3;
        }
        if (CellRSSI >= -100) {
          return 2;
        }
        if (CellRSSI >= -110) {
          return 1;
        } else {
          return 1;
        }
        break;
      }
      case 4: {
        if (CellRSSI >= -10) {
          return 5;
        }
        if (CellRSSI >= -12) {
          return 4;
        }
        if (CellRSSI >= -14) {
          return 3;
        }
        if (CellRSSI >= -16) {
          return 2;
        }
        if (CellRSSI >= -18) {
          return 1;
        } else {
          return 1;
        }
      }
    }
    return 1;
  } else {
    return 1;
  }
};

export const transformBatvoltToBattery = (deviceType: DeviceType, millivolt?: number) => {
  if (millivolt === undefined) {
    return undefined;
  }
  const batvolt = millivolt * 0.001;
  if (deviceType === DeviceType.gateway) {
    if (batvolt >= 12.9) {
      return 5;
    }
    if (batvolt >= 12.72) {
      return 4;
    }
    if (batvolt >= 12.48) {
      return 3;
    }
    if (batvolt >= 12.36) {
      return 2;
    }
    if (batvolt >= 12.24) {
      return 1;
    }
    return 0;
  }

  if (deviceType === DeviceType.tag) {
    if (batvolt >= 3.0) {
      return 5;
    }
    if (batvolt >= 2.95) {
      return 4;
    }
    if (batvolt >= 2.85) {
      return 3;
    }
    if (batvolt >= 2.7) {
      return 2;
    }
    if (batvolt >= 2.5) {
      return 1;
    }
    return 0;
  }

  if (deviceType === DeviceType.repeater) {
    if (batvolt >= 3.82) {
      return 5;
    }
    if (batvolt >= 3.78) {
      return 4;
    }
    if (batvolt >= 3.75) {
      return 3;
    }
    if (batvolt >= 3.7) {
      return 2;
    }
    if (batvolt >= 3.2) {
      return 1;
    }
    return 0;
  } else {
    return undefined;
  }
};

export const transformSignalQualityToIcon = (signal: number, fontSize = '72px') => {
  switch (signal) {
    case 5:
      return <SignalCellular4BarIcon sx={{ fontSize }} />;
    case 4:
      return <SignalCellular3BarIcon sx={{ fontSize }} />;
    case 3:
      return <SignalCellular2BarIcon sx={{ fontSize }} />;
    case 2:
      return <SignalCellular1BarIcon sx={{ fontSize }} />;
    case 1:
      return <SignalCellular0BarIcon sx={{ fontSize }} />;
    default:
      return <SignalCellular0BarIcon sx={{ fontSize }} />;
  }
};

export const transformBatteryToIcon = (
  signal: number | undefined,
  fontSize = '72px',
  millivolt: number | undefined | null,
) => {
  const voltValue = millivolt != null ? millivolt * 0.001 : null;
  const voltText = voltValue != null ? `${voltValue.toFixed(2)}V` : null;
  let batteryIconElement: JSX.Element | null = null;
  switch (signal) {
    case 5:
      batteryIconElement = <BatteryFullIcon sx={{ fontSize, transform: 'rotate(90deg)' }} />;
      break;
    case 4:
      batteryIconElement = <Battery80Icon sx={{ fontSize, transform: 'rotate(90deg)' }} />;
      break;
    case 3:
      batteryIconElement = <Battery60Icon sx={{ fontSize, transform: 'rotate(90deg)' }} />;
      break;
    case 2:
      batteryIconElement = <Battery50Icon sx={{ fontSize, transform: 'rotate(90deg)' }} />;
      break;
    case 1:
      batteryIconElement = <Battery30Icon sx={{ fontSize, transform: 'rotate(90deg)' }} />;
      break;
    case 0:
      batteryIconElement = <Battery20Icon sx={{ fontSize, transform: 'rotate(90deg)' }} />;
      break;
    default:
      batteryIconElement = null;
  }

  if (!batteryIconElement) {
    return null;
  }

  return <CustomTooltip title={voltText}>{batteryIconElement}</CustomTooltip>;
};

export const transformAlertsToIcons = (alerts?: string[], connection?: string) => {
  const res = [];
  alerts?.find(alert => {
    if (alert === DeviceAlertType.lowBattery) {
      res.push(<BatteryAlertIcon />);
    }
    if (alert === DeviceAlertType.lowLTE) {
      res.push(<SignalCellularOffIcon />);
    }
    if (alert === DeviceAlertType.outOfNetwork) {
      res.push(<SensorsOff />);
    }
    if (alert === DeviceAlertType.lowRssi) {
      res.push(<SignalCellularOffIcon />);
    }
  });
  if (connection === 'offline') {
    res.push(<CloudOffIcon />);
  }

  if (res.length == 0) {
    res.push(<ThumbUpAltIcon />);
  }
  return res;
};

export const convertRawDataToFirstStepFormFields = (data: Device): GatewayProps => {
  return {
    gateway: {
      id: data.data.deviceId,
    },
    metadata: {
      name: (data.data.metadata as GatewayMetadata)?.name,
      grower: (data.data.metadata as GatewayMetadata)?.grower,
      reseller: (data.data.metadata as GatewayMetadata)?.reseller,
      serviceProvider: (data.data.metadata as GatewayMetadata)?.serviceProvider,
      phoneNumber: (data.data.metadata as GatewayMetadata)?.phoneNumber,
      gwType: (data.data.metadata as GatewayMetadata)?.gwType,
      imsi: (data.data.metadata as GatewayMetadata)?.imsi,
    },
  };
};

export const convertRawDataToThirdStepFormFields = (
  data: Device,
): DeviceAddBody<GatewayMetadata, GatewayStatus> => {
  return {
    location:
      !data.data?.location?.long && !data.data?.location?.lat
        ? undefined
        : {
            long: data.data?.location?.long,
            lat: data.data?.location?.lat,
          },
  };
};
