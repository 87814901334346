import compareVersions from 'compare-versions';

import { DeviceData, Devices, DeviceType } from '../../../../services/types';

export const getEarliestVersionByType = (deviceData: DeviceData[], deviceType: DeviceType) => {
  const sorted = deviceData
    .filter(device => device.type === deviceType)
    .sort((v1, v2) => {
      if (v1?.fwVersion?.current && v2?.fwVersion?.current) {
        return compareVersions(v1.fwVersion.current, v2.fwVersion.current);
      } else {
        return 0;
      }
    });
  return sorted[0]?.fwVersion?.current;
};

export const getGatewaysEarliestVersions = (devices: Devices) => {
  const sortedGateway = devices.data.data.filter(device => device.type === DeviceType.gateway);
  const sortedTags = getEarliestVersionByType(devices.data.data, DeviceType.tag);
  const sortedRepeaters = getEarliestVersionByType(devices.data.data, DeviceType.repeater);

  return {
    gatewayLastVersion: sortedGateway[0].fwVersion?.current,
    tagsLastVersion: sortedTags,
    repeatersLastVersion: sortedRepeaters,
  };
};
