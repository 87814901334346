import { AxiosResponse } from 'axios';

import { apiClient } from '../../../services/client/axios-client';
import {
  Device,
  DeviceAddBody,
  DeviceType,
  GatewayAddBody,
  GatewayMetadata,
  GatewayStatus,
} from '../../../services/types';

export const addGateway = async (props: GatewayAddBody) => {
  const response = await apiClient.post<
    Device,
    AxiosResponse<Device, DeviceAddBody<GatewayMetadata, GatewayStatus>>,
    DeviceAddBody<GatewayMetadata, GatewayStatus>
  >(`/devices/${props.gateway?.id}`, {
    ...props,
    type: DeviceType.gateway,
  });
  return response.data;
};

export const updateGateway = async (props: GatewayAddBody) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { gateway, ...data } = props;
  const response = await apiClient.put<
    Device,
    AxiosResponse<Device, DeviceAddBody<GatewayMetadata, GatewayStatus>>,
    DeviceAddBody<GatewayMetadata, GatewayStatus>
  >(`/devices/${props.gateway?.id}`, { ...data });
  return response.data;
};
