import compareVersions from 'compare-versions';

import { DeviceType, FwVersion, TypeVersions } from './types';

export function getLatestForType(type: DeviceType, receivedVersions: FwVersion[]) {
  const sorted = receivedVersions
    .filter(v => v.deviceType == type)
    .sort((v1, v2) => compareVersions(v1.versionNumber, v2.versionNumber) * -1);
  return sorted.length == 0 ? null : sorted[0];
}

export function findLatestPerType(receivedVersions: FwVersion[]): TypeVersions {
  const map = new Map<DeviceType, FwVersion | null>();
  for (const type of [DeviceType.tag, DeviceType.repeater, DeviceType.gateway]) {
    map.set(type, getLatestForType(type, receivedVersions));
  }
  return map;
}
