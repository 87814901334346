import { apiClient } from '../../services/client/axios-client';
import { findLatestPerType } from '../../services/helpers';
import { DeviceType, FwVersionResponse } from '../../services/types';

export const getLatestVersions = async () => {
  return await apiClient.get<FwVersionResponse>(`/fw_versions`).then(res => {
    return findLatestPerType(res.data.data);
  });
};

export enum UploadVersionFileType {
  Firmware = 'firmware',
  Chip = 'chip',
}

export enum UploadVersionFormField {
  VersionNumber = 'versionNumber',
  DeviceType = 'deviceType',
  FileTypes = 'fileTypes',
  Files = 'files',
}

export const uploadTagOrRepeaterVersion = async (
  file: File,
  versionNumber: string,
  deviceType: DeviceType.tag | DeviceType.repeater,
) => {
  await uploadVersion(
    [{ type: UploadVersionFileType.Firmware, file: file }],
    versionNumber,
    deviceType,
  );
};

export const uploadGatewayVersion = async (
  firmwareFile: File,
  chipFile: File,
  versionNumber: string,
) => {
  await uploadVersion(
    [
      { type: UploadVersionFileType.Firmware, file: firmwareFile },
      { type: UploadVersionFileType.Chip, file: chipFile },
    ],
    versionNumber,
    DeviceType.gateway,
  );
};

const uploadVersion = async (
  files: { type: UploadVersionFileType; file: File }[],
  versionNumber: string,
  deviceType: DeviceType,
) => {
  const formData = new FormData();
  formData.append(UploadVersionFormField.VersionNumber, versionNumber);
  formData.append(UploadVersionFormField.DeviceType, deviceType);
  for (const file of files) {
    formData.append(UploadVersionFormField.FileTypes, file.type);
    formData.append(UploadVersionFormField.Files, file.file);
  }

  await apiClient.post(`/fw_versions`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteVersion = async (id: string) => {
  await apiClient.delete(`/fw_versions/${id}`);
};
