import React, { FC, useCallback, useState } from 'react';
import { Marker, Polygon, Tooltip } from 'react-leaflet';

import { useEventHandlers } from './hooks/use-event-handlers';
import { usePolygonCenter } from './hooks/use-polygon-center';
import { resizeIcon } from './helpers';
import { LeafletPolygonRefType, MapEntityComponentProps, MapEntityDisplayMode } from './types';

export const MapEntity: FC<MapEntityComponentProps> = props => {
  const [polygonRef, setPolygonRef] = useState<LeafletPolygonRefType>();
  const { entityKey, zoomLevel, polygon, marker, mapConfig } = props;
  const { polygonEventHandlers, markerEventHandlers, displayMode } = useEventHandlers(props);

  const handlePolygonRefChange = useCallback((ref: LeafletPolygonRefType) => {
    setPolygonRef(ref);
  }, []);

  const isZoomedOut = zoomLevel < mapConfig.showPolygonZoomLevel;
  const polygonCenter = usePolygonCenter({ polygonRef });

  return isZoomedOut || !polygon?.positions?.length ? (
    <Marker {...marker} key={`marker-${entityKey}`} eventHandlers={markerEventHandlers}>
      <Tooltip>{marker.tooltipContent}</Tooltip>
    </Marker>
  ) : (
    <Polygon
      {...polygon}
      key={`polygon-${entityKey}`}
      ref={handlePolygonRefChange as VoidFunction}
      eventHandlers={polygonEventHandlers}
      pathOptions={
        displayMode === MapEntityDisplayMode.Hover ? polygon.hoverPathOptions : polygon.pathOptions
      }
    >
      <Tooltip>{polygon.tooltipContent}</Tooltip>
      {polygon.customMarkers?.map(customMarker => (
        <Marker
          {...customMarker}
          key={`custom-marker-${customMarker.key}`}
          icon={resizeIcon(customMarker.icon, customMarker.iconSize, zoomLevel)}
          position={
            customMarker.alignCenter && polygonCenter ? polygonCenter : customMarker.position
          }
          title=""
        >
          <Tooltip>{customMarker.tooltipContent}</Tooltip>
        </Marker>
      ))}
    </Polygon>
  );
};
