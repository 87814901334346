export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_B2C_CLIENT_ID}`,
    authority: `https://${process.env.REACT_APP_B2C_TENANT_ID}.b2clogin.com/${process.env.REACT_APP_B2C_TENANT_ID}.onmicrosoft.com/b2c_1a_signuporsigninwithcosmos`,
    knownAuthorities: [`${process.env.REACT_APP_B2C_TENANT_ID}.b2clogin.com`],
    redirectUri: window.location.href,
  },
};
export const loginRequest = {
  scopes: [
    'openid',
    'offline_access',
    `https://${process.env.REACT_APP_B2C_TENANT_ID}.onmicrosoft.com/api/user_impersonation`,
  ],
};

export const forgotPasswordRequest = {
  authority: `https://${process.env.REACT_APP_B2C_TENANT_ID}.b2clogin.com/${process.env.REACT_APP_B2C_TENANT_ID}.onmicrosoft.com/B2C_1A_PASSWORDRESET`,
  scopes: [
    'openid',
    'offline_access',
    `https://${process.env.REACT_APP_B2C_TENANT_ID}.onmicrosoft.com/api/user_impersonation`,
  ],
};
