/* eslint-disable @typescript-eslint/naming-convention */
import Papa from 'papaparse';

import {
  ConfigDefaults,
  InputOption,
  SensorType,
} from '../common/store/context-components/sensor-context/sensor-context';
import SensorTypeFile from '../sensor_type.csv';
import SensorTypeIdFile from '../sensor_type_id.csv';

const readCsvFile = async (input: RequestInfo) => {
  try {
    const response = await fetch(input);
    const reader = response?.body?.getReader();
    const result = await reader?.read(); // raw array
    const decoder = new TextDecoder('utf-8');
    const csv = decoder.decode(result?.value); // the csv text
    const results = Papa.parse(csv, { header: true }); // object with { data, errors, meta }
    const rows = results.data || []; // array of objects
    return rows as any[];
  } catch (e) {
    console.error('err', e);
    return [];
  }
};

const getSensorTypeDataFromCSV = async () => {
  const sensorTypesArray = await readCsvFile(SensorTypeFile);
  const sensorTypesIdArray = await readCsvFile(SensorTypeIdFile);
  const combinedSensorTypeObject = sensorTypesArray.map((sensorTypeObject, index) => ({
    ...sensorTypeObject,
    ...sensorTypesIdArray[index],
  }));
  return combinedSensorTypeObject as SensorType[];
};

const getInputOptionsFromSensorTypes = (sensorTypeArray: SensorType[]) => {
  return sensorTypeArray.map(sensorTypeObject => {
    const isVoltage = sensorTypeObject?.expected_unit === 'VOLTAGE';
    const iotFactor = Number(sensorTypeObject?.iot_factor || 0) || 0;
    const iotBias = Number(sensorTypeObject?.iot_bias || 0) || 0;
    const mFactor = isVoltage ? (3.3 / 4095) * 1000 : 1;
    const mBias = 0;
    const tagFactor = iotFactor * mFactor;
    const tagBias = iotBias + mBias;
    const config = { bias: iotBias, factor: iotFactor, tagFactor, tagBias } as ConfigDefaults;
    return {
      label: sensorTypeObject?.name || '',
      value: sensorTypeObject?.type || '',
      configDefaults: config,
    } as InputOption;
  });
};

const getSensorSelectOptions = async () => {
  const sensorTypeRows = await getSensorTypeDataFromCSV();
  const filteredSensorTypes = (sensorTypeRows || []).filter(
    sensorTypeObject => sensorTypeObject.platform === 'IOP' || sensorTypeObject.platform === 'ALL',
  );

  // TODO: Update filtering when new row to distinguish between Analog and digital is added
  const filteredAnalogSensorTypes = filteredSensorTypes.filter(
    sensorTypeObject => sensorTypeObject.expected_unit === 'VOLTAGE',
  );

  const filteredDigitalSensorTypes = filteredSensorTypes.filter(
    sensorTypeObject => !sensorTypeObject.expected_unit,
  );

  const analogOptions = [{ label: 'None', value: 'none' }] as InputOption[];
  const digitalOptions = [{ label: 'None', value: 'none' }] as InputOption[];
  analogOptions.push(...getInputOptionsFromSensorTypes(filteredAnalogSensorTypes));
  digitalOptions.push(...getInputOptionsFromSensorTypes(filteredDigitalSensorTypes));
  return { analogOptions, digitalOptions };
};

const config = {
  getSensorTypeDataFromCSV,
  getSensorSelectOptions,
};

export default config;
