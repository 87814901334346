import { FC, useMemo } from 'react';
import { Box } from '@mui/system';
import * as yup from 'yup';

import { numberOrEmptyString } from '../../../schemas/schemas';
import { setGwConfigs } from '../../../services/api/';
import { GwConfigsInput, GwConfigsOutput } from '../../../services/types/config';
import { AppButton } from '../../buttons/buttons';
import { TextFieldController } from '../../form-controllers/form-controllers';
import { Modal } from '../../modal/modal';
import { transformGwInitialConfigs } from './helpers';
import { useConfigsFormSubmitter } from './hooks';
import { NetworkIdSchema, TelemetryIntervalSchema } from './schemas';

export interface GwConfigureModalProps {
  open: boolean;
  deviceId: string;
  handleClose: () => void;
  currentConfigs?: GwConfigsOutput;
}

const configSchema: yup.SchemaOf<GwConfigsInput> = yup.object({
  apn: yup.string(),
  user: yup.string(),
  pass: yup.string(),
  dbg_log: yup.string(), // eslint-disable-line @typescript-eslint/naming-convention
  telemetry_int: TelemetryIntervalSchema, // eslint-disable-line @typescript-eslint/naming-convention
  nw_id: NetworkIdSchema, // eslint-disable-line @typescript-eslint/naming-convention
  dbg_port: numberOrEmptyString(yup.number()), // eslint-disable-line @typescript-eslint/naming-convention
  bat_lvl_th: numberOrEmptyString(yup.number().min(0)), // eslint-disable-line @typescript-eslint/naming-convention
});

export const ConfigureGwModal: FC<GwConfigureModalProps> = ({
  open,
  deviceId,
  handleClose,
  currentConfigs,
}) => {
  const initialConfigs = useMemo(() => transformGwInitialConfigs(currentConfigs), [currentConfigs]);

  const { control, handleSubmit, onSubmit } = useConfigsFormSubmitter(
    open,
    handleClose,
    configSchema,
    async (input: GwConfigsInput) => {
      await setGwConfigs(deviceId, input);
    },
    'Successfully Configure Gateway',
    initialConfigs,
  );

  return (
    <Modal handleClose={handleClose} open={open} title="Configure Gateway">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <TextFieldController control={control} label="APN" name="apn" />
          <TextFieldController control={control} label="User" name="user" />
          <TextFieldController control={control} label="Password" name="pass" />
          <TextFieldController control={control} label="Debug Log" name="dbg_log" />
          <TextFieldController control={control} label="Telemetry Interval" name="telemetry_int" />
          <TextFieldController control={control} label="Network ID" name="nw_id" />
          <TextFieldController control={control} label="Debug Port" name="dbg_port" />
          <TextFieldController control={control} label="Battery Level" name="bat_lvl_th" />

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
            <AppButton type="submit">Submit</AppButton>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};
