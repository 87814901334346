import { FC, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { getDevice } from '../../../services/api/device';
import { RequestQuery } from '../../../services/react-query-request-names';
import { ErrorResponse } from '../../../services/types';
import { AppButton } from '../../buttons/buttons';
import { QRCodeField } from '../../qr-reader/qr-reader';
import { TagGwIdsContext } from '../../store/tag-gw-device-id-context';
import { associateDevice } from './api';

type AssociateRepeaterFirstStepProps = {
  handleNext: () => void;
  handleBack: () => void;
  closeModal: () => void;
  backButtonVisible: boolean;
  nextButtonVisible: boolean;
};

const schema = yup
  .object()
  .shape({
    id: yup.string().required('Please scan qr'),
  })
  .required();

export type RepeaterProps = yup.InferType<typeof schema>;

export const AssociateFirstStep: FC<AssociateRepeaterFirstStepProps> = ({
  handleNext,
  handleBack,
  backButtonVisible,
  closeModal,
}) => {
  const { control, handleSubmit, setValue, watch, reset } = useForm<RepeaterProps>({
    resolver: yupResolver(schema),
  });
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    setTagGwIds,
    tagGwIds: { tagId, gwId },
  } = useContext(TagGwIdsContext);
  const watchDeviceId = watch('id');

  useEffect(() => {
    if (tagId) {
      setValue('id', tagId);
    }
  }, [setValue, tagId]);

  const handleScan = (data: any) => {
    setValue('id', data, { shouldValidate: false });
  };

  const onSubmit = ({ id }: RepeaterProps) => {
    getDevice(id)
      .then(data => {
        if (data.data.gatewayId === null) {
          setTagGwIds({ tagId: id });
          associateDevice(id, gwId)
            .then(() => {
              queryClient.invalidateQueries(RequestQuery.DeviceByType);
              enqueueSnackbar('Successfully added', { variant: 'success' });
              handleNext();
            })
            .catch(err => {
              if (err.response) {
                const error = err.response.data as ErrorResponse;
                enqueueSnackbar(error.errors[0].description, { variant: 'error' });
                closeModal();
              }
            });
        } else {
          reset({ id: undefined });
          enqueueSnackbar('This devices is already attached to another GW', { variant: 'error' });
        }
      })
      .catch(err => {
        if (err.response) {
          const error = err.response.data as ErrorResponse;
          enqueueSnackbar(error.errors[0].description, { variant: 'error' });
        }
      });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <form>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '300px',
              gap: 1,
            }}
          >
            {tagId === undefined ? (
              <QRCodeField isActive={watchDeviceId ? false : true} setQrCode={handleScan} />
            ) : null}
            <Controller
              control={control}
              name="id"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={tagId ? true : false}
                  error={error ? true : false}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: true }}
                  label="Serial Number"
                  required
                />
              )}
            />
          </Box>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
          {backButtonVisible && <AppButton onClick={handleBack}>Back</AppButton>}
          <AppButton
            endIcon={<ArrowForwardIcon />}
            onClick={handleSubmit((values: RepeaterProps) => {
              onSubmit(values);
            })}
          >
            Next
          </AppButton>
        </Box>
      </form>
    </Box>
  );
};
