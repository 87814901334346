import { GridRowModel, GridSortModel } from '@mui/x-data-grid';

import { Sorts, UserData, Users } from '../../services/types';

export const transformDataForTable = (users: Users): GridRowModel[] => {
  return users.data.data.map(user => {
    return {
      id: user?.email,
      fullName: user?.fullName,
      email: user?.email,
      role: user?.role,
    };
  });
};

export const getCurrentUser = (users: Users, email: string) => {
  const currentUser = users.data.data.filter(user => user.email === email)[0];
  return currentUser as UserData;
};

export const transformSorts = (sorts: GridSortModel): Sorts => {
  return {
    orderBy: {
      field: {
        name: sorts[0]?.field,
      },
      direction: !sorts[0]?.sort ? undefined : sorts[0].sort == 'asc' ? 'ascending' : 'descending',
    },
  };
};
