import L, { Icon } from 'leaflet';

import { CustomMarkerProps } from './types';

const MAP_DEFAULT_ICON_SIZE = 14;
const MAP_DEFAULT_ICON_ZOOM = 18;

export const resizeIcon = (
  icon: CustomMarkerProps['icon'],
  iconSize: CustomMarkerProps['iconSize'],
  zoomLevel: number,
): Icon => {
  const iconSizeValue =
    iconSize ?? MAP_DEFAULT_ICON_SIZE / 2 ** (zoomLevel * -1 + MAP_DEFAULT_ICON_ZOOM);

  const anchorValue = iconSizeValue / 2;

  return new L.Icon({
    iconUrl: icon,
    iconAnchor: [anchorValue, anchorValue],
    iconSize: [iconSizeValue, iconSizeValue],
  });
};
