import { FC, useCallback, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { AppButton } from '../../../../common/buttons/buttons';
import { gatewayDefaultConfigurations } from '../../../../common/default-configurations/default-configurations';
import { QRCodeField } from '../../../../common/qr-reader/qr-reader';
import { DeviceIdContext } from '../../../../common/store/device-id-context';
import { getDevice } from '../../../../services/api/device';
import { RequestQuery } from '../../../../services/react-query-request-names';
import { Device, ErrorResponse, GatewayAddBody } from '../../../../services/types';
import { addGateway, updateGateway } from '../api';
import { convertRawDataToFirstStepFormFields } from '../helper';

type AddGatewayFirstStepProps = {
  handleNext: () => void;
  handleBack: () => void;
  closeModal: () => void;
  backButtonVisible: boolean;
  nextButtonVisible: boolean;
};

const schema = yup.object({
  gateway: yup.object({
    id: yup.string().required('Please scan qr').matches(/^\d+$/, 'Value must be digits only'),
  }),
  metadata: yup.object({
    name: yup.string().optional(),
    grower: yup.string().optional(),
    reseller: yup.string().optional(),
    serviceProvider: yup.string().optional(),
    phoneNumber: yup.string().optional(),
    gwType: yup.string().optional(),
    imsi: yup.string().optional(),
  }),
});

export type GatewayProps = yup.InferType<typeof schema>;

enum FirstStepFieldName {
  'name' = 'metadata.name',
  'grower' = 'metadata.grower',
  'reseller' = 'metadata.reseller',
  'serviceProvider' = 'metadata.serviceProvider',
  'phoneNumber' = 'metadata.phoneNumber',
  'gwType' = 'metadata.gwType',
  'imsi' = 'metadata.imsi',
}
const firstStepFields = [
  { label: 'Name', fieldName: FirstStepFieldName.name },
  { label: 'Grower', fieldName: FirstStepFieldName.grower },
  { label: 'Resseler', fieldName: FirstStepFieldName.reseller },
  { label: 'Service Provider', fieldName: FirstStepFieldName.serviceProvider },
  { label: 'Phone Number', fieldName: FirstStepFieldName.phoneNumber },
  { label: 'Gateway Type', fieldName: FirstStepFieldName.gwType },
  { label: 'IMSI', fieldName: FirstStepFieldName.imsi },
];

export const AddGatewayFirstStep: FC<AddGatewayFirstStepProps> = ({
  handleNext,
  handleBack,
  closeModal,
  backButtonVisible,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty },
    watch,
  } = useForm<GatewayProps>({
    resolver: yupResolver(schema),
  });
  const { enqueueSnackbar } = useSnackbar();
  const { setDeviceId, deviceId } = useContext(DeviceIdContext);
  const watchDeviceId = watch('gateway.id');
  const queryClient = useQueryClient();

  useEffect(() => {
    if (deviceId) {
      getDevice(deviceId).then(res => {
        const initialValues = convertRawDataToFirstStepFormFields(res);
        for (const [key, value] of Object.entries(initialValues)) {
          setValue(key as any, value, { shouldValidate: true });
        }
      });
    }
  }, [deviceId, setValue]);

  const handleScan = useCallback(
    (data: any) => {
      setValue('gateway.id', data, { shouldValidate: false });
    },
    [setValue],
  );

  const closeModalAndClearDeviceId = useCallback(() => {
    setDeviceId(undefined);
    closeModal();
  }, [closeModal, setDeviceId]);

  const handleGatewayUpdated = useCallback(
    (promise: Promise<Device>, successMessage: string, nextStep?: boolean, deviceId?: string) => {
      promise
        .then(() => {
          enqueueSnackbar(successMessage, { variant: 'success' });
          queryClient.invalidateQueries(RequestQuery.DeviceByType);

          nextStep ? handleNext() : closeModalAndClearDeviceId();
          setDeviceId(deviceId);
        })
        .catch(err => {
          if (err.response) {
            const error = err.response.data as ErrorResponse;
            enqueueSnackbar(error?.errors ? error?.errors[0]?.description : 'Error 500', {
              variant: 'error',
            });
          }
        });
    },
    [closeModalAndClearDeviceId, enqueueSnackbar, handleNext, queryClient, setDeviceId],
  );

  const onSubmit = (values: GatewayProps, nextStep: boolean) => {
    if (deviceId) {
      if (isDirty) {
        handleGatewayUpdated(
          updateGateway(values),
          'Successfully update GW',
          nextStep,
          values.gateway.id,
        );
      } else {
        nextStep ? handleNext() : closeModalAndClearDeviceId();
      }
    } else {
      const addGwBody: GatewayAddBody = {
        ...values,
        configurations: gatewayDefaultConfigurations,
      };
      handleGatewayUpdated(
        addGateway(addGwBody),
        'Successfully added',
        nextStep,
        values.gateway.id,
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <form>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {deviceId === undefined ? (
              <QRCodeField isActive={watchDeviceId ? false : true} setQrCode={handleScan} />
            ) : null}
            <Controller
              control={control}
              name="gateway.id"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={deviceId ? true : false}
                  error={error ? true : false}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: true }}
                  label="Serial Number"
                  required
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {firstStepFields.map(({ label, fieldName }, index) => (
              <Controller
                key={index}
                control={control}
                name={fieldName}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    label={label}
                    variant="outlined"
                  />
                )}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1 }}>
          {backButtonVisible && <AppButton onClick={handleBack}>Back</AppButton>}
          <AppButton
            type="submit"
            onClick={handleSubmit((values: GatewayProps) => {
              onSubmit(values, false);
            })}
          >
            {deviceId ? 'Update' : 'Add to repository'}
          </AppButton>
          <AppButton
            endIcon={<ArrowForwardIcon />}
            onClick={handleSubmit((values: GatewayProps) => {
              onSubmit(values, true);
            })}
          >
            Next
          </AppButton>
        </Box>
      </form>
    </Box>
  );
};
