import { GridColDef } from '@mui/x-data-grid';

import {
  batvoltField,
  fwVersionField,
  groupIdField,
  inputSignalField,
  nodeField,
  outPutSignalField,
  plotField,
  tagRepeaterStatusField,
  tsField,
} from './common';

export const repeaterIdField: GridColDef = {
  field: 'deviceId',
  headerName: 'Repeater Id',
  type: 'customString',
  minWidth: 140,
  align: 'center',
  headerAlign: 'center',
};

export const repeatersColumns: GridColDef[] = [
  repeaterIdField,
  groupIdField,
  fwVersionField,
  plotField,
  tagRepeaterStatusField,
  batvoltField,
  inputSignalField,
  outPutSignalField,
  nodeField,
  tsField,
];
