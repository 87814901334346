import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { transformBatteryToIcon } from '../../../components/devices/gateways/helper';
import { DeviceSelectAlertType } from '../../../services/types';
import theme from '../../theme';
import { CustomTooltip } from '../../tooltip/tooltip';
import { FWVersionCell, FWVersionProps, StatusCell, StatusFieldProps } from '../cells';

export const fwVersionField: GridColDef = {
  field: 'fwVersion',
  headerName: 'FW Version',
  type: 'customStringOperatorEqual',
  width: 160,
  sortable: true,
  filterable: true,
  align: 'center',
  headerAlign: 'center',
  renderCell: (params: GridRenderCellParams<FWVersionProps>) => (
    <FWVersionCell current={params?.value?.current} pending={params?.value?.pending} />
  ),
};

export const growerField: GridColDef = {
  field: 'grower',
  headerName: 'Grower',
  type: 'customString',
  minWidth: 110,
  align: 'center',
  headerAlign: 'center',
};

const statusField: GridColDef = {
  field: 'status',
  headerName: 'Status',
  type: 'customSingleSelectContain',
  minWidth: 70,
  align: 'center',
  sortable: false,
  headerAlign: 'center',
  valueOptions: [
    DeviceSelectAlertType.lowBattery,
    DeviceSelectAlertType.lowLTE,
    DeviceSelectAlertType.gwOffline,
    DeviceSelectAlertType.outOfNetwork,
  ],

  renderCell: (params: GridRenderCellParams<StatusFieldProps>) => (
    <StatusCell alerts={params?.value?.alerts || []} connection={params?.value?.connection} />
  ),
};

export const gwStatusField: GridColDef = {
  ...statusField,
  valueOptions: [
    DeviceSelectAlertType.lowBattery,
    DeviceSelectAlertType.lowLTE,
    DeviceSelectAlertType.gwOffline,
  ],
};

export const tagRepeaterStatusField: GridColDef = {
  ...statusField,
  valueOptions: [
    DeviceSelectAlertType.lowBattery,
    DeviceSelectAlertType.lowRssi,
    DeviceSelectAlertType.gwOffline,
    DeviceSelectAlertType.outOfNetwork,
  ],
};

export const batvoltField: GridColDef = {
  field: 'batvolt',
  headerName: 'Battery',
  type: 'customNumberSmallerBigger',
  minWidth: 70,
  sortable: true,
  align: 'center',
  headerAlign: 'center',
  renderCell: (params: GridRenderCellParams) => (
    <Box alignItems="center" display="flex" justifyContent="center">
      {transformBatteryToIcon(params.value.value, '24px', params.value.millivolt)}
    </Box>
  ),
};

export const tsField: GridColDef = {
  field: 'ts',
  headerName: 'Seen',
  minWidth: 150,
  sortable: false,
  type: 'customData',
  filterable: false,
  align: 'center',
  headerAlign: 'center',
};

export const plotField: GridColDef = {
  field: 'plot',
  headerName: 'Plot',
  type: 'customString',
  width: 110,
  align: 'center',
  headerAlign: 'center',
};

export const inputSignalField: GridColDef = {
  field: 'inputSignal',
  headerName: 'Input signal',
  type: 'customString',
  minWidth: 100,
  align: 'center',
  headerAlign: 'center',
  renderCell: (params: GridRenderCellParams) => (
    <Typography color={params?.value < -100 ? theme.color.error : theme.color.black}>
      {params.value}
    </Typography>
  ),
};

export const outPutSignalField: GridColDef = {
  field: 'outputSignal',
  headerName: 'Output signal',
  type: 'customString',
  width: 120,
  align: 'center',
  headerAlign: 'center',
  renderCell: (params: GridRenderCellParams) => (
    <Typography color={params?.value < -100 ? theme.color.error : theme.color.black}>
      {params.value}
    </Typography>
  ),
};

export const groupIdField: GridColDef = {
  field: 'gwId',
  headerName: 'GW Id',
  type: 'customString',
  width: 150,
  align: 'center',
  headerAlign: 'center',
};

export const plotFieldShort: GridColDef = {
  ...plotField,
  width: 100,
  sortable: false,
  filterable: false,
  align: 'center',
  headerAlign: 'center',
};

export const statusFieldShort: GridColDef = {
  ...statusField,
  width: 75,
  sortable: false,
  filterable: false,
  align: 'center',
  headerAlign: 'center',
};
export const batvoltFieldShort: GridColDef = {
  ...batvoltField,
  sortable: false,
  filterable: false,
  width: 75,
  align: 'center',
  headerAlign: 'center',
};

export const inputSignalFieldShort: GridColDef = {
  ...inputSignalField,
  width: 75,
  sortable: false,
  filterable: false,
  align: 'center',
  headerAlign: 'center',
};
export const outputSignalFieldShort: GridColDef = {
  ...outPutSignalField,
  width: 75,
  sortable: false,
  filterable: false,
  align: 'center',
  headerAlign: 'center',
};

export const nodeField: GridColDef = {
  field: 'ulNode',
  headerName: 'Node',
  type: 'customString',
  minWidth: 100,
  align: 'center',
  headerAlign: 'center',
};
