import {
  GwConfigsOutput,
  RepeaterConfigsOutput,
  TagConfigsInput,
  TagConfigsOutput,
} from '../../../services/types/config';
import { SensorSelectTypeOptions } from '../../store/context-components/sensor-context/sensor-context';
import { ConfigValue } from './types';

function passwordHide(pw: string | undefined | null) {
  const visibleLen = 2;
  if (!pw) {
    return null;
  } else if (pw.length < visibleLen) {
    return '*'.repeat(visibleLen);
  }
  const short = pw.slice(0, visibleLen);
  return short + '*'.repeat(pw.length - visibleLen);
}

function numberArrayToString(arr: number[] | null | undefined) {
  if (!arr) {
    return null;
  }
  return arr.join(', ');
}

export function convertGwConfigs(configs: GwConfigsOutput): ConfigValue[] {
  return [
    {
      name: 'APN',
      pending: configs?.apn?.pending,
      current: configs?.apn?.reported,
    },
    {
      name: 'Batterly Level Threshold',
      pending: configs?.bat_lvl_th?.pending,
      current: configs?.bat_lvl_th?.reported,
    },
    {
      name: 'Debug Log Dir',
      pending: configs?.dbg_log?.pending,
      current: configs?.dbg_log?.reported,
    },
    {
      name: 'Debug Port',
      pending: configs?.dbg_port?.pending,
      current: configs?.dbg_port?.reported,
    },
    {
      name: 'Network ID',
      pending: configs?.nw_id?.pending,
      current: configs?.nw_id?.reported,
      tooltip: "device's network group ID",
    },
    {
      name: 'Password',
      pending: passwordHide(configs?.pass?.pending),
      current: passwordHide(configs?.pass?.reported),
    },
    {
      name: 'User',
      pending: configs?.user?.pending,
      current: configs?.user?.reported,
    },
    {
      name: 'Telemetry Interval',
      pending: configs?.telemetry_int?.pending,
      current: configs?.telemetry_int?.reported,
      tooltip: 'Frequency of telemetry data transmission to Gateway',
    },
  ];
}

// Function used to generate local Tag config (instead of using server Data)
export function convertTagConfigs(
  configs: TagConfigsOutput,
  localTagConfig: TagConfigsInput, // Local sensor tag data
): ConfigValue[] {
  return [
    {
      // TODO: Remove this forced local values when required
      name: 'Analog Inputs Bias',
      pending: numberArrayToString(localTagConfig?.bias),
      current: numberArrayToString(localTagConfig?.bias),
      tooltip: 'Linear transformation parameter for Analog1, Analog2 and UART inputs respectively',
    },
    {
      name: 'Exicitation',
      pending: configs?.exicitation?.pending,
      current: configs?.exicitation?.reported,
      tooltip: "Time period of sensor's Power ON before sampling",
    },
    {
      // TODO: Remove this forced local values when required
      name: 'Analog Inputs Factor',
      pending: numberArrayToString(localTagConfig?.fac),
      current: numberArrayToString(localTagConfig?.fac),
      tooltip: 'Linear transformation parameter for Analog1, Analog2 and UART inputs respectively',
    },
    {
      name: 'Network ID',
      pending: configs?.nw_id?.pending,
      current: configs?.nw_id?.reported,
      tooltip: "device's network group ID",
    },
    {
      name: 'Number of Samples',
      pending: configs?.smplsNum?.pending,
      current: configs?.smplsNum?.reported,
      tooltip: 'Number of consequent samples of the voltage value (to be averaged)',
    },
    {
      name: 'Space',
      pending: configs?.space?.pending,
      current: configs?.space?.reported,
      tooltip: 'Period of time between consequent samples in [msec]',
    },
    {
      name: 'Telemetry Interval',
      pending: configs?.telemetry_int?.pending,
      current: configs?.telemetry_int?.reported,
      tooltip: 'Frequency of telemetry data transmission to Gateway',
    },
    {
      name: 'Transmit Window',
      pending: configs?.trans_win?.pending,
      current: configs?.trans_win?.reported,
      tooltip: 'Frequency of data sampling in [sec]',
    },
  ];
}

export function convertRepeaterConfigs(configs: RepeaterConfigsOutput): ConfigValue[] {
  return [
    {
      name: 'Network ID',
      pending: configs?.nw_id?.pending,
      current: configs?.nw_id?.reported,
      tooltip: "device's network group ID",
    },
    {
      name: 'Telemetry Interval',
      pending: configs?.telemetry_int?.pending,
      current: configs?.telemetry_int?.reported,
      tooltip: 'Frequency of telemetry data transmission to Gateway',
    },
  ];
}
