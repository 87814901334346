import '@fontsource/metropolis';

import { BrowserRouter } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

import { AppRoutes } from '../common/routes';
import { SensorTypeContextProvider } from '../common/store/context-components/sensor-context/sensor-context';
import { muiTheme } from '../common/theme';
import { useSetTokenAndActiveAccount } from '../services/client/axios-client';
import { ReactQueryProvider } from '../services/client/react-query-client';

import '../common/global-styles.css';

type AppProps = {
  pca: IPublicClientApplication;
};

const App = ({ pca }: AppProps) => {
  useSetTokenAndActiveAccount(pca);
  return (
    <ThemeProvider theme={muiTheme}>
      <MsalProvider instance={pca}>
        <ReactQueryProvider>
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <SensorTypeContextProvider>
                <AppRoutes />
              </SensorTypeContextProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </ReactQueryProvider>
      </MsalProvider>
    </ThemeProvider>
  );
};

export default App;
