import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { AppButton } from '../../../../common/buttons/buttons';
import { getTagInitialDefaultConfigs } from '../../../../common/default-configurations/default-configurations';
import { QRCodeField } from '../../../../common/qr-reader/qr-reader';
import { SensorTypesSelectContext } from '../../../../common/store/context-components/sensor-context/sensor-context';
import { DeviceIdContext } from '../../../../common/store/device-id-context';
import { getDevice } from '../../../../services/api';
import { RequestQuery } from '../../../../services/react-query-request-names';
import { ErrorResponse, TagAddBody, TagsMetadata } from '../../../../services/types';
import { addTag } from '../api';
import { convertRawDataToFirstStepFormFields } from '../helpers';
import { updateTagAndConfigs } from './helpers';

type AddTagFirstStepProps = {
  handleNext: () => void;
  handleBack: () => void;
  closeModal: () => void;
  backButtonVisible: boolean;
  nextButtonVisible: boolean;
};

const schema = yup.object({
  id: yup.string().required('Please scan qr').matches(/^\d+$/, 'Value must be digits only'),
  metadata: yup.object({
    analog1: yup.string().optional(),
    analog2: yup.string().optional(),
    digital: yup.string().optional(),
  }),
});

export type TagProps = yup.InferType<typeof schema>;

enum FirstStepFieldName {
  'analog1' = 'metadata.analog1',
  'analog2' = 'metadata.analog2',
  'digital' = 'metadata.digital',
}

export const AddTagFirstStep: FC<AddTagFirstStepProps> = ({
  handleNext,
  handleBack,
  closeModal,
  backButtonVisible,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
    watch,
  } = useForm<TagProps>({
    resolver: yupResolver(schema),
    defaultValues: { metadata: { analog1: 'none', analog2: 'none', digital: 'none' } },
  });
  const queryClient = useQueryClient();

  const sensorTypeOptions = useContext(SensorTypesSelectContext);
  const { enqueueSnackbar } = useSnackbar();
  const { setDeviceId, deviceId } = useContext(DeviceIdContext);
  const watchDeviceId = watch('id');
  const watchAnalog1 = watch('metadata.analog1');
  const watchAnalog2 = watch('metadata.analog2');
  const watchDigital = watch('metadata.digital');
  const [initialMeta, setInitialMeta] = useState<TagsMetadata>();

  const emptyOrNone = (str: string | undefined) => {
    return str == 'none' || !str;
  };

  useEffect(() => {
    if (deviceId) {
      getDevice(deviceId).then(res => {
        setInitialMeta(res?.data?.metadata as TagsMetadata);
        const initialValues = convertRawDataToFirstStepFormFields(res);
        reset(initialValues);
      });
    }
  }, [deviceId, reset, setValue]);

  const handleScan = (data: any) => {
    setValue('id', data, { shouldValidate: false });
  };
  const closeModalAndClearDeviceId = useCallback(() => {
    setDeviceId(undefined);
    closeModal();
  }, [closeModal, setDeviceId]);

  const handleTagUpdated = useCallback(
    (promise: Promise<any>, successMessage: string, nextStep?: boolean) => {
      return promise
        .then(() => {
          queryClient.invalidateQueries(RequestQuery.DeviceByType);
          enqueueSnackbar(successMessage, { variant: 'success' });
          nextStep ? handleNext() : closeModalAndClearDeviceId();
        })
        .catch(err => {
          if (err.response) {
            reset({
              id: undefined,
            });
            setDeviceId(undefined);
            const error = err.response.data as ErrorResponse;
            enqueueSnackbar(error.errors[0].description, { variant: 'error' });
          }
        });
    },
    [closeModalAndClearDeviceId, enqueueSnackbar, handleNext, queryClient, reset, setDeviceId],
  );

  const onSubmit = useCallback(
    (input: TagProps, nextStep: boolean) => {
      setDeviceId(input.id);
      if (deviceId) {
        if (isDirty) {
          handleTagUpdated(
            updateTagAndConfigs(
              sensorTypeOptions.iopSensorSelectOptions,
              input.id,
              input.metadata,
              initialMeta,
            ),
            'Successfully update tag',
            nextStep,
          );
        } else {
          nextStep ? handleNext() : closeModalAndClearDeviceId();
        }
      } else {
        const tagAddBody: TagAddBody = {
          metadata: input.metadata,
          configurations: getTagInitialDefaultConfigs(
            sensorTypeOptions.iopSensorSelectOptions,
            input.metadata?.analog1,
            input.metadata?.analog2,
            input.metadata?.digital,
          ),
        };
        handleTagUpdated(addTag(tagAddBody, input.id), 'Successfully added', nextStep).then(() => {
          queryClient.invalidateQueries(RequestQuery.DeviceByType);
        });
      }
    },
    [
      sensorTypeOptions.iopSensorSelectOptions,
      closeModalAndClearDeviceId,
      deviceId,
      handleNext,
      handleTagUpdated,
      initialMeta,
      isDirty,
      queryClient,
      setDeviceId,
    ],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <form>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {deviceId === undefined ? (
              <QRCodeField isActive={watchDeviceId ? false : true} setQrCode={handleScan} />
            ) : null}
            <Controller
              control={control}
              name="id"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={deviceId ? true : false}
                  error={error ? true : false}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: true }}
                  label="Serial Number"
                  required
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel id={FirstStepFieldName.analog1}>Analog 1</InputLabel>
              <Controller
                control={control}
                name={FirstStepFieldName.analog1}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={!emptyOrNone(watchAnalog2) && !emptyOrNone(watchDigital)}
                    sx={{ mt: 1 }}
                  >
                    {sensorTypeOptions.iopSensorSelectOptions.analogOptions.map(
                      ({ label, value }, index) => (
                        <MenuItem key={index} value={value}>
                          {label}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel id={FirstStepFieldName.analog2}>Analog 2</InputLabel>
              <Controller
                control={control}
                name={FirstStepFieldName.analog2}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={!emptyOrNone(watchAnalog1) && !emptyOrNone(watchDigital)}
                    sx={{ mt: 1 }}
                  >
                    {sensorTypeOptions.iopSensorSelectOptions.analogOptions.map(
                      ({ label, value }, index) => (
                        <MenuItem key={index} value={value}>
                          {label}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel id={FirstStepFieldName.digital}>Digital </InputLabel>
              <Controller
                control={control}
                name={FirstStepFieldName.digital}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={!emptyOrNone(watchAnalog1) && !emptyOrNone(watchAnalog2)}
                    sx={{ mt: 1 }}
                  >
                    {sensorTypeOptions.iopSensorSelectOptions.digitalOptions.map(
                      ({ label, value }, index) => (
                        <MenuItem key={index} value={value}>
                          {label}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
          {backButtonVisible && <AppButton onClick={handleBack}>Back</AppButton>}
          <AppButton
            type="submit"
            onClick={handleSubmit((values: TagProps) => {
              onSubmit(values, false);
            })}
          >
            {deviceId ? 'Update' : 'Add and Close'}
          </AppButton>
          <AppButton
            endIcon={<ArrowForwardIcon />}
            onClick={handleSubmit((values: TagProps) => {
              onSubmit(values, true);
            })}
          >
            Attach to Gateway
          </AppButton>
        </Box>
      </form>
    </Box>
  );
};
