import { FC } from 'react';

import { DeviceData, DeviceType } from '../../../services/types';
import {
  GwConfigsOutput,
  RepeaterConfigsOutput,
  TagConfigsOutput,
} from '../../../services/types/config';
import { ConfigureGwModal } from './configure-gw-modal';
import { ConfigureRepeaterModal } from './configure-repeater-modal';
import { ConfigureTagModal } from './configure-tag-modal';

interface ConfigureModalProps {
  open: boolean;
  deviceId: string;
  deviceType: DeviceType;
  handleClose: () => void;
  deviceData: DeviceData;
  currentConfigs?: GwConfigsOutput | TagConfigsOutput | RepeaterConfigsOutput;
}

export const ConfigureModal: FC<ConfigureModalProps> = ({
  handleClose,
  open,
  deviceId,
  deviceType,
  deviceData,
  currentConfigs,
}) => {
  switch (deviceType) {
    case DeviceType.gateway:
      return (
        <ConfigureGwModal
          currentConfigs={currentConfigs}
          deviceId={deviceId}
          handleClose={handleClose}
          open={open}
        />
      );
    case DeviceType.tag:
      return (
        <ConfigureTagModal
          currentConfigs={currentConfigs}
          deviceData={deviceData}
          deviceId={deviceId}
          handleClose={handleClose}
          open={open}
        />
      );
    case DeviceType.repeater:
      return (
        <ConfigureRepeaterModal
          currentConfigs={currentConfigs}
          deviceId={deviceId}
          handleClose={handleClose}
          open={open}
        />
      );
  }
};
