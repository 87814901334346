import * as yup from 'yup';

export function numberOrEmptyString(numberSchema: yup.NumberSchema) {
  return yup
    .mixed()
    .test(value => !value || numberSchema.isValidSync(value))
    .transform(val => {
      if (typeof val === 'string' && val.trim() === '') return undefined;
      if (!isNaN(val)) return Number(val);
      return val;
    });
}
