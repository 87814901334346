import { FC, useCallback, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';

import { DeviceData, DeviceType } from '../../services/types';
import { detachDeviceFromGW, RemoveDeviceProps } from '../alert/api';
import { DeviceAlert } from '../alert/device-alert';
import { AppButton } from '../buttons/buttons';
import { DeviceTable } from '../device-table/device-table';
import { ModalWithSteps } from '../modal/modal-with-steps';
import { TagGwIds, TagGwIdsContext } from '../store/tag-gw-device-id-context';
import { CreateStepsProps, Step } from '../types';
import { DeviceDetails } from './device-details';
import { getPanelsWithoutTags, Panel } from './helpers';

type DeviceDetailsTableProps = {
  data: DeviceData;
  deviceType: DeviceType;
  columns: GridColDef[];
  stepsCreator: ({
    handleBack,
    handleNext,
    handleCloseAndReset,
    backButtonVisible,
    nextButtonVisible,
  }: CreateStepsProps) => Step[];
};

export const DeviceDetailsTable: FC<DeviceDetailsTableProps> = ({
  data,
  columns,
  deviceType,
  stepsCreator,
}) => {
  const [panels, setPanels] = useState<Panel[]>();
  const [tagGwIds, setTagGwIds] = useState<TagGwIds>({});
  const [openAssociate, setOpenAssociate] = useState(false);
  const [rowSelectedId, setRowSelectedId] = useState('');

  const handleCloseAssociate = useCallback(() => {
    setOpenAssociate(false);
    setTagGwIds(prev => {
      return { ...prev, tagId: undefined };
    });
  }, []);

  const value = {
    tagGwIds: tagGwIds,
    setTagGwIds: (newState: TagGwIds) => {
      setTagGwIds(prev => {
        return {
          ...prev,
          ...newState,
        };
      });
    },
  };

  useEffect(() => {
    setTagGwIds(prev => {
      return { ...prev, gwId: data.deviceId };
    });
  }, [data]);

  const onCellClick = useCallback((device?: DeviceData) => {
    if (device) {
      setPanels(getPanelsWithoutTags({ data: device, deviceType: device.type as DeviceType }));
      setRowSelectedId(device.deviceId);
    } else {
      setPanels(undefined);
      setRowSelectedId('');
    }
  }, []);

  const handleDetach = useCallback(async (device: RemoveDeviceProps) => {
    await detachDeviceFromGW(device);
    setRowSelectedId('');
  }, []);

  return (
    <Box
      sx={{
        display: 'grid',
        height: '100%',
        gridTemplateColumns: panels ? '1fr 1fr' : '1fr',
        gap: 1,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Paper sx={{ width: '100%', height: 60, padding: 1, display: 'flex', gap: 2 }}>
          <AppButton onClick={() => setOpenAssociate(true)}>Associate {deviceType}</AppButton>
          <DeviceAlert
            buttonTitle="Detach"
            description="Do you want to detach this device?"
            deviceId={rowSelectedId}
            title={`Detaching device with id ${rowSelectedId}`}
            onDelete={handleDetach}
          />
        </Paper>
        <Box sx={{ height: '100%' }}>
          <DeviceTable
            columns={columns}
            deviceType={[deviceType]}
            gatewayId={data.gatewayId}
            onRowClick={onCellClick}
          />
        </Box>
        <TagGwIdsContext.Provider value={value}>
          <ModalWithSteps
            handleClose={handleCloseAssociate}
            open={openAssociate}
            stepCreator={stepsCreator}
            title={`Associate ${deviceType}`}
          />
        </TagGwIdsContext.Provider>
      </Box>
      {panels && <DeviceDetails panels={panels} />}
    </Box>
  );
};
