import { apiClient } from '../../services/client/axios-client';
import { Device, User } from '../../services/types';

export type RemoveDeviceProps = {
  deviceId: string;
};

export type DeleteUserProps = {
  email: string;
};

export const removeDevice = (data: RemoveDeviceProps) => {
  return apiClient.delete<Device>(`/devices/${data.deviceId}`);
};

export const detachDeviceFromGW = (data: RemoveDeviceProps) => {
  return apiClient.put<Device>(`/devices/${data.deviceId}`, {
    gateway: { id: null },
  });
};

export const deleteUser = (data: DeleteUserProps) => {
  return apiClient.delete<User>(`/users/${data.email}`);
};
