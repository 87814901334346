import { FC } from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Box, Typography } from '@mui/material';

import { transformAlertsToIcons } from '../../components/devices/gateways/helper';
import theme from '../theme';

export type FWVersionProps = {
  current?: string;
  pending?: string;
};

export type StatusFieldProps = {
  alerts: string[];
  connection?: string;
};

export const FWVersionCell: FC<FWVersionProps> = ({ current, pending }) => {
  return (
    <Box display="flex">
      <Typography>{current}</Typography>
      {pending && (
        <>
          <AutorenewIcon
            sx={{ ml: 1.5, fill: theme.palette.primary.main, fontSize: 18, mt: 0.4, mr: 0.3 }}
          />
          <Typography sx={{ color: theme.palette.primary.main }}>{pending}</Typography>
        </>
      )}
    </Box>
  );
};

export const StatusCell: FC<StatusFieldProps> = ({ alerts, connection }): JSX.Element => {
  return (
    <Box alignItems="center" display="flex" justifyContent="center">
      {transformAlertsToIcons(alerts, connection).map((elem, index) => (
        <Box key={index} alignItems="center" display="flex" justifyContent="center">
          {elem}
        </Box>
      ))}
    </Box>
  );
};
