import { FC, useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { CircularProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';

import { RequestQuery } from '../../services/react-query-request-names';
import { DeviceType } from '../../services/types';
import { AppButton } from '../buttons/buttons';
import { getDevices } from '../device-table/api';
import { RemoveDeviceProps } from './api';

type DeviceAlertProps = {
  buttonTitle: string;
  deviceId: string;
  description: string;
  title: string;
  deviceType?: DeviceType;
  onDelete: (data: RemoveDeviceProps) => Promise<any>;
};

enum OpenState {
  Closed,
  OpenedWaiting,
  OpenedNotAllowed,
  OpenedAllowed,
}

export const DeviceAlert: FC<DeviceAlertProps> = ({
  buttonTitle,
  deviceId,
  description,
  title,
  onDelete,
  deviceType,
}) => {
  const [openState, setOpenState] = useState<OpenState>(OpenState.Closed);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = useCallback(() => {
    if (deviceType === DeviceType.gateway && deviceId) {
      setOpenState(OpenState.OpenedWaiting);
      getDevices({ gatewayId: deviceId }).then(devices => {
        if (devices.data.data.length > 1) {
          setOpenState(OpenState.OpenedNotAllowed);
        } else {
          setOpenState(OpenState.OpenedAllowed);
        }
      });
    } else {
      setOpenState(OpenState.OpenedAllowed);
    }
  }, [deviceType, deviceId]);

  const handleClose = useCallback(() => {
    setOpenState(OpenState.Closed);
  }, []);

  const Delete = useCallback(() => {
    onDelete({ deviceId })
      .then(() => {
        queryClient.invalidateQueries(RequestQuery.DeviceByType);
        enqueueSnackbar('Successfully', { variant: 'success' });
        handleClose();
      })
      .catch(() => enqueueSnackbar('Something went wrong', { variant: 'error' }));
  }, [deviceId, enqueueSnackbar, handleClose, onDelete, queryClient]);

  return (
    <>
      <AppButton disabled={deviceId ? false : true} onClick={handleClickOpen}>
        {buttonTitle}
      </AppButton>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={openState != OpenState.Closed}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
            {openState == OpenState.OpenedNotAllowed ? (
              <Typography color="red">
                {' Gateway cannot be deleted. Please remove attached devices '}
              </Typography>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={openState != OpenState.OpenedAllowed} onClick={Delete}>
            {openState == OpenState.OpenedWaiting ? <CircularProgress size={20} /> : 'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
