import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { RequestQuery } from '../../services/react-query-request-names';
import { ErrorResponse } from '../../services/types';
import { deleteVersion } from './api';

export const useDeleteVersionHandler = (versionId: string | undefined) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useCallback(async () => {
    try {
      if (versionId) {
        await deleteVersion(versionId);
      }
      enqueueSnackbar('Version successfuly removed', { variant: 'success' });
      await queryClient.invalidateQueries(RequestQuery.FwVersions);
    } catch (err: any) {
      if (err?.response) {
        const error = err?.response?.data as ErrorResponse;
        if (error.errors && error.errors.length > 0) {
          enqueueSnackbar(error?.errors[0]?.description, { variant: 'error' });
        } else {
          enqueueSnackbar('server unknown error', { variant: 'error' });
        }
      }
    }
  }, [versionId, enqueueSnackbar, queryClient]);
};
