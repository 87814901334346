import { FC, useCallback, useMemo } from 'react';
import { Box } from '@mui/system';
import * as yup from 'yup';

import { numberOrEmptyString } from '../../../schemas/schemas';
import { setTagConfigs } from '../../../services/api';
import { ConfigParam, DeviceData, TagConfigsOutput, TagsMetadata } from '../../../services/types';
import { AppButton } from '../../buttons/buttons';
import { TextFieldController } from '../../form-controllers/form-controllers';
import { Modal } from '../../modal/modal';
import { transformTagInitialConfigs } from './helpers';
import { useConfigsFormSubmitter } from './hooks';
import { NetworkIdSchema, TelemetryIntervalSchema } from './schemas';
import { TagConfigsFormInput } from './types';

export interface TagConfigureModalProps {
  open: boolean;
  deviceId: string;
  handleClose: () => void;
  currentConfigs?: TagConfigsOutput;
  deviceData: DeviceData;
}

const configSchema: yup.SchemaOf<TagConfigsFormInput> = yup.object({
  trans_win: numberOrEmptyString(yup.number().min(10)), // eslint-disable-line @typescript-eslint/naming-convention
  smplsNum: numberOrEmptyString(yup.number().min(1).max(20)),
  space: numberOrEmptyString(yup.number().min(10).max(120000)),
  telemetry_int: TelemetryIntervalSchema, // eslint-disable-line @typescript-eslint/naming-convention
  exicitation: numberOrEmptyString(yup.number().min(10).max(60000)),
  fac1: numberOrEmptyString(yup.number()),
  fac2: numberOrEmptyString(yup.number()),
  fac3: numberOrEmptyString(yup.number()),
  bias1: numberOrEmptyString(yup.number()),
  bias2: numberOrEmptyString(yup.number()),
  bias3: numberOrEmptyString(yup.number()),
  nw_id: NetworkIdSchema, // eslint-disable-line @typescript-eslint/naming-convention
});

const getFactorOrBiasValues = (
  configs: ConfigParam<[number, number, number]> | undefined,
  newValues: [number | undefined, number | undefined, number | undefined],
) => {
  if (newValues[0] != undefined || newValues[1] != undefined || newValues[2] != undefined) {
    let res: [number, number, number] = [0, 0, 0];
    if (configs?.reported) {
      res = configs.reported;
    } else if (configs?.pending) {
      res = configs.pending;
    }
    for (let i = 0; i < 3; ++i) {
      if (newValues[i] != undefined) {
        res[i] = newValues[i] as number;
      }
    }
    return res;
  }
  return undefined;
};

export const ConfigureTagModal: FC<TagConfigureModalProps> = ({
  open,
  deviceId,
  handleClose,
  currentConfigs,
  deviceData,
}) => {
  const initialConfigs = useMemo(
    () => transformTagInitialConfigs(currentConfigs),
    [currentConfigs],
  );

  const submitter = useCallback(
    (formInput: TagConfigsFormInput) => {
      const bias = getFactorOrBiasValues(currentConfigs?.bias, [
        formInput.bias1,
        formInput.bias2,
        formInput.bias3,
      ]);
      const fac = getFactorOrBiasValues(currentConfigs?.fac, [
        formInput.fac1,
        formInput.fac2,
        formInput.fac3,
      ]);

      return setTagConfigs(deviceId, {
        trans_win: formInput.trans_win, // eslint-disable-line @typescript-eslint/naming-convention
        smplsNum: formInput.smplsNum,
        space: formInput.space,
        telemetry_int: formInput.telemetry_int, // eslint-disable-line @typescript-eslint/naming-convention
        exicitation: formInput.exicitation,
        fac: fac,
        bias: bias,
        nw_id: formInput.nw_id, // eslint-disable-line @typescript-eslint/naming-convention
      });
    },
    [currentConfigs, deviceId],
  );

  const { control, handleSubmit, onSubmit } = useConfigsFormSubmitter(
    open,
    handleClose,
    configSchema,
    submitter,
    'Successfully Configure Tag',
    initialConfigs,
  );
  const metadata = deviceData.metadata as TagsMetadata;

  if (!currentConfigs) {
    return <></>;
  }
  return (
    <Modal handleClose={handleClose} open={open} title="Configure Tag">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <TextFieldController control={control} label="Trans Win" name="trans_win" />
          <TextFieldController control={control} label="Sample Number" name="smplsNum" />
          <TextFieldController control={control} label="Space" name="space" />
          <TextFieldController control={control} label="Telemetry Interval" name="telemetry_int" />
          <TextFieldController control={control} label="Excitation" name="exicitation" />
          <TextFieldController
            control={control}
            disabled={!metadata?.analog1}
            label="Analog Inputs Factor 1"
            name="fac1"
          />
          <TextFieldController
            control={control}
            disabled={!metadata?.analog2}
            label="Analog Inputs Factor 2"
            name="fac2"
          />
          <TextFieldController
            control={control}
            disabled={!metadata?.digital}
            label="Digital Input Factor"
            name="fac3"
          />
          <TextFieldController
            control={control}
            disabled={!metadata?.analog1}
            label="Analog Inputs Bias 1"
            name="bias1"
          />
          <TextFieldController
            control={control}
            disabled={!metadata?.analog2}
            label="Analog Inputs Bias 2"
            name="bias2"
          />
          <TextFieldController
            control={control}
            disabled={!metadata?.digital}
            label="Digital Input Bias"
            name="bias3"
          />
          <TextFieldController control={control} label="Network ID" name="nw_id" />

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
            <AppButton type="submit">Submit</AppButton>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};
