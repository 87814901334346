import {
  getGridDateOperators,
  getGridNumericColumnOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColTypeDef,
} from '@mui/x-data-grid';

export const customNumberOperators: GridColTypeDef = {
  extendType: 'number',
  filterOperators: getGridNumericColumnOperators()
    .filter(
      (operator: any) =>
        operator.value === '=' ||
        operator.value === '>' ||
        operator.value === '<' ||
        operator.value === '<=' ||
        operator.value === '>=',
    )
    .map((operator: any) => {
      return {
        ...operator,
      };
    }),
};

export const smallerThanBiggerThanOperators: GridColTypeDef = {
  extendType: 'number',
  filterOperators: getGridNumericColumnOperators()
    .filter((operator: any) => operator.value === '>' || operator.value === '<')
    .map((operator: any) => {
      return {
        ...operator,
      };
    }),
};

export const customStringOperators: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter(operator => operator.value === 'contains' || operator.value === 'equals')
    .map(operator => {
      return {
        ...operator,
      };
    }),
};
export const customStringOperatorEqual: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter(operator => operator.value === 'equals')
    .map(operator => {
      return {
        ...operator,
      };
    }),
};

export const customContainOperator: GridColTypeDef = {
  extendType: 'singleSelect',
  filterOperators: getGridSingleSelectOperators()
    .filter(operator => operator.value === 'is')
    .map(operator => {
      return {
        ...operator,
      };
    }),
};

export const customSelectBiggerThanSmallerThen: GridColTypeDef = {
  extendType: 'singleSelect',
  filterOperators: getGridNumericColumnOperators()
    .filter((operator: any) => operator.value === '>' || operator.value === '<')
    .map((operator: any) => {
      return {
        ...operator,
      };
    }),
};

export const customData: GridColTypeDef = {
  extendType: 'singleSelect',
  filterOperators: getGridDateOperators(true)
    .filter(operator => operator.value === 'after' || operator.value === 'before')
    .map(operator => {
      return {
        ...operator,
      };
    }),
};
