import { ChangeEvent, FC, useCallback } from 'react';
import { CloseOutlined } from '@mui/icons-material';
import { Divider, IconButton, Modal as MUIModal, Typography } from '@mui/material';
import { Box, BoxProps } from '@mui/system';

type GatewayModalProps = {
  open: boolean;
  handleClose: () => void;
  title: string;
  width?: string;
  children: React.ReactNode;
};

const contentContainerStyles: BoxProps['sx'] = {
  maxHeight: '80%',
  minWidth: '600px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  overflowY: 'auto',
  boxShadow: 24,
  p: 2,
  borderRadius: 1,
};

export const Modal: FC<GatewayModalProps> = ({
  open,
  handleClose,
  title,
  width = '50%',
  children,
}) => {
  const onCloseModal = useCallback(
    (_event: ChangeEvent, reason: string) => {
      if (reason === 'backdropClick') return;
      handleClose();
    },
    [handleClose],
  );

  return (
    <MUIModal
      aria-describedby="modal-modal-description"
      aria-labelledby="modal-modal-title"
      open={open}
      onClose={onCloseModal}
    >
      <Box sx={contentContainerStyles} width={width}>
        <IconButton
          aria-label="close"
          color="primary"
          component="span"
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={handleClose}
        >
          <CloseOutlined />
        </IconButton>
        <Typography component="h2" id="modal-modal-title" variant="h6">
          {title}
        </Typography>
        <Divider sx={{ my: 1 }} />
        {children}
      </Box>
    </MUIModal>
  );
};
