import { useCallback, useState } from 'react';
import { Box, Paper } from '@mui/material';

import { deleteUser } from '../../common/alert/api';
import { UserDeleteAlert } from '../../common/alert/user-delete-alert';
import { withMsalAuth } from '../../common/auth/auth';
import { AppButton } from '../../common/buttons/buttons';
import { deviceMenuWrapper, devicePageWrapper } from '../../common/styles/devices-table';
import { userColumns } from '../../common/user-table/columns';
import { UsersTable } from '../../common/user-table/user-table';
import { UserData } from '../../services/types';
import { AddUserModal } from './add-user/add-user';

export const Users = () => {
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openEditGateway, setOpenEditGateway] = useState(false);

  const [selectedUserEmail, setSelectedUserEmail] = useState<string>('');
  const [selectedRowInfo, setSelectedRowInfo] = useState<UserData>();

  const handleOpenAddGateway = () => {
    setOpenAddUserModal(true);
  };

  const onCellClick = useCallback((user: UserData) => {
    if (user && user.email) {
      setSelectedRowInfo(user);
      setSelectedUserEmail(user?.email);
    }
  }, []);

  const handleOpenEditGateway = useCallback(() => {
    setOpenEditGateway(true);
  }, []);

  const handleCloseAddUserModal = useCallback(() => {
    setOpenAddUserModal(false);
  }, []);

  const handleCloseEditGateway = useCallback(() => {
    setOpenEditGateway(false);
  }, []);

  const handleDelete = useCallback(async (userId: any) => {
    await deleteUser(userId);
    setSelectedUserEmail('');
  }, []);

  return (
    <Box sx={{ ...devicePageWrapper, gridTemplateRows: '52px 10fr' }}>
      <Paper sx={deviceMenuWrapper}>
        <AppButton onClick={handleOpenAddGateway}>Add User</AppButton>
        <AppButton disabled={selectedUserEmail ? false : true} onClick={handleOpenEditGateway}>
          Edit User
        </AppButton>
        <UserDeleteAlert
          buttonTitle="Delete"
          description="Do you want to delete this user?"
          email={selectedUserEmail}
          title={`Deleting ${selectedUserEmail} user?`}
          onDelete={handleDelete}
        />
      </Paper>

      <UsersTable columns={userColumns} onRowClick={onCellClick} />
      <AddUserModal key="addUser" handleClose={handleCloseAddUserModal} open={openAddUserModal} />
      <AddUserModal
        key="editUser"
        handleClose={handleCloseEditGateway}
        initialValues={{ email: selectedRowInfo?.email, fullName: selectedRowInfo?.fullName }}
        open={openEditGateway}
      />
    </Box>
  );
};

export const UsersPage = withMsalAuth(<Users />);
