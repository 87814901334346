import { FC } from 'react';
import { Step as MUIStep } from '@mui/material';
import Box from '@mui/material/Box';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import { Step } from '../types';
import { StepWrapper } from './step-wrapper';

type CustomStepperProps = {
  steps: Step[];
  activeStep: number;
};

export const CustomStepper: FC<CustomStepperProps> = ({ steps, activeStep }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} sx={{ my: 2 }}>
        {steps.map(({ label }) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <MUIStep key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </MUIStep>
          );
        })}
      </Stepper>
      <>
        {steps.map((step, index) => (
          <StepWrapper key={index} index={index} value={activeStep}>
            {step.component}
          </StepWrapper>
        ))}
      </>
    </Box>
  );
};
