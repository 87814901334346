import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';

import { withMsalAuth } from '../../common/auth/auth';
import { SidebarItem } from './sidebar-items';
import { SignOutButton } from './sign-out-button';
import { SidebarItemProps } from './types';

const IotManagementLinks: SidebarItemProps[] = [
  { title: 'Gateways', path: '/devices/gateways', icon: 'device' },
  { title: 'Tags', path: '/devices/tags', icon: 'device' },
  { title: 'Repeaters', path: '/devices/repeaters', icon: 'device' },
];

const DrawerStyles = {
  width: 230,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 230,
    backgroundColor: '#f7f7f7',
    boxSizing: 'border-box',
  },
};

export const SidebarComponent = () => {
  return (
    <>
      <CssBaseline />
      <Drawer anchor="left" sx={DrawerStyles} variant="permanent">
        <Box sx={{ mt: 1 }}>
          <SidebarItem icon="dashboard" parent path="/" title="IoP Dashboard" />
          <List sx={{ paddingLeft: 3 }}>
            {IotManagementLinks.map(props => (
              <SidebarItem key={props.path} {...props} />
            ))}
          </List>
        </Box>
        <Divider sx={{ my: 2 }} />
        <SidebarItem icon="software" parent path="/versions" title="SW Management" />
        <Divider sx={{ my: 2 }} />
        <SidebarItem icon="users" parent path="/users" title="Users Management" />
        <Divider sx={{ my: 2 }} />
        <SidebarItem icon="settings" parent path="/settings" title="Settings" />
        <Box
          sx={{
            position: 'absolute',
            bottom: 8,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SignOutButton />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '50px',
            }}
          >
            <img alt="logo" src="/iop-logo.svg" />
          </Box>
          <Box>{process.env.REACT_APP_VERSION}</Box>
        </Box>
      </Drawer>
    </>
  );
};

export const Sidebar = withMsalAuth(<SidebarComponent />);
