import {
  getTagAnalogInputDefaults,
  getTagAnalogInputLocalDefaults,
} from '../../../../common/default-configurations/default-configurations';
import { SensorSelectTypeOptions } from '../../../../common/store/context-components/sensor-context/sensor-context';
import { getTagConfigs } from '../../../../services/api';
import {
  ConfigParam,
  TagAddBody,
  TagConfigsInput,
  TagConfigsOutput,
  TagsMetadata,
} from '../../../../services/types';
import { updateTag } from '../api';

const getFromFactorOrBiasConfigs = (
  index: 0 | 1 | 2,
  facOrBias: ConfigParam<[number, number, number]> | undefined,
) => {
  if (facOrBias?.pending) {
    return facOrBias.pending[index];
  } else if (facOrBias?.reported) {
    return facOrBias.reported[index];
  } else {
    return undefined;
  }
};

export const updateTagAndConfigs = async (
  sensorOptions: SensorSelectTypeOptions,
  id: string,
  metadata: TagsMetadata,
  previousMetadata: TagsMetadata | undefined,
) => {
  const configs = await getTagConfigs(id);
  const newPartialConfigs: TagConfigsInput = getTagAnalogInputDefaults(
    sensorOptions,
    {
      type: metadata?.analog1,
      previousType: previousMetadata?.analog1,
      currentBias: getFromFactorOrBiasConfigs(0, configs.bias),
      currentFactor: getFromFactorOrBiasConfigs(0, configs.fac),
    },
    {
      type: metadata?.analog2,
      previousType: previousMetadata?.analog2,
      currentBias: getFromFactorOrBiasConfigs(1, configs.bias),
      currentFactor: getFromFactorOrBiasConfigs(1, configs.fac),
    },
    {
      type: metadata?.digital,
      previousType: previousMetadata?.digital,
      currentBias: getFromFactorOrBiasConfigs(2, configs.bias),
      currentFactor: getFromFactorOrBiasConfigs(2, configs.fac),
    },
  );
  const tagUpdateBody: TagAddBody = {
    metadata: metadata,
    configurations: newPartialConfigs,
  };
  await updateTag(tagUpdateBody, id);
};

export const getLocalTagConfig = (
  configs: TagConfigsOutput,
  sensorOptions: SensorSelectTypeOptions,
  id: string,
  metadata: TagsMetadata,
  previousMetadata: TagsMetadata | undefined,
) => {
  const newPartialConfigs: TagConfigsInput = getTagAnalogInputLocalDefaults(
    sensorOptions,
    {
      type: metadata?.analog1,
      previousType: previousMetadata?.analog1,
      currentBias: getFromFactorOrBiasConfigs(0, configs.bias),
      currentFactor: getFromFactorOrBiasConfigs(0, configs.fac),
    },
    {
      type: metadata?.analog2,
      previousType: previousMetadata?.analog2,
      currentBias: getFromFactorOrBiasConfigs(1, configs.bias),
      currentFactor: getFromFactorOrBiasConfigs(1, configs.fac),
    },
    {
      type: metadata?.digital,
      previousType: previousMetadata?.digital,
      currentBias: getFromFactorOrBiasConfigs(2, configs.bias),
      currentFactor: getFromFactorOrBiasConfigs(2, configs.fac),
    },
  );

  return newPartialConfigs;
};
