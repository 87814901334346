import { CSSProperties, FC, useCallback, useState } from 'react';
import { Box } from '@mui/material';

import { AppButton } from '../../../common/buttons/buttons';

export type MapTableDisplayMode = 'table' | 'map';

interface MapTableToggleProps {
  initialMode?: MapTableDisplayMode;
  onChange(mode: MapTableDisplayMode): void;
}

const tableButtonDisplayStyles: CSSProperties = { marginRight: 5 };

export const MapTableToggle: FC<MapTableToggleProps> = ({ initialMode = 'table', onChange }) => {
  const [displayMode, setDisplayMode] = useState<MapTableDisplayMode>(initialMode);

  const handleDisplayModeChange = useCallback(
    (mode: MapTableDisplayMode) => () => {
      setDisplayMode(mode);
      onChange(mode);
    },
    [onChange],
  );

  return (
    <Box>
      <AppButton
        style={tableButtonDisplayStyles}
        variant={displayMode === 'table' ? 'contained' : 'outlined'}
        onClick={handleDisplayModeChange('table')}
      >
        Table
      </AppButton>
      <AppButton
        variant={displayMode === 'map' ? 'contained' : 'outlined'}
        onClick={handleDisplayModeChange('map')}
      >
        Map
      </AppButton>
    </Box>
  );
};
