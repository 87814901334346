import { useCallback, useState } from 'react';
import { Box, Paper } from '@mui/material';

import { removeDevice } from '../../../common/alert/api';
import { DeviceAlert } from '../../../common/alert/device-alert';
import { withMsalAuth } from '../../../common/auth/auth';
import { AppButton } from '../../../common/buttons/buttons';
import { DeviceDetails } from '../../../common/device-details/device-details';
import { getPanels, Panel } from '../../../common/device-details/helpers';
import { gatewayColumns } from '../../../common/device-table/columns';
import { DeviceTable } from '../../../common/device-table/device-table';
import { ModalWithSteps } from '../../../common/modal/modal-with-steps';
import { DeviceIdContext } from '../../../common/store/device-id-context';
import { deviceMenuWrapper, devicePageWrapper } from '../../../common/styles/devices-table';
import { DeviceData, DeviceType } from '../../../services/types';
import { MapTableDisplayMode, MapTableToggle } from '../components/map-table-toggle';
import { DevicesMap } from '../devices-map/devices-map';
import { useAlertParam } from '../hooks';
import { UpdateFW } from './update-fw/update-fw';
import { createGatewaySteps } from './helper';

export const Gateways = () => {
  const [panels, setPanels] = useState<Panel[]>();
  const [displayMode, setDisplayMode] = useState<MapTableDisplayMode>('table');
  const [openAddGateway, setOpenAddGateway] = useState(false);
  const [openEditGateway, setOpenEditGateway] = useState(false);
  const [openUpdateFW, setOpenUpdateFW] = useState(false);
  const [deviceId, setDeviceId] = useState<string>();
  const [rowSelectedId, setRowSelectedId] = useState('');
  const alert = useAlertParam();

  const onCellClick = useCallback((device?: DeviceData) => {
    if (device) {
      setPanels(getPanels({ data: device, deviceType: DeviceType.gateway }));
      setRowSelectedId(device.deviceId);
    } else {
      setPanels(undefined);
      setRowSelectedId('');
    }
  }, []);

  const value = {
    deviceId: deviceId,
    setDeviceId: (deviceId?: string) => setDeviceId(deviceId),
  };

  const handleMapTableModeChange = useCallback((mode: MapTableDisplayMode) => {
    setDisplayMode(mode);
  }, []);

  const handleOpenAddGateway = useCallback(() => {
    setOpenAddGateway(true);
  }, []);

  const handleOpenEditGateway = useCallback(() => {
    setOpenEditGateway(true);
    setDeviceId(rowSelectedId);
  }, [rowSelectedId]);

  const handleCloseAddGateway = useCallback(() => {
    setOpenAddGateway(false);
    setDeviceId(undefined);
  }, []);

  const handleCloseEditGateway = useCallback(() => {
    setOpenEditGateway(false);
    setDeviceId(undefined);
  }, []);

  const handleOpenUpdateFW = useCallback(() => {
    setOpenUpdateFW(true);
  }, []);

  const handleCloseUpdateFW = useCallback(() => {
    setOpenUpdateFW(false);
  }, []);

  const handleRemoveDevice = useCallback(async (deviceId: any) => {
    await removeDevice(deviceId);
    setRowSelectedId('');
  }, []);

  return (
    <Box sx={{ ...devicePageWrapper, gridTemplateRows: panels ? '52px 10fr 10fr' : '52px 10fr' }}>
      <Paper sx={deviceMenuWrapper}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <AppButton onClick={handleOpenAddGateway}>Add Gateway</AppButton>
            <AppButton disabled={rowSelectedId ? false : true} onClick={handleOpenEditGateway}>
              Edit GW
            </AppButton>
            <DeviceAlert
              buttonTitle="Delete"
              description="Do you want to delete this device?"
              deviceId={rowSelectedId}
              deviceType={DeviceType.gateway}
              title={`Deleting device with id ${rowSelectedId}`}
              onDelete={handleRemoveDevice}
            />
            <AppButton disabled={rowSelectedId ? false : true} onClick={handleOpenUpdateFW}>
              Update FW
            </AppButton>
          </Box>
          <MapTableToggle onChange={handleMapTableModeChange} />
        </Box>
      </Paper>
      {displayMode === 'table' ? (
        <DeviceTable
          alertFilter={alert}
          columns={gatewayColumns}
          deviceType={[DeviceType.gateway]}
          onRowClick={onCellClick}
        />
      ) : (
        <DevicesMap />
      )}
      {openUpdateFW && (
        <UpdateFW deviceId={rowSelectedId} handleClose={handleCloseUpdateFW} open={openUpdateFW} />
      )}
      {panels && <DeviceDetails panels={panels} />}
      <DeviceIdContext.Provider value={value}>
        <ModalWithSteps
          key="addGateway"
          handleClose={handleCloseAddGateway}
          open={openAddGateway}
          stepCreator={createGatewaySteps}
          title="Add gateway"
        />
        <ModalWithSteps
          key="editGateway"
          handleClose={handleCloseEditGateway}
          open={openEditGateway}
          stepCreator={createGatewaySteps}
          title="Edit gateway"
        />
      </DeviceIdContext.Provider>
    </Box>
  );
};

export const GatewaysPage = withMsalAuth(<Gateways />);
