export enum RequestQuery {
  DeviceByType = '/devices/query',
  User = '/users',
  GwAlerts = 'GwAlerts',
  GwOffline = 'GwOffline',
  TagAlerts = 'TagAlerts',
  RepeaterAlerts = 'RepeaterAlerts',
  FwVersions = 'FwVersions',
  DeviceConfigs = 'DeviceConfigs',
  FwVersionsCurrentVersion = 'FwVersionsCurrentVersion',
}
