import { FC } from 'react';
import { Box } from '@mui/system';

export const ColumnView: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: '1fr 1fr 1fr 1fr',
        gridAutoFlow: 'column',
        padding: 3,
      }}
    >
      {children}
    </Box>
  );
};
