import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { AppButton } from '../../../../common/buttons/buttons';
import { getDevices } from '../../../../common/device-table/api';
import { Modal } from '../../../../common/modal/modal';
import { getLatestVersions } from '../../../../services/api';
import { RequestQuery } from '../../../../services/react-query-request-names';
import { Devices, DeviceType, ErrorResponse, TypeVersions } from '../../../../services/types';
import { DeviceLatestVersion } from '../../../versions-management/versions-management';
import { UpdateFWAlert } from './alert';
import { GetGatewayLastFWVersionsReturn, updateGatewayDevicesFW } from './api';
import { getGatewaysEarliestVersions } from './helpers';

const schema = yup.object({
  gateways: yup.boolean(),
  repeaters: yup.boolean(),
  tags: yup.boolean(),
});

export interface UpdateFWProps {
  open: boolean;
  handleClose: () => void;
  deviceId?: string;
}
export type FWProps = yup.InferType<typeof schema>;

enum GWVersionUpdate {
  Available,
  NotRequired,
  NotCompatible,
}

const getMajor = (version: string) => {
  return parseInt(version.split('.')[0]);
};

const hasSameMajor = (versionA: string, versionB: string) => {
  return getMajor(versionA) == getMajor(versionB);
};

export const UpdateFW: FC<UpdateFWProps> = ({ handleClose, open, deviceId }) => {
  const [gatewayAvailable, setGatewayAvailable] = useState<GWVersionUpdate>();
  const [tagAvailable, setTagAvailable] = useState<boolean>();
  const [repeaterAvailable, setRepeaterAvailable] = useState<boolean>();
  const [allDevices, setAllDevices] = useState<Devices>();
  const queryClient = useQueryClient();

  const { isLoading, data: latestVersions } = useQuery<TypeVersions>(
    [RequestQuery.FwVersions],
    async () => {
      return await getLatestVersions();
    },
  );
  const { data: currentVersions } = useQuery<GetGatewayLastFWVersionsReturn>(
    [RequestQuery.FwVersionsCurrentVersion, deviceId],
    async () => {
      const allDevices = await getDevices({
        gatewayId: deviceId,
      });
      setAllDevices(allDevices);
      return getGatewaysEarliestVersions(allDevices);
    },
  );

  useEffect(() => {
    if (currentVersions && latestVersions) {
      const gwLatest = latestVersions?.get(DeviceType.gateway);
      if (gwLatest && currentVersions.gatewayLastVersion !== gwLatest.versionNumber) {
        if (
          (!currentVersions.tagsLastVersion ||
            hasSameMajor(gwLatest.versionNumber, currentVersions.tagsLastVersion)) &&
          (!currentVersions.repeatersLastVersion ||
            hasSameMajor(gwLatest.versionNumber, currentVersions.repeatersLastVersion))
        ) {
          setGatewayAvailable(GWVersionUpdate.Available);
        } else {
          setGatewayAvailable(GWVersionUpdate.NotCompatible);
        }
      } else {
        setGatewayAvailable(GWVersionUpdate.NotRequired);
      }
      setTagAvailable(
        currentVersions.tagsLastVersion !== latestVersions?.get(DeviceType.tag)?.versionNumber,
      );
      setRepeaterAvailable(
        currentVersions.repeatersLastVersion !==
          latestVersions?.get(DeviceType.repeater)?.versionNumber,
      );
    }
  }, [currentVersions, latestVersions]);

  const { control, handleSubmit } = useForm<FWProps>({
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    (values: FWProps) => {
      updateGatewayDevicesFW({
        id: deviceId,
        gatewaysVersion: values.gateways
          ? latestVersions?.get(DeviceType.gateway)?.versionNumber
          : undefined,
        repeatersVersion: values.repeaters
          ? latestVersions?.get(DeviceType.repeater)?.versionNumber
          : undefined,
        tagsVersion: values.tags ? latestVersions?.get(DeviceType.tag)?.versionNumber : undefined,
      })
        .then(() => {
          enqueueSnackbar('Successfully updated', { variant: 'success' });
          queryClient.invalidateQueries(RequestQuery.DeviceByType);
          handleClose();
        })
        .catch(err => {
          if (err.response) {
            const error = err.response.data as ErrorResponse;
            enqueueSnackbar(error?.errors ? error?.errors[0]?.description : 'Error 500', {
              variant: 'error',
            });
          }
        });
    },
    [deviceId, enqueueSnackbar, handleClose, latestVersions, queryClient],
  );

  return (
    <Modal handleClose={handleClose} open={open} title="Update FW">
      <UpdateFWAlert devices={allDevices} onClose={handleClose} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          {isLoading ? (
            <CircularProgress sx={{ marginBottom: 5 }} />
          ) : (
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" mt={7}>
              <Box alignItems="center" display="flex" flexDirection="column">
                <Typography marginBottom={5} variant="h3">
                  Gateways
                </Typography>
                <DeviceLatestVersion versionNumber={currentVersions?.gatewayLastVersion} />
                <Controller
                  control={control}
                  name="gateways"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          disabled={gatewayAvailable != GWVersionUpdate.Available}
                          onChange={e => field.onChange(e.target.checked)}
                        />
                      }
                      label={
                        gatewayAvailable == GWVersionUpdate.Available
                          ? `Update to ${
                              latestVersions?.get(DeviceType.gateway)?.versionNumber
                            } version`
                          : gatewayAvailable == GWVersionUpdate.NotCompatible
                          ? 'New version not compatible with Tags/Repeaters'
                          : 'Up to date'
                      }
                    />
                  )}
                />
              </Box>
              <Box alignItems="center" display="flex" flexDirection="column">
                <Typography marginBottom={5} variant="h3">
                  Tags
                </Typography>
                <DeviceLatestVersion versionNumber={currentVersions?.tagsLastVersion} />
                <Controller
                  control={control}
                  name="tags"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          disabled={!tagAvailable}
                          onChange={e => field.onChange(e.target.checked)}
                        />
                      }
                      label={
                        tagAvailable
                          ? `Update to ${
                              latestVersions?.get(DeviceType.tag)?.versionNumber
                            } version`
                          : 'Up to date'
                      }
                    />
                  )}
                />
              </Box>
              <Box alignItems="center" display="flex" flexDirection="column">
                <Typography marginBottom={5} variant="h3">
                  Repeaters
                </Typography>
                <DeviceLatestVersion versionNumber={currentVersions?.repeatersLastVersion} />
                <Controller
                  control={control}
                  name="repeaters"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          disabled={!repeaterAvailable}
                          onChange={e => field.onChange(e.target.checked)}
                        />
                      }
                      label={
                        repeaterAvailable
                          ? `Update to ${
                              latestVersions?.get(DeviceType.repeater)?.versionNumber
                            } version`
                          : 'Up to date'
                      }
                    />
                  )}
                />
              </Box>
            </Box>
          )}
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
            <AppButton type="submit">Submit</AppButton>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};
