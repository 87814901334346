import { AxiosResponse } from 'axios';

import { apiClient } from '../../../services/client/axios-client';
import { Alert, DeviceAlertType, Devices, DeviceType, Filters } from '../../../services/types';

export const getAlerts = async (deviceType: DeviceType, alert?: DeviceAlertType) => {
  return (
    await apiClient.post<Alert, AxiosResponse<Alert>, Filters>(`/devices/activealerts/query`, {
      filters: {
        deviceType: {
          operation: 'equal',
          value: deviceType,
        },
        alerts: alert ? [alert] : undefined,
      },
    })
  ).data;
};

export const getGwOfflineDevices = async (deviceType?: DeviceType) => {
  return (
    await apiClient.post<Devices, AxiosResponse<Devices>, Filters>(`/devices/query`, {
      filters: {
        connectionState: 'offline',
        deviceType: deviceType
          ? {
              operation: 'equal',
              value: deviceType,
            }
          : undefined,
      },
    })
  ).data;
};

export const getOutOfNetworkDevices = async (deviceType: DeviceType) => {
  return (
    await apiClient.post<Alert, AxiosResponse<Alert>, Filters>(`/devices/activealerts/query`, {
      filters: {
        deviceType: {
          operation: 'equal',
          value: deviceType,
        },
        alerts: ['tag out of network'],
      },
    })
  ).data;
};
