import { FC } from 'react';
import { OnResultFunction, QrReader } from 'react-qr-reader';
import { Box } from '@mui/material';

import { AppButton } from '../buttons/buttons';

type QRCodeFieldProps = {
  setQrCode: (data: any) => void;
  isActive: boolean;
};

export const QRCodeField: FC<QRCodeFieldProps> = ({ setQrCode, isActive }) => {
  const onResult: OnResultFunction = result => {
    if (!!result) {
      setQrCode(result.getText());
    }
  };

  return (
    <Box>
      {isActive ? (
        <QrReader
          constraints={{ facingMode: 'environment' }}
          videoStyle={{ width: undefined, height: '100%' }}
          onResult={onResult}
        />
      ) : (
        <AppButton onClick={() => setQrCode('')}>Rescan</AppButton>
      )}
    </Box>
  );
};
