import { FC, useContext, useState } from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { DeviceData, DeviceType } from '../../../services/types';
import { AppButton } from '../../buttons/buttons';
import { LabelField } from '../../label-field/label-field';
import { SensorTypesSelectContext } from '../../store/context-components/sensor-context/sensor-context';
import theme from '../../theme';
import { CustomTooltip } from '../../tooltip/tooltip';
import { ColumnView } from '../common/column-view';
import { ConfigureModal } from '../configure-modals/configure-modal';
import { useConfigData } from './hooks';

interface ConfigsTabProps {
  deviceId: string;
  deviceType: DeviceType;
  deviceData: DeviceData;
}

export const ConfigsTab: FC<ConfigsTabProps> = ({ deviceId, deviceType, deviceData }) => {
  const [openConfigureModal, setOpenConfigureModal] = useState(false);
  const sensorTypeOptions = useContext(SensorTypesSelectContext);
  // Data used to display config from local csv
  const data = useConfigData(deviceId, deviceType, sensorTypeOptions.iopSensorSelectOptions);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ width: '100%', padding: 1, pl: 3, pb: 0 }}>
          <AppButton onClick={() => setOpenConfigureModal(true)}>Configure</AppButton>
        </Box>
        <Box>
          <ColumnView>
            {data?.uiConfigs.map(field => (
              <LabelField key={field.name} label={field.name} labelTooltip={field.tooltip}>
                <Typography>{field.current}</Typography>
                {field.pending && field.pending != field.current && (
                  <>
                    <AutorenewIcon
                      sx={{
                        ml: 1.5,
                        fill: theme.palette.primary.main,
                        fontSize: 18,
                        mt: 0.4,
                        mr: 0.3,
                      }}
                    />
                    <Typography sx={{ color: theme.palette.primary.main }}>
                      {field.pending}
                    </Typography>
                  </>
                )}
              </LabelField>
            ))}
          </ColumnView>
        </Box>
      </Box>
      <ConfigureModal
        currentConfigs={data?.rawConfigs}
        deviceData={deviceData}
        deviceId={deviceId}
        deviceType={deviceType}
        handleClose={() => {
          setOpenConfigureModal(false);
        }}
        open={openConfigureModal}
      />
    </>
  );
};
