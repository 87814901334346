import { DeviceData, DeviceType } from '../../services/types';
import { repeatersColumnsShort, tagsColumnsShort } from '../device-table/columns';
import { createAssociateRepeaterSteps } from './associate-repeater/helpers';
import { createAssociateTagsSteps } from './associate-tag/helpers';
import { ConfigsTab } from './configs-tab/configs-tab';
import { MainTab } from './main-tab/main-tab';
import { DeviceDetailsTable } from './device-details-table';

export type TabsProps = {
  data: DeviceData;
  deviceType: DeviceType;
};
export type Panel = {
  name: string;
  component: JSX.Element;
};

export const getPanels = ({ data, deviceType }: TabsProps): Panel[] => [
  { name: 'Main', component: <MainTab key="main" data={data} deviceType={deviceType} /> },
  {
    name: 'Tags',
    component: (
      <DeviceDetailsTable
        key="tags"
        columns={tagsColumnsShort}
        data={data}
        deviceType={DeviceType.tag}
        stepsCreator={createAssociateTagsSteps}
      />
    ),
  },
  {
    name: 'Repeaters',
    component: (
      <DeviceDetailsTable
        key="repeaters"
        columns={repeatersColumnsShort}
        data={data}
        deviceType={DeviceType.repeater}
        stepsCreator={createAssociateRepeaterSteps}
      />
    ),
  },
  {
    name: 'Config',
    component: (
      <ConfigsTab key="config" deviceData={data} deviceId={data.deviceId} deviceType={deviceType} />
    ),
  },
];
export const getPanelsWithoutTags = ({ data, deviceType }: TabsProps): Panel[] => [
  { name: 'Main', component: <MainTab key="main" data={data} deviceType={deviceType} /> },
  {
    name: 'Config',
    component: (
      <ConfigsTab key="config" deviceData={data} deviceId={data.deviceId} deviceType={deviceType} />
    ),
  },
];
