import { AxiosResponse } from 'axios';

import { apiClient } from '../../services/client/axios-client';
import { User, UserData } from '../../services/types';

export const addUser = (props: UserData) => {
  return apiClient
    .post<UserData, AxiosResponse<User, User>, UserData>(`/users`, {
      ...props,
      role: 'superadmin',
    })
    .then(response => response.data);
};

export const updateUser = (props: UserData) => {
  return apiClient
    .put<UserData, AxiosResponse<User, User>, UserData>(`/users/${props.email}`, {
      fullName: props.fullName,
    })
    .then(response => response.data);
};
