import { FC, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { RequestQuery } from '../../services/react-query-request-names';
import { AlertButton } from './alert-button';
import { DeleteUserProps } from './api';

type UserDeleteAlertProps = {
  buttonTitle: string;
  email: string;
  description: string;
  title: string;
  onDelete: (data: DeleteUserProps) => Promise<any>;
};

export const UserDeleteAlert: FC<UserDeleteAlertProps> = ({
  buttonTitle,
  email,
  description,
  title,
  onDelete,
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const Delete = useCallback(async () => {
    try {
      await onDelete({ email });
      await queryClient.invalidateQueries(RequestQuery.User);
      enqueueSnackbar('Successfully', { variant: 'success' });
    } catch {
      return enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [onDelete, email, queryClient, enqueueSnackbar]);

  return (
    <AlertButton description={description} isDisabled={!email} title={title} onConfirm={Delete}>
      {buttonTitle}
    </AlertButton>
  );
};
