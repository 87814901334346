import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MemoryIcon from '@mui/icons-material/Memory';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import { ListItem, ListItemText } from '@mui/material';

import { IconType, SidebarItemProps } from './types';

const getIcon = (icon: IconType) => {
  switch (icon) {
    case 'device':
      return <MemoryIcon />;
    case 'dashboard':
      return <DashboardIcon />;
    case 'users':
      return <PeopleAltIcon />;
    case 'software':
      return <FileCopyIcon />;
    case 'settings':
      return <SettingsIcon />;
  }
};

export const SidebarItem: FC<SidebarItemProps> = ({ title, path, icon }) => {
  const location = useLocation();
  return (
    <Link style={{ textDecoration: 'none', color: 'black' }} to={path}>
      <ListItem button selected={location.pathname === path}>
        {getIcon(icon)}
        <ListItemText primary={title} sx={{ marginLeft: 1 }} />
      </ListItem>
    </Link>
  );
};
