import { CreateStepsProps, Step } from '../../../common/types';
import { Device, TagsMetadata } from '../../../services/types';
import { AddTagFirstStep, TagProps } from './add-edit-tag/first-step';
import { AddTagSecondStep } from './add-edit-tag/second-step';
import { AddTagThirdStep, SecondStepProps } from './add-edit-tag/third-step';

export const createTagsSteps = ({
  handleBack,
  handleNext,
  handleCloseAndReset,
  backButtonVisible,
  nextButtonVisible,
}: CreateStepsProps): Step[] => [
  {
    label: 'Tag',
    component: (
      <AddTagFirstStep
        backButtonVisible={backButtonVisible}
        closeModal={handleCloseAndReset}
        handleBack={handleBack}
        handleNext={handleNext}
        nextButtonVisible={nextButtonVisible}
      />
    ),
  },
  {
    label: 'Attach gateway',
    component: (
      <AddTagSecondStep
        backButtonVisible={backButtonVisible}
        closeModal={handleCloseAndReset}
        handleBack={handleBack}
        handleNext={handleNext}
        nextButtonVisible={nextButtonVisible}
      />
    ),
  },
  {
    label: 'Tag info',
    component: <AddTagThirdStep closeModal={handleCloseAndReset} handleBack={handleBack} />,
  },
];

export const convertRawDataToFirstStepFormFields = (data: Device): TagProps => {
  return {
    id: data.data.deviceId,
    metadata: {
      analog1: (data.data?.metadata as TagsMetadata)?.analog1 ?? 'none',
      analog2: (data.data?.metadata as TagsMetadata)?.analog2 ?? 'none',
      digital: (data.data?.metadata as TagsMetadata)?.digital ?? 'none',
    },
  };
};

export const convertRawDataToSecondStepFormFields = (data: Device): SecondStepProps => {
  return {
    gateway: {
      id: data.data?.gatewayId,
    },
  };
};

export const convertRawDataToThirdStepFormFields = (data: Device): SecondStepProps => {
  return {
    location: {
      long: data.data?.location?.long,
      lat: data.data?.location?.lat,
    },
    metadata: {
      plot: (data.data.metadata as TagsMetadata)?.plot,
      comment: (data.data.metadata as TagsMetadata)?.comment,
      depth: (data.data.metadata as TagsMetadata)?.depth,
    },
  };
};
