import { useMemo } from 'react';
import { LatLngTuple } from 'leaflet';

import { LeafletPolygonRefType } from '../types';

interface UsePolygonCenterProps {
  polygonRef: LeafletPolygonRefType;
}

export const usePolygonCenter = ({ polygonRef }: UsePolygonCenterProps): LatLngTuple | null => {
  const polygonCenter = useMemo(() => {
    if (!polygonRef) {
      return null;
    }

    const center = polygonRef.getCenter();
    if (center?.lat && center?.lng) {
      return [center.lat, center.lng] as LatLngTuple;
    }

    return null;
  }, [polygonRef]);

  return polygonCenter;
};
