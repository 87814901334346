import { useEffect } from 'react';
import { DeepPartial, UnpackNestedValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { ErrorResponse } from '../services/types';

export function useFormWithSubmitter<T>(
  open: boolean,
  handleClose: () => void,
  schema: yup.SchemaOf<T>,
  submitter: (input: T) => Promise<any>,
  successMessage: string,
  initialValues?: UnpackNestedValue<DeepPartial<T>>,
) {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useForm<T>({
    resolver: yupResolver(schema as yup.AnyObjectSchema), // don't know why
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  });

  const onSubmit = useSubmitSnackbar(submitter, handleClose, successMessage, isDirty);

  useEffect(() => {
    if (!open) {
      reset();
    } else if (initialValues) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      reset(initialValues);
    }
  }, [open, reset, initialValues]);

  // hi there! if you manage to transpile handleSubmit(onSubmit) and return it, please do :)
  return { register, control, handleSubmit, onSubmit };
}

export function useSubmitSnackbar<T>(
  submitter: (input: T) => Promise<any>,
  onSuccess: () => void,
  successMessage: string,
  shouldSubmit = true,
) {
  const { enqueueSnackbar } = useSnackbar();
  return (input: T) => {
    if (shouldSubmit) {
      submitter(input)
        .then(() => {
          enqueueSnackbar(successMessage, { variant: 'success' });
          onSuccess();
        })
        .catch(err => {
          if (err?.response) {
            const error = err?.response?.data as ErrorResponse;
            if (error.errors && error.errors.length > 0) {
              enqueueSnackbar(error?.errors[0]?.description, { variant: 'error' });
            } else {
              enqueueSnackbar('server unknown error', { variant: 'error' });
            }
          }
        });
    } else {
      onSuccess();
    }
  };
}
