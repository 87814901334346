import { GridColDef } from '@mui/x-data-grid';

import {
  batvoltFieldShort,
  inputSignalFieldShort,
  plotFieldShort,
  statusFieldShort,
} from './common';
import { tagsIdField } from './tags';

const tagsIdFieldShort: GridColDef = {
  ...tagsIdField,
  width: 150,
  type: 'customString',
  sortable: false,
  filterable: false,
  align: 'center',
  headerAlign: 'center',
};

export const tagsColumnsShort: GridColDef[] = [
  tagsIdFieldShort,
  plotFieldShort,
  statusFieldShort,
  batvoltFieldShort,
  inputSignalFieldShort,
];
