import { FC, useContext, useEffect, useState } from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { formatDistance, parseISO } from 'date-fns';

import { AppButton } from '../../../../common/buttons/buttons';
import { LabelField } from '../../../../common/label-field/label-field';
import { DeviceIdContext } from '../../../../common/store/device-id-context';
import {
  getDevice,
  sendGetTelemetryCommand,
  setGatewayActive,
} from '../../../../services/api/device';
import { GatewayStatus } from '../../../../services/types';
import { transformCellRSSIToSignalQuality, transformSignalQualityToIcon } from '../helper';

type AddGatewayFirstStepProps = {
  handleNext: () => void;
  handleBack: () => void;
};

export const signalWrapper = {
  width: '100%',
  flexDirection: 'column',
  height: '300px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
} as const;

interface DeviceData {
  signal: 1 | 2 | 3 | 4 | 5 | undefined;
  imei: string | undefined;
  connection?: string;
  statusUpdateTime?: string;
}

export const AddGatewaySecondStep: FC<AddGatewayFirstStepProps> = ({ handleNext, handleBack }) => {
  const [deviceData, setDeviceData] = useState<DeviceData>();

  const { deviceId } = useContext(DeviceIdContext);

  const updateDeviceState = (deviceIdInput: string) => {
    getDevice(deviceIdInput).then(res => {
      const CellGen = (res.data.status as GatewayStatus)?.CellGen;
      const CellRSSI = (res.data.status as GatewayStatus)?.CellRSSI;
      const signalQuality = transformCellRSSIToSignalQuality(CellGen, CellRSSI);
      setDeviceData({
        signal: signalQuality,
        imei: res.data?.info?.imei,
        connection: res.data?.gatewayConnectionState,
        statusUpdateTime: res.data?.statusUpdateTime,
      });
    });
  };

  useEffect(() => {
    if (deviceId) {
      const polling = setInterval(() => {
        updateDeviceState(deviceId);
      }, 2000);
      setGatewayActive(deviceId).then(() => {
        sendGetTelemetryCommand(deviceId);
      });

      return () => {
        clearTimeout(polling);
      };
    }
  }, [deviceId]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={signalWrapper}>
        <Typography variant="h5">Signal</Typography>
        {deviceData?.signal && transformSignalQualityToIcon(deviceData.signal)}
        <Box sx={{ mt: 4 }}>
          <LabelField label="IMEI">{deviceData?.imei}</LabelField>
          <LabelField label="Connection">{deviceData?.connection ?? 'Offline'}</LabelField>
          <LabelField label="Status Update Time">
            {deviceData?.statusUpdateTime
              ? formatDistance(Date.now(), parseISO(deviceData.statusUpdateTime))
              : ''}
          </LabelField>
        </Box>
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <AppButton startIcon={<NavigateBeforeIcon />} onClick={handleBack}>
          Back
        </AppButton>
        <AppButton endIcon={<NavigateNextIcon />} onClick={handleNext}>
          Next
        </AppButton>
      </Box>
    </Box>
  );
};
