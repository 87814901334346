import { useCallback } from 'react';
import { DeepPartial, UnpackNestedValue } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';

import { useFormWithSubmitter } from '../../../hooks/use-submit';
import { RequestQuery } from '../../../services/react-query-request-names';

export function useConfigsFormSubmitter<T>(
  open: boolean,
  handleClose: () => void,
  schema: yup.SchemaOf<T>,
  submitter: (input: T) => Promise<any>,
  successMessage: string,
  initialValues?: UnpackNestedValue<DeepPartial<T>>,
) {
  const queryClient = useQueryClient();
  const finalSubmitter = useCallback(
    async (input: T) => {
      await submitter(input);
      await queryClient.invalidateQueries(RequestQuery.DeviceConfigs);
    },
    [submitter, queryClient],
  );
  return useFormWithSubmitter(
    open,
    handleClose,
    schema,
    finalSubmitter,
    successMessage,
    initialValues,
  );
}
