import { FC, useMemo } from 'react';
import { Box } from '@mui/system';
import * as yup from 'yup';

import { setRepeaterConfigs } from '../../../services/api/';
import { RepeaterConfigsInput } from '../../../services/types';
import { RepeaterConfigsOutput } from '../../../services/types/config';
import { AppButton } from '../../buttons/buttons';
import { TextFieldController } from '../../form-controllers/form-controllers';
import { Modal } from '../../modal/modal';
import { transformRepeaterInitialConfigs } from './helpers';
import { useConfigsFormSubmitter } from './hooks';
import { NetworkIdSchema, TelemetryIntervalSchema } from './schemas';

export interface RepeaterConfigureModalProps {
  open: boolean;
  deviceId: string;
  handleClose: () => void;
  currentConfigs?: RepeaterConfigsOutput;
}

type PartialRepeaterConfigsInput = Omit<RepeaterConfigsInput, 'fac' | 'bias' | 'trans_win'>;

const configSchema: yup.SchemaOf<PartialRepeaterConfigsInput> = yup.object({
  telemetry_int: TelemetryIntervalSchema, // eslint-disable-line @typescript-eslint/naming-convention
  nw_id: NetworkIdSchema, // eslint-disable-line @typescript-eslint/naming-convention
});

export const ConfigureRepeaterModal: FC<RepeaterConfigureModalProps> = ({
  open,
  deviceId,
  handleClose,
  currentConfigs,
}) => {
  const initialConfigs = useMemo(
    () => transformRepeaterInitialConfigs(currentConfigs),
    [currentConfigs],
  );
  const { control, handleSubmit, onSubmit } = useConfigsFormSubmitter(
    open,
    handleClose,
    configSchema,
    (input: PartialRepeaterConfigsInput) => {
      return setRepeaterConfigs(deviceId, input);
    },
    'Successfully Configure Repeater',
    initialConfigs,
  );

  return (
    <Modal handleClose={handleClose} open={open} title="Configure Repeater">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <TextFieldController control={control} label="Telemetry Interval" name="telemetry_int" />
          <TextFieldController control={control} label="Network ID" name="nw_id" />

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
            <AppButton type="submit">Submit</AppButton>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};
