import { FC, useCallback, useEffect, useState } from 'react';

import { CustomStepper } from '../stepper/stepper';
import { CreateStepsProps, Step } from '../types';
import { Modal } from './modal';

type GatewaysAddModalProps = {
  title: string;
  handleClose: () => void;
  open: boolean;
  stepCreator: (props: CreateStepsProps) => Step[];
};

export const ModalWithSteps: FC<GatewaysAddModalProps> = ({
  open,
  handleClose,
  stepCreator,
  title,
}) => {
  const [steps, setSteps] = useState<Step[]>([]);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleCloseAndReset = useCallback(() => {
    setActiveStep(0);
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    setSteps(
      stepCreator({
        handleBack,
        handleNext,
        handleCloseAndReset,
        backButtonVisible: activeStep > 0,
        nextButtonVisible: activeStep < steps.length,
      }),
    );
  }, [activeStep, handleCloseAndReset, stepCreator, steps.length]);

  return (
    <>
      {steps && (
        <Modal handleClose={handleCloseAndReset} open={open} title={title}>
          <CustomStepper activeStep={activeStep} steps={steps} />
        </Modal>
      )}
    </>
  );
};
