import { createContext } from 'react';

export type InitialContext = {
  deviceId?: string;
  setDeviceId: (deviceId?: string) => void;
};

export const DeviceIdContext = createContext({
  deviceId: undefined,
  setDeviceId: () => {},
} as InitialContext);
