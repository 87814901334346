import { FwVersion, Pagination } from './common';
import { GwConfigsInput, RepeaterConfigsInput, TagConfigsInput } from '.';

export enum DeviceType {
  gateway = 'RFN-gate',
  tag = 'tag',
  repeater = 'repeater',
}

export type GatewayStatus = {
  ts: string;
  CellGen?: number;
  CellRSSI?: number;
  batvolt?: number;
  charging?: boolean;
  dlPer?: string;
  logQlength?: string;
};

export type RepeaterStatus = {
  ts: string;
  batvolt?: number;
  charging?: boolean;
  state?: 'install' | 'active';
  dlPer?: string;
  ulPer?: string;
  ulNode?: string;
  rfRssi?: string;
  gwRssi?: string;
};

export type TagsStatus = {
  ts: string;
  batvolt?: number;
  state?: 'install' | 'active';
  ulPer: string;
  ulNode: string;
  rfRssi: number;
  gwRssi: number;
};

export type GatewayMetadata = {
  gwType?: string;
  grower?: string;
  reseller?: string;
  name?: string;
  serviceProvider?: string;
  phoneNumber?: string;
  imsi?: string;
};

export type TagsMetadata = {
  grower?: string;
  plot?: string;
  analog1?: string;
  analog1_value?: string;
  analog2?: string;
  analog2_value?: string;
  analog3?: string;
  digital?: string;
  digital_value?: string;
  comment?: string;
  depth?: string;
};

export type RepeatersMetadata = {
  grower?: string;
  plot?: string;
  comment?: string;
};

export type Info = {
  imei?: string;
};

export type DeviceData = {
  deviceId: string;
  gatewayId?: string;
  type: DeviceType;
  creationDate: string;
  gatewayLastSeen?: string;
  gatewayConnectionState?: string;
  statusUpdateTime?: string;
  fwVersion?: {
    current?: string;
    pending?: string;
    gatewayUpdateState?: string;
  };
  alerts: string[];
  location?: {
    lat?: number;
    long?: number;
  };
  distance?: number;
  metadata: GatewayMetadata | TagsMetadata | RepeatersMetadata | null;
  status: GatewayStatus | RepeaterStatus | TagsStatus;
  info: Info;
};

export type Device = {
  status: {
    result: string;
    code: number;
  };
  errors: DeviceError[];
  data: DeviceData;
};

export type Devices = Omit<Device, 'data'> & {
  data: {
    pagination: Pagination;
    data: DeviceData[];
  };
};
export type DeviceError = {
  description: string;
  code: string;
  meta: {
    offendedFields: string[];
  };
};

export enum DeviceAlertType {
  lowBattery = 'low battery voltage',
  lowLTE = 'low LTE signal',
  outOfNetwork = 'tag out of network',
  lowRssi = 'low tag RSSI',
}

export enum DeviceSelectAlertType {
  lowBattery = 'Low battery voltage',
  lowLTE = 'Low LTE signal',
  lowRssi = 'Low RSSI signal',
  outOfNetwork = 'Out of network',
  gwOffline = 'Gateway offline',
}

export enum DeviceTableAlertQuery {
  lowBattery = 'lowBattery',
  lowLTE = 'lowLte',
  outOfNetwork = 'outOfNetwork',
  gwOffline = 'gwOffline',
  lowRssi = 'lowRssi',
}

export enum DeviceTypeRoutes {
  gateways = 'gateways',
  tags = 'tags',
  repeaters = 'repeaters',
}

export enum DeviceConnectionState {
  online = 'online',
  offline = 'offline',
}

export type DeviceAddBody<
  T = RepeatersMetadata | GatewayMetadata | TagsMetadata,
  U = RepeaterStatus | GatewayStatus | TagsStatus,
  V = RepeaterConfigsInput | GwConfigsInput | TagConfigsInput,
> = {
  groups?: string[];
  type?: DeviceType;
  gateway?: {
    id?: string;
  };
  location?: {
    lat?: number;
    long?: number;
  };
  configurations?: V;
  metadata?: T;
  status?: U;
};

export type TagAddBody = DeviceAddBody<TagsMetadata, TagsStatus, TagConfigsInput>;
export type RepeaterAddBody = DeviceAddBody<
  RepeatersMetadata,
  RepeaterStatus,
  RepeaterConfigsInput
>;
export type GatewayAddBody = DeviceAddBody<GatewayMetadata, GatewayStatus, GwConfigsInput>;

export type DeviceUpdateBody<
  T = RepeatersMetadata | GatewayMetadata | TagsMetadata,
  U = RepeaterStatus | GatewayStatus | TagsStatus,
  V = RepeaterConfigsInput | GwConfigsInput | TagConfigsInput,
> = {
  groups?: string[];
  gateway?: {
    id?: string;
  };
  location?: {
    lat?: number;
    long?: number;
  };
  configurations?: V;
  metadata?: T;
  status?: U;
};

export type TagUpdateBody = DeviceUpdateBody<TagsMetadata, TagsStatus, TagConfigsInput>;
export type RepeaterUpdateBody = DeviceUpdateBody<
  RepeatersMetadata,
  RepeaterStatus,
  RepeaterConfigsInput
>;
export type GatewayUpdateBody = DeviceUpdateBody<GatewayMetadata, GatewayStatus, GwConfigsInput>;

export type TypeVersions = Map<DeviceType, FwVersion | null>;
