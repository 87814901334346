/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */

import { DeviceError, DeviceType } from '.';

export type Filters = {
  filters: {
    status?: object[];
    metadata?: object[];
    managedGroups?: string[];
    deviceType?: {
      operation: string;
      value: DeviceType;
    };
    gateway?: {
      operation?: string;
      value?: string;
    };
    creationDateMinimum?: string;
    creationDateMaximum?: string;
    pendingVersion?: string;
    currentVersion?: string;
    deviceTypes?: DeviceType;
    connectionState?: string;
    alerts?: string[];
    hasAlerts?: boolean;
    deviceId?: object;
    gateways?: string[];
    distance?: object;
    ts?: object;
  };
};

export type Sorts = {
  orderBy: {
    field: {
      source?: string;
      name?: string;
    };
    direction?: 'descending' | 'ascending' | null;
  };
};

export type Pagination = {
  offset?: number;
  limit?: number;
  totalCount?: number;
};

export type FwVersionResponse = ServerResponse<FwVersion[]>;

export interface FwVersion {
  versionId: string;
  versionNumber: string;
  deviceType: string;
  isStable: boolean;
}

export interface ServerResponse<T> {
  status: {
    result: string;
    code: number;
  };
  errors: DeviceError[];
  data: T;
}

export type FilterRequest = Pagination & Filters & Sorts;

export type ErrorResponse = {
  status: {
    result: string;
    code: number;
  };
  errors: DeviceError[];
  data?: any;
};

export enum OrderBySource {
  Metadata = 'metadata',
  Status = 'status',
  Common = 'common',
}

export enum Analog {
  FRUIT_ECOMATIK_DF4_WITHOUTRSV50 = 'FRUIT_ECOMATIK_DF4_WITHOUTRSV50',
  WPS_HONEYWELL_ABP2 = 'WPS_HONEYWELL_ABP2',
  WPS_KELLER_DRUK_21D = 'WPS_KELLER_DRUK_21D',
  STEM_BIO_INSTRUMENTS_5T = 'STEM_BIO_INSTRUMENTS_5T',
  STEM_BIO_INSTRUMENTS_10T = 'STEM_BIO_INSTRUMENTS_10T',
  LT_BIO_INSTRUMENT_4T = 'LT_BIO_INSTRUMENT_4T',
  TRUNK_BIO_INSTRUMENTS_DE = 'TRUNK_BIO_INSTRUMENTS_DE',
  TRUNK_SUPPLANT_LR_PLAS_HOLDER = 'TRUNK_SUPPLANT_LR_PLAS_HOLDER',
  SOIL_DFM_SUP060_UART = 'SOIL_DFM_SUP060_UART',
  SOIL_TEMP_DFM_SUP060_UART = 'SOIL_TEMP_DFM_SUP060_UART',
  SOIL_VWC_METEO_TECH_TEROS10 = 'SOIL_VWC_METEO_TECH_TEROS10',
  PAR_APOGEE_SQ_212 = 'PAR_APOGEE_SQ_212',
  PYRANOMETER_APOGEE_SP_212 = 'PYRANOMETER_APOGEE_SP_212',
  FRUIT_ECOMATIK_DF6WITHOUTRSV50 = 'FRUIT_ECOMATIK_DF6WITHOUTRSV50',
  WPS_SUPPLANT_WP1_ANALOG = 'WPS_SUPPLANT_WP1_ANALOG',
  FRUIT_ECOMATIK_DF5WITHOUTRSV50 = 'FRUIT_ECOMATIK_DF5WITHOUTRSV50',
  STEM_ECOMATIK_DDS2WITHOUTRSV50 = 'STEM_ECOMATIK_DDS2WITHOUTRSV50',
  STEM_ECOMATIK_DDL2WITHOUTRSV50 = 'STEM_ECOMATIK_DDL2WITHOUTRSV50',
  STEM_ECOMATIK_DDL3WITHOUTRSV50 = 'STEM_ECOMATIK_DDL3WITHOUTRSV50',
  TRUNK_ECOMATIK_DR3WITHOUTRSV50 = 'TRUNK_ECOMATIK_DR3WITHOUTRSV50',
  STEM_SUPPLANT_LR_ALUM_HOLDER = 'STEM_SUPPLANT_LR_ALUM_HOLDER',
  FRUIT_SUPPLANT_LR_ML = 'FRUIT_SUPPLANT_LR_ML',
}
export enum Digital {
  SOIL_DFM_SUP060_UART = 'SOIL_DFM_SUP060_UART',
  WPS_HONEYWELL_ABP2 = 'WPS_HONEYWELL_ABP2',
  WPS_KELLER_DRUK_21D = 'WPS_KELLER_DRUK_21D',
}

export enum Depth {
  '20cm' = '20cm',
  '40cm' = '40cm',
  '60cm' = '60cm',
  '80cm' = '80cm',
}
