import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, BoxProps, CircularProgress } from '@mui/material';

import { defaultMapOptions } from '../../../common/configs/map-config';
import { getDevices } from '../../../common/device-table/api';
import { Map } from '../../../common/map';
import { MapRef } from '../../../common/map/types';
import { DeviceData } from '../../../services/types';
import { useDeviceMapEntities } from './hooks/use-device-map-entities';
import { getVisibleDevices } from './utils';

const loadingContainerStyles: BoxProps['sx'] = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const DevicesMap: FC = () => {
  const mapRef = useRef<MapRef>(null);
  const [devices, setDevices] = useState<DeviceData[]>([]);
  const [visibleDevices, setVisibleDevices] = useState<DeviceData[]>([]);
  const [isLoading, setLoading] = useState(false);
  const { mapEntities } = useDeviceMapEntities({ devices: visibleDevices });
  const map = mapRef.current?.map;

  const updateVisibleDevices = useCallback(() => {
    const mapBounds = map?.getBounds();

    if (mapBounds) {
      setVisibleDevices(getVisibleDevices(devices, mapBounds));
    }
  }, [devices, map]);

  useEffect(() => {
    setLoading(true);
    getDevices({})
      .then(response => {
        setLoading(false);
        setDevices(response.data?.data ?? []);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (devices.length && map) {
      updateVisibleDevices();
    }
  }, [devices.length, map, updateVisibleDevices]);

  useEffect(() => {
    map?.on('moveend', updateVisibleDevices);
    return () => {
      map?.removeEventListener('moveend', updateVisibleDevices);
    };
  }, [map, updateVisibleDevices]);

  return isLoading ? (
    <Box sx={loadingContainerStyles}>
      <CircularProgress />
    </Box>
  ) : (
    <Map {...defaultMapOptions} ref={mapRef} mapEntities={mapEntities} />
  );
};
