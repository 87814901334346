import { FC, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { AppButton } from '../../../../common/buttons/buttons';
import { DeviceIdContext } from '../../../../common/store/device-id-context';
import { getDevice } from '../../../../services/api/device';
import { ErrorResponse } from '../../../../services/types';
import { updateTag } from '../api';
import { convertRawDataToSecondStepFormFields } from '../helpers';

type AddTagSecondStepProps = {
  handleNext: () => void;
  handleBack: () => void;
  closeModal: () => void;
  backButtonVisible: boolean;
  nextButtonVisible: boolean;
};

enum SecondStepFieldName {
  'gatewayId' = 'gateway.id',
}
const secondStepFields = [
  { label: 'Gateway Id', fieldName: SecondStepFieldName.gatewayId, isRequired: true },
];

const schema = yup
  .object()
  .shape({
    gateway: yup.object().shape({
      id: yup.string().required('Enter gateway id'),
    }),
  })
  .required();

export type SecondStepProps = yup.InferType<typeof schema>;

export const AddTagSecondStep: FC<AddTagSecondStepProps> = ({ handleBack, handleNext }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { deviceId } = useContext(DeviceIdContext);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<SecondStepProps>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (deviceId) {
      getDevice(deviceId).then(res => {
        const initialValues = convertRawDataToSecondStepFormFields(res);
        reset(initialValues);
      });
    }
  }, [deviceId, reset, setValue]);

  const onSubmit = (values: SecondStepProps) => {
    if (deviceId) {
      if (isDirty) {
        updateTag(values, deviceId)
          .then(() => {
            enqueueSnackbar('Successfully associate', { variant: 'success' });
            handleNext();
          })
          .catch(err => {
            if (err.response) {
              const error = err.response.data as ErrorResponse;
              enqueueSnackbar(error?.errors ? error?.errors[0]?.description : 'Error 500', {
                variant: 'error',
              });
            }
          });
      } else {
        handleNext();
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {secondStepFields.map(({ label, fieldName, isRequired }, index) => (
            <Controller
              key={index}
              control={control}
              name={fieldName}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={error ? true : false}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: field.value !== undefined ? true : false }}
                  label={label}
                  required={isRequired}
                  variant="outlined"
                />
              )}
            />
          ))}
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
          <AppButton startIcon={<NavigateBeforeIcon />} onClick={handleBack}>
            Back
          </AppButton>
          <AppButton endIcon={<ArrowForwardIcon />} type="submit">
            Associate
          </AppButton>
        </Box>
      </form>
    </Box>
  );
};
