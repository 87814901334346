import { FC, useState } from 'react';
import { Box, Paper, Tab, Tabs } from '@mui/material';

import { Panel } from './helpers';
import { TabPanel } from './tab-panel';

type DeviceDetailsProps = {
  panels: Panel[];
};

const DeviceDetailsWrapper = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: 1,
} as const;

export const DeviceDetails: FC<DeviceDetailsProps> = ({ panels }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Paper sx={DeviceDetailsWrapper}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs aria-label="basic tabs example" value={value} onChange={handleChange}>
          {panels?.map((panel, index) => (
            <Tab key={index} label={panel.name} />
          ))}
        </Tabs>
      </Box>
      {panels?.map((panel, index) => (
        <TabPanel key={index} index={index} value={value}>
          {panel.component}
        </TabPanel>
      ))}
    </Paper>
  );
};
