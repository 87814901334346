import { FC, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowModel,
  GridSortModel,
} from '@mui/x-data-grid';

import { RequestQuery } from '../../services/react-query-request-names';
import { Pagination, Sorts, UserData, Users } from '../../services/types';
import { customNumberOperators, customStringOperators } from '../device-table/filter-types';
import { getUsers } from './api';
import { getCurrentUser, transformDataForTable, transformSorts } from './helpers';

type UsersTableProps = {
  columns: GridColDef[];
  onRowClick: (device: UserData) => void;
  gatewayId?: string;
};

const pageSize = 10;

export const UsersTable: FC<UsersTableProps> = ({ columns, onRowClick }) => {
  const [rawData, setRawData] = useState<Users>();
  const [tableData, setTableData] = useState<GridRowModel[]>([]);
  const [sorts, setSorts] = useState<Sorts | undefined>();
  const [pagination, setPagination] = useState<Pagination>({
    limit: pageSize,
    offset: 0,
  });

  const query = useCallback(async () => {
    return await getUsers({ pagination, sorts });
  }, [pagination, sorts]);

  const { isLoading, data } = useQuery<Users>([RequestQuery.User, pagination, sorts], query);

  useEffect(() => {
    if (data) {
      const transformedData = transformDataForTable(data);
      setRawData(data);
      setTableData(transformedData);
    }
  }, [data]);

  const onSortChange = useCallback((sortModel: GridSortModel) => {
    const transformedSorts = transformSorts(sortModel);
    setSorts(transformedSorts);
  }, []);

  const onPageChange = useCallback((page: number) => {
    setPagination({
      offset: page * pageSize,
      limit: pageSize,
    });
  }, []);

  const onCellClick = useCallback(
    (params: GridCellParams<any, any, any>) => {
      if (rawData) {
        const selectedDevice = getCurrentUser(rawData, params.row.email);
        onRowClick(selectedDevice);
      }
    },
    [onRowClick, rawData],
  );

  return (
    <DataGrid
      columns={columns}
      columnTypes={{ customNumber: customNumberOperators, customString: customStringOperators }}
      loading={isLoading}
      pageSize={pageSize}
      pagination
      paginationMode="server"
      rowCount={rawData?.data?.pagination?.totalCount}
      rows={tableData}
      rowsPerPageOptions={[pageSize]}
      sortingMode="server"
      sortingOrder={['asc', 'desc', null]}
      onCellClick={onCellClick}
      onPageChange={onPageChange}
      onSortModelChange={onSortChange}
    />
  );
};
