import React, { FC, useCallback, useState } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { AppButton } from '../buttons/buttons';

type DeviceAlertProps = {
  description: string;
  title: string;
  isDisabled: boolean;
  onConfirm: () => Promise<any>;
  children: React.ReactNode;
  buttonProps?: ButtonProps;
};

export const AlertButton: FC<DeviceAlertProps> = ({
  description,
  title,
  onConfirm,
  isDisabled,
  children,
  buttonProps,
}) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const Confirm = useCallback(async () => {
    await onConfirm();
    handleClose();
  }, [onConfirm, handleClose]);

  return (
    <>
      <AppButton disabled={isDisabled} onClick={handleClickOpen} {...buttonProps}>
        {children}
      </AppButton>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={Confirm}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
