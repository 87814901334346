import { GridColDef } from '@mui/x-data-grid';

const fullNameField: GridColDef = {
  field: 'fullName',
  headerName: 'Full name',
  type: 'customString',
  flex: 1,
  sortable: true,
  align: 'center',
  headerAlign: 'center',
};

const emailField: GridColDef = {
  field: 'email',
  headerName: 'Email',
  type: 'customString',
  flex: 1,
  sortable: true,
  align: 'center',
  headerAlign: 'center',
};

const roleField: GridColDef = {
  field: 'role',
  headerName: 'Role',
  type: 'customString',
  flex: 1,
  sortable: false,
  filterable: false,
  align: 'center',
  headerAlign: 'center',
};

export const userColumns: GridColDef[] = [fullNameField, emailField, roleField];
