import { useCallback, useState } from 'react';
import { Box, Paper } from '@mui/material';

import { removeDevice } from '../../../common/alert/api';
import { DeviceAlert } from '../../../common/alert/device-alert';
import { withMsalAuth } from '../../../common/auth/auth';
import { AppButton } from '../../../common/buttons/buttons';
import { DeviceDetails } from '../../../common/device-details/device-details';
import { getPanelsWithoutTags, Panel } from '../../../common/device-details/helpers';
import { tagsColumns } from '../../../common/device-table/columns';
import { DeviceTable } from '../../../common/device-table/device-table';
import { ModalWithSteps } from '../../../common/modal/modal-with-steps';
import { DeviceIdContext } from '../../../common/store/device-id-context';
import { deviceMenuWrapper, devicePageWrapper } from '../../../common/styles/devices-table';
import { DeviceData, DeviceType } from '../../../services/types';
import { MapTableDisplayMode, MapTableToggle } from '../components/map-table-toggle';
import { DevicesMap } from '../devices-map/devices-map';
import { useAlertParam } from '../hooks';
import { getLastMeasurement } from './api';
import { createTagsSteps } from './helpers';

export const Tags = () => {
  const [panels, setPanels] = useState<Panel[]>();
  const [deviceId, setDeviceId] = useState<string>();
  const [displayMode, setDisplayMode] = useState<MapTableDisplayMode>('table');
  const [openAddTag, setOpenAddTag] = useState(false);
  const [openEditTag, setOpenEditTag] = useState(false);
  const [rowSelectedId, setRowSelectedId] = useState('');
  const alert = useAlertParam();

  const value = {
    deviceId: deviceId,
    setDeviceId: (deviceId?: string) => setDeviceId(deviceId),
  };

  const onCellClick = useCallback(async (device?: DeviceData) => {
    if (device) {
      try {
        const measurement = await getLastMeasurement(device.deviceId);
        if (measurement) {
          device.metadata = {
            ...device.metadata,
            analog1_value: measurement.data.adc1 ? `${measurement.data.adc1}` : undefined,
            analog2_value: measurement.data.adc2 ? `${measurement.data.adc2}` : undefined,
            digital_value: measurement.data.uart ? `${measurement.data.uart}` : undefined,
          };
        }
      } catch (e) {
        console.error('Error getting device measurement', e);
      } finally {
        setPanels(getPanelsWithoutTags({ data: device, deviceType: DeviceType.tag }));
        setRowSelectedId(device.deviceId);
      }
    } else {
      setPanels(undefined);
      setRowSelectedId('');
    }
  }, []);

  const handleMapTableModeChange = useCallback((mode: MapTableDisplayMode) => {
    setDisplayMode(mode);
  }, []);

  const handleOpenAddTag = useCallback(() => {
    setOpenAddTag(true);
  }, []);

  const handleOpenEditTag = useCallback(() => {
    setOpenEditTag(true);
    setDeviceId(rowSelectedId);
  }, [rowSelectedId]);

  const handleCloseAddTag = useCallback(() => {
    setOpenAddTag(false);
    setDeviceId(undefined);
  }, []);

  const handleCloseEditTag = useCallback(() => {
    setOpenEditTag(false);
    setDeviceId(undefined);
  }, []);

  const handleRemoveDevice = useCallback(async (deviceId: any) => {
    await removeDevice(deviceId);
    setRowSelectedId('');
  }, []);

  return (
    <Box sx={{ ...devicePageWrapper, gridTemplateRows: panels ? '52px 10fr 10fr' : '52px 10fr' }}>
      <Paper sx={deviceMenuWrapper}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <AppButton onClick={handleOpenAddTag}>Add Tag</AppButton>
            <AppButton disabled={rowSelectedId ? false : true} onClick={handleOpenEditTag}>
              Edit Tag
            </AppButton>
            <DeviceAlert
              buttonTitle="Delete"
              description="Do you want to delete this device?"
              deviceId={rowSelectedId}
              title={`Deleting device with id ${rowSelectedId}`}
              onDelete={handleRemoveDevice}
            />
          </Box>
          <MapTableToggle onChange={handleMapTableModeChange} />
        </Box>
      </Paper>
      {displayMode === 'table' ? (
        <DeviceTable
          alertFilter={alert}
          columns={tagsColumns}
          deviceType={[DeviceType.tag]}
          onRowClick={onCellClick}
        />
      ) : (
        <DevicesMap />
      )}
      {panels && <DeviceDetails panels={panels} />}
      <DeviceIdContext.Provider value={value}>
        <ModalWithSteps
          key="addTag"
          handleClose={handleCloseAddTag}
          open={openAddTag}
          stepCreator={createTagsSteps}
          title="Add Tag"
        />
        <ModalWithSteps
          key="editTag"
          handleClose={handleCloseEditTag}
          open={openEditTag}
          stepCreator={createTagsSteps}
          title={`Edit Tag: ${value.deviceId}`}
        />
      </DeviceIdContext.Provider>
    </Box>
  );
};

export const TagsPage = withMsalAuth(<Tags />);
