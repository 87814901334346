import { Box, Paper } from '@mui/material';

import { withMsalAuth } from '../../common/auth/auth';
import { deviceMenuWrapper, devicePageWrapper } from '../../common/styles/devices-table';

export const Settings = () => {
  return (
    <Box sx={{ ...devicePageWrapper, gridTemplateRows: '52px 10fr' }}>
      <Paper sx={deviceMenuWrapper}>settings</Paper>
    </Box>
  );
};

export const SettingsPage = withMsalAuth(<Settings />);
