import { useCallback, useState } from 'react';
import { Box, Paper } from '@mui/material';

import { removeDevice } from '../../../common/alert/api';
import { DeviceAlert } from '../../../common/alert/device-alert';
import { withMsalAuth } from '../../../common/auth/auth';
import { AppButton } from '../../../common/buttons/buttons';
import { DeviceDetails } from '../../../common/device-details/device-details';
import { getPanelsWithoutTags, Panel } from '../../../common/device-details/helpers';
import { repeatersColumns } from '../../../common/device-table/columns/';
import { DeviceTable } from '../../../common/device-table/device-table';
import { ModalWithSteps } from '../../../common/modal/modal-with-steps';
import { DeviceIdContext } from '../../../common/store/device-id-context';
import { deviceMenuWrapper, devicePageWrapper } from '../../../common/styles/devices-table';
import { DeviceData, DeviceType } from '../../../services/types';
import { MapTableDisplayMode, MapTableToggle } from '../components/map-table-toggle';
import { DevicesMap } from '../devices-map/devices-map';
import { useAlertParam } from '../hooks';
import { createRepeaterSteps } from './helpers';

export const Repeaters = () => {
  const [panels, setPanels] = useState<Panel[]>();
  const [deviceId, setDeviceId] = useState<string>();
  const [displayMode, setDisplayMode] = useState<MapTableDisplayMode>('table');
  const [openAddRepeater, setOpenAddRepeater] = useState(false);
  const [openEditRepeater, setOpenEditRepeater] = useState(false);
  const [rowSelectedId, setRowSelectedId] = useState('');
  const alert = useAlertParam();

  const value = {
    deviceId: deviceId,
    setDeviceId: (deviceId?: string) => setDeviceId(deviceId),
  };
  const onCellClick = useCallback((device?: DeviceData) => {
    if (device) {
      setPanels(getPanelsWithoutTags({ data: device, deviceType: DeviceType.repeater }));
      setRowSelectedId(device.deviceId);
    } else {
      setPanels(undefined);
      setRowSelectedId('');
    }
  }, []);

  const handleMapTableModeChange = useCallback((mode: MapTableDisplayMode) => {
    setDisplayMode(mode);
  }, []);

  const handleOpenAddRepeater = useCallback(() => {
    setOpenAddRepeater(true);
  }, []);

  const handleOpenEditRepeater = useCallback(() => {
    setOpenEditRepeater(true);
    setDeviceId(rowSelectedId);
  }, [rowSelectedId]);

  const handleCloseAddRepeater = useCallback(() => {
    setOpenAddRepeater(false);
    setDeviceId(undefined);
  }, []);

  const handleCloseEditRepeater = useCallback(() => {
    setOpenEditRepeater(false);
    setDeviceId(undefined);
  }, []);

  const handleRemoveDevice = useCallback(async (deviceId: any) => {
    await removeDevice(deviceId);
    setRowSelectedId('');
  }, []);

  return (
    <Box sx={{ ...devicePageWrapper, gridTemplateRows: panels ? '52px 10fr 10fr' : '52px 10fr' }}>
      <Paper sx={deviceMenuWrapper}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <AppButton onClick={handleOpenAddRepeater}>Add Repeater</AppButton>
            <AppButton disabled={rowSelectedId ? false : true} onClick={handleOpenEditRepeater}>
              Edit Repeater
            </AppButton>
            <DeviceAlert
              buttonTitle="Delete"
              description="Do you want to delete this device?"
              deviceId={rowSelectedId}
              title={`Deleting device with id ${rowSelectedId}`}
              onDelete={handleRemoveDevice}
            />
          </Box>
          <MapTableToggle onChange={handleMapTableModeChange} />
        </Box>
      </Paper>
      {displayMode === 'table' ? (
        <DeviceTable
          alertFilter={alert}
          columns={repeatersColumns}
          deviceType={[DeviceType.repeater]}
          onRowClick={onCellClick}
        />
      ) : (
        <DevicesMap />
      )}
      {panels && <DeviceDetails panels={panels} />}
      <DeviceIdContext.Provider value={value}>
        <ModalWithSteps
          key="addRepeater"
          handleClose={handleCloseAddRepeater}
          open={openAddRepeater}
          stepCreator={createRepeaterSteps}
          title="Add Repeater"
        />
        <ModalWithSteps
          key="editRepeater"
          handleClose={handleCloseEditRepeater}
          open={openEditRepeater}
          stepCreator={createRepeaterSteps}
          title={`Edit Repeater: ${value.deviceId}`}
        />
      </DeviceIdContext.Provider>
    </Box>
  );
};

export const RepeatersPage = withMsalAuth(<Repeaters />);
