import { FC } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import theme from '../theme';
import { CustomTooltip } from '../tooltip/tooltip';

export type LabelFieldProps = {
  label: string;
  color?: string;
  labelTooltip?: string;
  children: React.ReactNode;
};

export const LabelField: FC<LabelFieldProps> = ({ label, labelTooltip, children, color }) => {
  const isValue = typeof children == 'string' || typeof children == 'number';

  return (
    <Box sx={{ display: 'flex', mb: 1 }}>
      <CustomTooltip title={labelTooltip}>
        <Typography sx={{ color: theme.color.gray, mr: 3 }}>{label}</Typography>
      </CustomTooltip>
      {isValue ? (
        <Typography sx={{ color: label.includes('Value') ? 'red' : color }}>{children}</Typography>
      ) : (
        children
      )}
    </Box>
  );
};
