import { FC, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { AppButton } from '../../../../common/buttons/buttons';
import { repeaterDefaultConfigurations } from '../../../../common/default-configurations/default-configurations';
import { QRCodeField } from '../../../../common/qr-reader/qr-reader';
import { DeviceIdContext } from '../../../../common/store/device-id-context';
import { getDevice } from '../../../../services/api/device';
import { RequestQuery } from '../../../../services/react-query-request-names';
import { Device, ErrorResponse, RepeaterAddBody } from '../../../../services/types';
import { addRepeater, updateRepeater } from '../api';
import { convertRawDataToFirstStepFormFields } from '../helpers';

type AddTagFirstStepProps = {
  handleNext: () => void;
  handleBack: () => void;
  closeModal: () => void;
  backButtonVisible: boolean;
  nextButtonVisible: boolean;
};

const schema = yup.object({
  id: yup.string().required('Please scan qr').matches(/^\d+$/, 'Value must be digits only'),
});

export type RepeaterFirstStepProps = yup.InferType<typeof schema>;

export const AddRepeaterFirstStep: FC<AddTagFirstStepProps> = ({
  handleNext,
  handleBack,
  closeModal,
  backButtonVisible,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
    watch,
  } = useForm<RepeaterFirstStepProps>({
    resolver: yupResolver(schema),
  });
  const { enqueueSnackbar } = useSnackbar();
  const { setDeviceId, deviceId } = useContext(DeviceIdContext);
  const watchDeviceId = watch('id');
  const queryClient = useQueryClient();

  useEffect(() => {
    if (deviceId) {
      getDevice(deviceId).then(res => {
        const initialValues = convertRawDataToFirstStepFormFields(res);
        reset(initialValues);
      });
    }
  }, [deviceId, reset, setValue]);

  const handleScan = (data: any) => {
    setValue('id', data, { shouldValidate: false });
  };
  const closeModalAndClearDeviceId = () => {
    setDeviceId(undefined);
    closeModal();
  };

  const handleRepeaterUpdated = (
    promise: Promise<Device>,
    successMessage: string,
    nextStep?: boolean,
  ) => {
    promise
      .then(() => {
        queryClient.invalidateQueries(RequestQuery.DeviceByType);
        enqueueSnackbar(successMessage, { variant: 'success' });
        nextStep ? handleNext() : closeModalAndClearDeviceId();
      })
      .catch(err => {
        if (err.response) {
          reset();
          setValue('id', '');
          setDeviceId(undefined);
          const error = err.response.data as ErrorResponse;
          enqueueSnackbar(error.errors[0].description, { variant: 'error' });
        }
      });
  };

  const onSubmit = ({ id, ...values }: RepeaterFirstStepProps, nextStep: boolean) => {
    setDeviceId(id);
    if (deviceId) {
      if (isDirty) {
        handleRepeaterUpdated(updateRepeater(values, id), 'Successfully update repeater', nextStep);
      } else {
        nextStep ? handleNext() : closeModalAndClearDeviceId();
      }
    } else {
      const addBody: RepeaterAddBody = {
        ...values,
        configurations: repeaterDefaultConfigurations,
      };
      handleRepeaterUpdated(addRepeater(addBody, id), 'Successfully added', nextStep);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <form>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 3fr 1fr' }}>
          <Box />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {deviceId === undefined ? (
              <QRCodeField isActive={watchDeviceId ? false : true} setQrCode={handleScan} />
            ) : null}
            <Controller
              control={control}
              name="id"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={deviceId ? true : false}
                  error={error ? true : false}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: true }}
                  label="Serial Number"
                  required
                />
              )}
            />
          </Box>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
          {backButtonVisible && <AppButton onClick={handleBack}>Back</AppButton>}
          <AppButton
            type="submit"
            onClick={handleSubmit((values: RepeaterFirstStepProps) => {
              onSubmit(values, false);
            })}
          >
            {deviceId ? 'Update' : 'Add to repository'}
          </AppButton>
          <AppButton
            endIcon={<ArrowForwardIcon />}
            onClick={handleSubmit((values: RepeaterFirstStepProps) => {
              onSubmit(values, true);
            })}
          >
            Attach to Gateway
          </AppButton>
        </Box>
      </form>
    </Box>
  );
};
