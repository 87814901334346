import { useMsal } from '@azure/msal-react';
import { Box, Typography } from '@mui/material';

import { AppButton } from '../../common/buttons/buttons';

export const SignOutButton = () => {
  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const emailClaimName = account
    ? (account?.idTokenClaims as any)?.['signInNames.emailAddress']
    : 'N/A';
  const handleLogout = () => {
    instance.logoutRedirect({ postLogoutRedirectUri: window.location.origin }).catch(e => {
      console.error(e);
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bottom: 64,
        gap: 1,
        alignItems: 'center',
        width: '100%',
        marginBottom: 1,
      }}
    >
      <Typography>{emailClaimName}</Typography>
      <AppButton variant="text" onClick={() => handleLogout()}>
        Sign out
      </AppButton>
    </Box>
  );
};
