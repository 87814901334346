import { GridRowModel } from '@mui/x-data-grid';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import {
  transformBatvoltToBattery,
  transformCellRSSIToSignalQuality,
} from '../../../components/devices/gateways/helper';
import {
  DeviceAlertType,
  DeviceConnectionState,
  DeviceData,
  Devices,
  DeviceType,
  GatewayMetadata,
  GatewayStatus,
  RepeatersMetadata,
  RepeaterStatus,
  TagsMetadata,
  TagsStatus,
} from '../../../services/types';

function formatDistance(distance: number) {
  if (distance < 1000) {
    return `${Math.round(distance)} m`;
  } else {
    return `${(distance / 1000).toFixed(2)} km`;
  }
}

const isConnected = (device: DeviceData) => {
  return (
    !device.alerts?.includes(DeviceAlertType.outOfNetwork) &&
    device.gatewayConnectionState != DeviceConnectionState.offline
  );
};

export const transformDataForTable = (
  devices: Devices,
  deviceType: DeviceType[],
): GridRowModel[] => {
  if (deviceType.length === 1) {
    if (deviceType[0] === DeviceType.gateway) {
      return devices.data.data.map(device => {
        const deviceStatus = device?.status as GatewayStatus;
        return {
          id: device.deviceId,
          deviceId: device.deviceId,
          type: 'Gateway',
          fwVersion: device.fwVersion,
          grower: device?.metadata?.grower,
          reseller: (device?.metadata as GatewayMetadata)?.reseller,
          distance: device?.distance ? formatDistance(device?.distance) : '',
          location: device?.location ? `${device?.location?.lat}, ${device?.location?.long}` : '',
          status: {
            alerts: device?.alerts,
            connection: device?.gatewayConnectionState,
            deviceType: DeviceType.gateway,
          },
          batvolt: {
            value: transformBatvoltToBattery(DeviceType.gateway, deviceStatus?.batvolt),
            millivolt: deviceStatus?.batvolt,
          },
          cellGen: deviceStatus?.CellGen,
          cellRSSI: deviceStatus?.CellRSSI,
          signal: isConnected(device)
            ? transformCellRSSIToSignalQuality(deviceStatus?.CellGen, deviceStatus?.CellRSSI)
            : undefined,
          ts: device?.gatewayLastSeen
            ? format(parseISO(device?.gatewayLastSeen), 'MMM dd yyyy HH:mm')
            : '',
        };
      });
    }
    if (deviceType[0] === DeviceType.repeater) {
      return devices.data.data.map(device => {
        return {
          id: device.deviceId,
          deviceId: device.deviceId,
          gwId: device.gatewayId,
          fwVersion: device.fwVersion,
          grower: device?.metadata?.grower,
          plot: (device.metadata as RepeatersMetadata)?.plot,
          status: {
            alerts: device?.alerts,
            connection: device?.gatewayConnectionState,
            deviceType: DeviceType.repeater,
            groupId: device.gatewayId,
          },
          batvolt: {
            value: transformBatvoltToBattery(DeviceType.repeater, device?.status?.batvolt),
            millivolt: device?.status?.batvolt,
          },
          inputSignal: isConnected(device) ? (device?.status as RepeaterStatus)?.gwRssi : undefined,
          outputSignal: isConnected(device)
            ? (device?.status as RepeaterStatus)?.rfRssi
            : undefined,
          ulNode: (device?.status as RepeaterStatus)?.ulNode,
          ts: device?.status?.ts ? format(parseISO(device.status.ts), 'MMM dd yyyy HH:mm') : '',
        };
      });
    }

    if (deviceType[0] === DeviceType.tag) {
      return devices.data.data.map(device => {
        return {
          id: device.deviceId,
          deviceId: device.deviceId,
          fwVersion: device.fwVersion,
          gwId: device.gatewayId,
          grower: device?.metadata?.grower,
          plot: (device.metadata as TagsMetadata)?.plot,
          status: {
            alerts: device?.alerts,
            connection: device?.gatewayConnectionState,
            deviceType: DeviceType.tag,
            groupId: device.gatewayId,
          },
          batvolt: {
            value: transformBatvoltToBattery(DeviceType.tag, device?.status?.batvolt),
            millivolt: device?.status?.batvolt,
          },
          inputSignal: isConnected(device) ? (device?.status as TagsStatus)?.gwRssi : undefined,
          outputSignal: isConnected(device) ? (device?.status as TagsStatus)?.rfRssi : undefined,
          ulNode: (device?.status as TagsStatus)?.ulNode,
          ts: device?.status?.ts ? format(parseISO(device.status.ts), 'MMM dd yyyy HH:mm') : '',
        };
      });
    }
  }
  return [{ error: 'device type is incorrect' }];
};
