import { FC, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { updateRepeater } from '../../../components/devices/repeaters/api';
import { SignalBatteryInfo } from '../../../components/devices/tags/add-edit-tag/signal-battery';
import { numberOrEmptyString } from '../../../schemas/schemas';
import { getDevice } from '../../../services/api/device';
import { RequestQuery } from '../../../services/react-query-request-names';
import { DeviceType, ErrorResponse } from '../../../services/types';
import { AppButton } from '../../buttons/buttons';
import { useDeviceInstallationMode } from '../../hooks/instalation-mode';
import { LabelField } from '../../label-field/label-field';
import { TagGwIdsContext } from '../../store/tag-gw-device-id-context';
import { convertRawDataToSecondStepFormFields } from './helpers';

type AssociateRepeaterSecondStepProps = {
  handleBack: () => void;
  closeModal: () => void;
};

enum SecondStepFieldName {
  'plot' = 'metadata.plot',
  'comment' = 'metadata.comment',
  'lat' = 'location.lat',
  'long' = 'location.long',
}
const secondStepFields = [
  { label: 'Latitude', fieldName: SecondStepFieldName.lat },
  { label: 'Longitude', fieldName: SecondStepFieldName.long },
  { label: 'Plot', fieldName: SecondStepFieldName.plot },
  { label: 'Comment', fieldName: SecondStepFieldName.comment },
];

const schema = yup
  .object()
  .shape({
    gateway: yup.object().shape({
      id: yup.string().optional(),
    }),
    location: yup.object().shape({
      lat: numberOrEmptyString(yup.number()),
      long: numberOrEmptyString(yup.number()),
    }),
    metadata: yup.object().shape({
      plot: yup.string().optional(),
      comment: yup.string().optional(),
    }),
  })
  .required();

export type SecondStepProps = yup.InferType<typeof schema>;

export const AssociateRepeaterSecondStep: FC<AssociateRepeaterSecondStepProps> = ({
  closeModal,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    tagGwIds: { tagId, gwId },
  } = useContext(TagGwIdsContext);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<SecondStepProps>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (tagId) {
      getDevice(tagId).then(res => {
        const initialValues = convertRawDataToSecondStepFormFields(res);
        reset({ ...initialValues, gateway: { id: gwId } });
      });
    }
  }, [tagId, reset, setValue, gwId]);

  useDeviceInstallationMode(tagId);

  const onSubmit = (values: SecondStepProps) => {
    if (tagId) {
      if (isDirty) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { gateway, ...data } = values;
        updateRepeater(data, tagId)
          .then(() => {
            queryClient.invalidateQueries(RequestQuery.DeviceByType);
            enqueueSnackbar('Successfully associate', { variant: 'success' });
            closeModal();
          })
          .catch(err => {
            if (err.response) {
              const error = err.response.data as ErrorResponse;
              enqueueSnackbar(error?.errors ? error?.errors[0]?.description : 'Error 500', {
                variant: 'error',
              });
            }
          });
      } else {
        closeModal();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {gwId && <LabelField label="GW id"> {gwId}</LabelField>}
          {tagId && <LabelField label="Tag id"> {tagId} </LabelField>}
          <SignalBatteryInfo deviceId={tagId} deviceType={DeviceType.repeater} />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {secondStepFields.map(({ label, fieldName }, index) => (
            <Controller
              key={index}
              control={control}
              name={fieldName}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={error ? true : false}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: field.value !== undefined ? true : false }}
                  label={label}
                  variant="outlined"
                />
              )}
            />
          ))}

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
            <AppButton type="submit">Associate</AppButton>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
