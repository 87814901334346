import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid';

import {
  DeviceAlertType,
  DeviceConnectionState,
  DeviceSelectAlertType,
  DeviceType,
  Filters,
} from '../../../services/types';
import { metadataFields, statusFields } from '../api';

const operators = {
  '=': 'equal',
  '>': 'biggerThan',
  '>=': 'biggerOrEqualThan',
  '<': 'smallerThan',
  '<=': 'smallerOrEqualThan',
  contains: 'contains',
  equals: 'equal',
};

const convertDistanceFilterField = (filter: GridFilterItem) => {
  if (filter.operatorValue === '<') {
    return {
      maximum: filter.value,
    };
  }
  if (filter.operatorValue === '>') {
    return {
      minimum: filter.value,
    };
  }
};

const convertAlertFilterField = (filter: GridFilterItem) => {
  switch (filter.value) {
    case DeviceSelectAlertType.lowBattery:
      return [DeviceAlertType.lowBattery];
    case DeviceSelectAlertType.lowLTE:
      return [DeviceAlertType.lowLTE];
    case DeviceSelectAlertType.outOfNetwork:
      return [DeviceAlertType.outOfNetwork];
    case DeviceSelectAlertType.lowRssi:
      return [DeviceAlertType.lowRssi];
  }
};

export const convertBatvoltLevelToNumber = (filter: GridFilterItem, deviceType?: DeviceType) => {
  const currentFilter = {
    field: filter.columnField,
    operation: operators[filter.operatorValue as keyof typeof operators],
    value: filter.value,
  };
  if (deviceType === DeviceType.gateway) {
    switch (filter.value) {
      case '1':
        currentFilter.value = 12.24;
        return currentFilter;
      case '2':
        currentFilter.value = 12.36;
        return currentFilter;
      case '3':
        currentFilter.value = 12.48;
        return currentFilter;
      case '4':
        currentFilter.value = 12.72;
        return currentFilter;
      case '5':
        currentFilter.value = 12.9;
        return currentFilter;
      default:
        currentFilter.value = 12.9;
        return currentFilter;
    }
  }
  if (deviceType === DeviceType.tag) {
    switch (filter.value) {
      case '1':
        currentFilter.value = 2.5;
        return currentFilter;
      case '2':
        currentFilter.value = 2.7;
        return currentFilter;
      case '3':
        currentFilter.value = 2.85;
        return currentFilter;
      case '4':
        currentFilter.value = 2.95;
        return currentFilter;
      case '5':
        currentFilter.value = 3.0;
        return currentFilter;
      default:
        currentFilter.value = 3.0;
        return currentFilter;
    }
  }
  if (deviceType === DeviceType.repeater) {
    switch (filter.value) {
      case '1':
        currentFilter.value = 3.82;
        return currentFilter;
      case '2':
        currentFilter.value = 3.78;
        return currentFilter;
      case '3':
        currentFilter.value = 3.75;
        return currentFilter;
      case '4':
        currentFilter.value = 3.7;
        return currentFilter;
      case '5':
        currentFilter.value = 3.2;
        return currentFilter;
      default:
        currentFilter.value = 3.2;
        return currentFilter;
    }
  }
};

export const TransformFilters = (
  filters: GridFilterModel,
  deviceType?: DeviceType,
): Filters | undefined => {
  let notEmpty = false;
  // eslint-disable-next-line no-var
  var convertedFilters: Filters = {
    filters: {
      status: [],
      metadata: [],
    },
  };

  filters.items.forEach(filter => {
    const convertedOperator = operators[filter.operatorValue as keyof typeof operators] || 'equal';
    if (filter.value) {
      const isMetaField = metadataFields.includes(filter.columnField);
      const isStatusField = statusFields.includes(filter.columnField);

      const currentFilter = {
        field: filter.columnField,
        operation: convertedOperator,
        value: filter.value,
      };

      if (isMetaField && filter.value) {
        convertedFilters.filters.metadata?.push(currentFilter);
        notEmpty = true;
      }
      if (isStatusField && filter.value) {
        if (filter.columnField === 'batvolt') {
          convertedFilters.filters.status?.push(
            convertBatvoltLevelToNumber(filter, deviceType) || {},
          );
          notEmpty = true;
        } else {
          convertedFilters.filters.status?.push(currentFilter);
          notEmpty = true;
        }
      }

      if (filter.columnField === 'distance') {
        convertedFilters.filters.distance = convertDistanceFilterField(filter);
        notEmpty = true;
      }

      if (filter.columnField === 'status') {
        if (filter.value === DeviceSelectAlertType.gwOffline) {
          convertedFilters.filters.connectionState = DeviceConnectionState.offline;
          notEmpty = true;
        } else {
          convertedFilters.filters.alerts = convertAlertFilterField(filter);
          notEmpty = true;
        }
      }

      switch (filter.columnField) {
        case 'deviceId':
          convertedFilters.filters.deviceId = {
            operation: convertedOperator,
            value: filter.value,
          };
          notEmpty = true;
          break;
        case 'fwVersion':
          convertedFilters.filters.currentVersion = filter.value;
          notEmpty = true;
          break;
        case 'gwId':
          convertedFilters.filters.gateway = {
            operation: convertedOperator,
            value: filter.value,
          };
          notEmpty = true;
          break;
      }
    }
  });

  if (notEmpty) {
    return convertedFilters;
  } else {
    return undefined;
  }
};
