import { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Devices } from '../../../../services/types';

type UpdateFWAlertProps = {
  devices?: Devices;
  onClose: () => void;
};

export const UpdateFWAlert: FC<UpdateFWAlertProps> = ({ devices, onClose }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const isPending = devices?.data.data.some(device => (device.fwVersion?.pending ? true : false));
    if (isPending) {
      setOpen(true);
    }
  }, [devices]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">Update FW is Unavailable</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Gateway is currently updating. Please wait until all devices will be updated{' '}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
