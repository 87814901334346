import { apiClient } from '../../services/client/axios-client';
import { Devices, DeviceType, Filters, Pagination, Sorts } from '../../services/types';

export const metadataFields = ['grower', 'reseller', 'location', 'plot'];
export const statusFields = [
  'ts',
  'alerts',
  'CellGen',
  'CellRSSI',
  'batvolt',
  'charging',
  'dlPer',
  'logQlength',
  'ulNode',
];
export const commonFields = [
  'gateway',
  'gwId',
  'creationDate',
  'connectionState',
  'deviceType',
  'deviceId',
  'fwVersion',
  'distance',
];

export type GetDevicesProps = {
  deviceType?: DeviceType[];
  filters?: Filters;
  sorts?: Sorts;
  gatewayId?: string;
  pagination?: Pagination;
  coordinates?: { lat?: number; long?: number };
};

export const getDevices = async ({
  deviceType: deviceTypeArray,
  filters,
  sorts,
  pagination,
  coordinates,
  gatewayId,
}: GetDevicesProps) => {
  const gateway = gatewayId
    ? {
        operation: 'equal',
        value: gatewayId,
      }
    : undefined;
  const deviceType = deviceTypeArray
    ? {
        operation: 'equal',
        value: deviceTypeArray[0],
      }
    : undefined;

  const res = await apiClient
    .post<Devices>('/devices/query', {
      ...pagination,
      filters: {
        gateway,
        ...filters?.filters,
        deviceType,
      },
      orderBy: sorts?.orderBy ? [sorts?.orderBy] : undefined,
      locationReference: {
        lat: coordinates?.lat,
        long: coordinates?.long,
      },
    })
    .then(response => response.data);
  return res;
};
