import { useQuery } from 'react-query';

import { getLocalTagConfig } from '../../../components/devices/tags/add-edit-tag/helpers';
import { convertRawDataToFirstStepFormFields } from '../../../components/devices/tags/helpers';
import { getDevice, getGwConfigs, getRepeaterConfigs, getTagConfigs } from '../../../services/api';
import { RequestQuery } from '../../../services/react-query-request-names';
import { DeviceType } from '../../../services/types';
import {
  GwConfigsOutput,
  RepeaterConfigsOutput,
  TagConfigsOutput,
} from '../../../services/types/config';
import { SensorSelectTypeOptions } from '../../store/context-components/sensor-context/sensor-context';
import { convertGwConfigs, convertRepeaterConfigs, convertTagConfigs } from './helpers';
import { ConfigValue } from './types';

export interface ConfigData {
  uiConfigs: ConfigValue[];
  rawConfigs: GwConfigsOutput | TagConfigsOutput | RepeaterConfigsOutput;
}

export function useConfigData(
  deviceId: string,
  deviceType: DeviceType,
  localSensorSelectOptions: SensorSelectTypeOptions,
): ConfigData | undefined {
  const { data } = useQuery<ConfigData>(
    [RequestQuery.DeviceConfigs, deviceType, deviceId],
    async () => {
      switch (deviceType) {
        case DeviceType.gateway: {
          const rawConfigs = await getGwConfigs(deviceId);
          return {
            rawConfigs,
            uiConfigs: convertGwConfigs(rawConfigs),
          };
        }

        case DeviceType.tag: {
          const rawConfigs = await getTagConfigs(deviceId);
          const deviceData = await getDevice(deviceId);
          const deviceMetaData = convertRawDataToFirstStepFormFields(deviceData);
          const localTagConfig = getLocalTagConfig(
            rawConfigs,
            localSensorSelectOptions,
            deviceId,
            deviceMetaData.metadata,
            undefined,
          );
          return {
            rawConfigs,
            uiConfigs: convertTagConfigs(rawConfigs, localTagConfig),
          };
        }

        case DeviceType.repeater: {
          const rawConfigs = await getRepeaterConfigs(deviceId);
          return {
            rawConfigs,
            uiConfigs: convertRepeaterConfigs(rawConfigs),
          };
        }
      }
    },
  );
  return data;
}
