import { Box, Typography } from '@mui/material';

import { withMsalAuth } from '../../../common/auth/auth';
import { DeviceTableAlertQuery, DeviceTypeRoutes } from '../../../services/types';
import { useAlertCounters } from './hooks';
import { LinkAlertItem } from './link-alert-item';

const wrapperStyles = {
  mt: {
    xs: '20px',
    lg: 15,
  },
  gridTemplateColumns: {
    xs: '1fr',
    lg: '1fr 1fr 1fr',
  },
  gap: {
    xs: '20px',
    lg: '0px',
  },
};

export const Dashboard = () => {
  const {
    gwLowBattery,
    gwLowLte,
    gwOffline,
    repeaterLowBattery,
    repeaterLowRssi,
    repeaterGwOffline,
    repeaterOutOfNetwork,
    tagLowBattery,
    tagLowRssi,
    tagGwOffline,
    tagOutOfNetwork,
  } = useAlertCounters();

  return (
    <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
      <Box display="flex" height={100} width={200}>
        <img alt="logo" src="/iop-logo.svg" />
      </Box>
      <Box display="grid" sx={wrapperStyles}>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Typography marginBottom={5} variant="h3">
            Gateways
          </Typography>
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
            <LinkAlertItem
              alertType={DeviceTableAlertQuery.lowBattery}
              deviceType={DeviceTypeRoutes.gateways}
              label="Low battery voltage"
              value={gwLowBattery}
            />
            <LinkAlertItem
              alertType={DeviceTableAlertQuery.lowLTE}
              deviceType={DeviceTypeRoutes.gateways}
              label="Low signal"
              value={gwLowLte}
            />
            <LinkAlertItem
              alertType={DeviceTableAlertQuery.gwOffline}
              deviceType={DeviceTypeRoutes.gateways}
              label="Offline"
              value={gwOffline}
            />
          </Box>
        </Box>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Typography marginBottom={5} variant="h3">
            Tags
          </Typography>
          <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap={6}>
            <LinkAlertItem
              alertType={DeviceTableAlertQuery.lowBattery}
              deviceType={DeviceTypeRoutes.tags}
              label="Low battery voltage"
              value={tagLowBattery}
            />
            <LinkAlertItem
              alertType={DeviceTableAlertQuery.lowRssi}
              deviceType={DeviceTypeRoutes.tags}
              label="Low signal"
              value={tagLowRssi}
            />
            <LinkAlertItem
              alertType={DeviceTableAlertQuery.gwOffline}
              deviceType={DeviceTypeRoutes.tags}
              label="GW Offline"
              value={tagGwOffline}
            />
            <LinkAlertItem
              alertType={DeviceTableAlertQuery.outOfNetwork}
              deviceType={DeviceTypeRoutes.tags}
              label="Out of Network"
              value={tagOutOfNetwork}
            />
          </Box>
        </Box>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Typography marginBottom={5} variant="h3">
            Repeaters
          </Typography>
          <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap={6}>
            <LinkAlertItem
              alertType={DeviceTableAlertQuery.lowBattery}
              deviceType={DeviceTypeRoutes.repeaters}
              label="Low battery voltage"
              value={repeaterLowBattery}
            />
            <LinkAlertItem
              alertType={DeviceTableAlertQuery.lowRssi}
              deviceType={DeviceTypeRoutes.repeaters}
              label="Low signal"
              value={repeaterLowRssi}
            />
            <LinkAlertItem
              alertType={DeviceTableAlertQuery.gwOffline}
              deviceType={DeviceTypeRoutes.repeaters}
              label="GW Offline"
              value={repeaterGwOffline}
            />
            <LinkAlertItem
              alertType={DeviceTableAlertQuery.outOfNetwork}
              deviceType={DeviceTypeRoutes.repeaters}
              label="Out of Network"
              value={repeaterOutOfNetwork}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const DashboardPage = withMsalAuth(<Dashboard />);
