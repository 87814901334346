import { CreateStepsProps, Step } from '../../../common/types';
import { Device, RepeatersMetadata } from '../../../services/types';
import { AddRepeaterFirstStep, RepeaterFirstStepProps } from './add-edit-repeater/first-step';
import { AddRepeaterSecondStep, SecondStepProps } from './add-edit-repeater/second-step';
import { AddRepeaterThirdStep } from './add-edit-repeater/third-step';

export const createRepeaterSteps = ({
  handleBack,
  handleNext,
  handleCloseAndReset,
  backButtonVisible,
  nextButtonVisible,
}: CreateStepsProps): Step[] => [
  {
    label: 'Repeater',
    component: (
      <AddRepeaterFirstStep
        backButtonVisible={backButtonVisible}
        closeModal={handleCloseAndReset}
        handleBack={handleBack}
        handleNext={handleNext}
        nextButtonVisible={nextButtonVisible}
      />
    ),
  },
  {
    label: 'Attach Gateway',
    component: (
      <AddRepeaterSecondStep
        backButtonVisible={backButtonVisible}
        closeModal={handleCloseAndReset}
        handleBack={handleBack}
        handleNext={handleNext}
        nextButtonVisible={nextButtonVisible}
      />
    ),
  },
  {
    label: 'Repeater Info',
    component: <AddRepeaterThirdStep closeModal={handleCloseAndReset} handleBack={handleBack} />,
  },
];

export const convertRawDataToFirstStepFormFields = (data: Device): RepeaterFirstStepProps => {
  return {
    id: data.data.deviceId,
  };
};

export const convertRawDataToSecondStepFormFields = (data: Device): SecondStepProps => {
  return {
    gateway: {
      id: data.data?.gatewayId,
    },
  };
};

export const convertRawDataToThirdStepFormFields = (data: Device): SecondStepProps => {
  return {
    location: {
      long: data.data?.location?.long,
      lat: data.data?.location?.lat,
    },
    metadata: {
      plot: (data.data.metadata as RepeatersMetadata)?.plot,
      comment: (data.data.metadata as RepeatersMetadata)?.comment,
    },
  };
};
