import { FC, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import ClearIcon from '@mui/icons-material/Clear';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, CircularProgress, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

import { AlertButton } from '../../common/alert/alert-button';
import { withMsalAuth } from '../../common/auth/auth';
import { AppButton } from '../../common/buttons/buttons';
import theme from '../../common/theme';
import { RequestQuery } from '../../services/react-query-request-names';
import { DeviceType, FwVersion } from '../../services/types';
import { getLatestVersions } from './api';
import { useDeleteVersionHandler } from './hooks';
import { UploadGwVersionModal } from './upload-gw-version-modal';
import { UploadTagOrRepeaterVersionModal } from './upload-tag-repeater-version-modal';

type TypeVersions = Map<DeviceType, FwVersion | null>;

interface DeviceLatestVersionProps {
  versionNumber: string | undefined;
  isLoading?: boolean;
}

export const DeviceLatestVersion: FC<DeviceLatestVersionProps> = ({ versionNumber, isLoading }) => {
  return isLoading ? (
    <CircularProgress sx={{ marginBottom: 5 }} />
  ) : (
    <Box sx={{ display: 'flex', mb: 5 }}>
      <Typography sx={{ color: theme.color.gray, mr: 1 }} variant="h5">
        Version :
      </Typography>
      <Typography variant="h5">{versionNumber}</Typography>
    </Box>
  );
};

interface TypeVersionProps {
  isLoading: boolean;
  data: TypeVersions | undefined;
  deviceType: DeviceType;
  setOpenedModal: (type: DeviceType) => void;
  title: string;
}

const IconStyle: SxProps = { fontSize: 32, marginY: 0.2 };

const wrapperStyles = {
  mt: {
    xs: '20px',
    md: 15,
  },
  gridTemplateColumns: {
    xs: '1fr',
    md: '1fr 1fr 1fr',
  },
  gap: {
    xs: '20px',
    md: '0px',
  },
};

const TypeVersionView: FC<TypeVersionProps> = ({
  isLoading,
  data,
  deviceType,
  setOpenedModal,
  title,
}) => {
  const versionId = data?.get(deviceType)?.versionId;
  const onDeleteVersion = useDeleteVersionHandler(versionId);

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <Typography marginBottom={5} variant="h3">
        {title}
      </Typography>
      <DeviceLatestVersion
        isLoading={isLoading}
        versionNumber={data?.get(deviceType)?.versionNumber}
      />
      <Box sx={{ display: 'flex' }}>
        <Box mr={3}>
          <AlertButton
            buttonProps={{
              color: 'warning',
              sx: { outlineColor: 'red' },
            }}
            description="Are you sure you want to delete latest version?"
            isDisabled={isLoading || !versionId}
            title="Delete Version"
            onConfirm={onDeleteVersion}
          >
            <ClearIcon sx={IconStyle} />
          </AlertButton>
        </Box>
        <Box>
          <AppButton
            disabled={isLoading}
            onClick={() => {
              setOpenedModal(deviceType);
            }}
          >
            <FileUploadIcon sx={IconStyle} />
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};

export const VersionsManagement = () => {
  const { isLoading, data } = useQuery<TypeVersions>([RequestQuery.FwVersions], async () => {
    return await getLatestVersions();
  });

  const [openedModal, setOpenedModal] = useState<DeviceType | null>(null);
  const closeModal = useCallback(() => {
    setOpenedModal(null);
  }, []);
  return (
    <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
      <Box display="grid" sx={wrapperStyles}>
        <TypeVersionView
          data={data}
          deviceType={DeviceType.gateway}
          isLoading={isLoading}
          setOpenedModal={setOpenedModal}
          title="Gateways"
        />
        <TypeVersionView
          data={data}
          deviceType={DeviceType.tag}
          isLoading={isLoading}
          setOpenedModal={setOpenedModal}
          title="Tags"
        />
        <TypeVersionView
          data={data}
          deviceType={DeviceType.repeater}
          isLoading={isLoading}
          setOpenedModal={setOpenedModal}
          title="Repeaters"
        />

        <UploadGwVersionModal
          currentVersion={data?.get(DeviceType.gateway)?.versionNumber}
          handleClose={closeModal}
          open={openedModal == DeviceType.gateway}
          title="Upload Gateway Version"
        />
        <UploadTagOrRepeaterVersionModal
          currentVersion={data?.get(DeviceType.tag)?.versionNumber}
          deviceType={DeviceType.tag}
          handleClose={closeModal}
          open={openedModal == DeviceType.tag}
          title="Upload Tag Version"
        />
        <UploadTagOrRepeaterVersionModal
          currentVersion={data?.get(DeviceType.repeater)?.versionNumber}
          deviceType={DeviceType.repeater}
          handleClose={closeModal}
          open={openedModal == DeviceType.repeater}
          title="Upload Repeater Version"
        />
      </Box>
    </Box>
  );
};

export const VersionsManagementPage = withMsalAuth(<VersionsManagement />);
