import { AxiosResponse } from 'axios';

import { apiClient } from '../../../services/client/axios-client';
import { Device, DeviceType, RepeaterAddBody, RepeaterUpdateBody } from '../../../services/types';

export const addRepeater = async (props: RepeaterAddBody, id: string) => {
  const response = await apiClient.post<
    Device,
    AxiosResponse<Device, RepeaterAddBody>,
    RepeaterAddBody
  >(`/devices/${id}`, {
    ...props,
    type: DeviceType.repeater,
  });
  return response.data;
};

export const updateRepeater = async (props: RepeaterUpdateBody, id: string) => {
  const response = await apiClient.put<
    Device,
    AxiosResponse<Device, RepeaterUpdateBody>,
    RepeaterUpdateBody
  >(`/devices/${id}`, {
    ...props,
  });
  return response.data;
};
