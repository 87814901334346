import { useEffect } from 'react';
import { InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';

import { loginRequest } from '../../authConfig';
import { msalInstance } from '../msal/msal-instance';

const getConfig = () => {
  const config = {
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  } as any;

  return config;
};

export const createApiClient = () => {
  const client = axios.create({
    ...getConfig(),
  });
  return client;
};

export const apiClient = createApiClient();

apiClient.interceptors.request.use(
  async function (config) {
    try {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: msalInstance.getAllAccounts()[0],
      });
      config.headers = { Authorization: `Bearer ${response.accessToken}` };
    } catch {
      (error: any) => {
        if (error instanceof InteractionRequiredAuthError) {
          return msalInstance.acquireTokenRedirect(loginRequest);
        }
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export const useSetTokenAndActiveAccount = (pca: IPublicClientApplication) => {
  useEffect(() => {
    if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
      pca.setActiveAccount(pca.getAllAccounts()[0]);
    }
    pca.enableAccountStorageEvents();
  }, [pca]);
};
