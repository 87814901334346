import {
  transformBatteryToIcon,
  transformBatvoltToBattery,
  transformCellRSSIToSignalQuality,
  transformSignalQualityToIcon,
} from '../../../components/devices/gateways/helper';
import {
  DeviceData,
  DeviceType,
  GatewayMetadata,
  GatewayStatus,
  RepeatersMetadata,
  RepeaterStatus,
  TagsMetadata,
  TagsStatus,
} from '../../../services/types';
import { StatusCell } from '../../device-table/cells';
import { LabeledField } from './types';

const concatStrings = (...args: any[]) => args.filter(Boolean).join(', ');

export const createGatawayFields = (data: DeviceData): LabeledField[] => {
  const metadata = data.metadata as GatewayMetadata;
  const status = data.status as GatewayStatus;

  return [
    { label: 'ID', value: data.deviceId },
    { label: 'IMSI', value: metadata?.imsi ?? '' },
    { label: 'GW Type', value: metadata?.gwType ?? '' },
    { label: 'Grower', value: metadata?.grower ?? '' },
    { label: 'Resseler', value: metadata?.reseller ?? '' },
    { label: 'Name', value: metadata?.name ?? '' },
    { label: 'Phone number', value: metadata?.phoneNumber ?? '' },
    { label: 'Service Provider', value: metadata?.serviceProvider ?? '' },
    {
      label: 'Status',
      value: StatusCell({
        alerts: data.alerts,
        connection: data?.gatewayConnectionState,
      }),
    },
    {
      label: 'Signal',
      value: transformSignalQualityToIcon(
        transformCellRSSIToSignalQuality(status?.CellGen, status?.CellRSSI),
        '24px',
      ),
    },
    {
      label: 'Battery',
      value: transformBatteryToIcon(
        transformBatvoltToBattery(DeviceType.gateway, status?.batvolt),
        '24px',
        status?.batvolt,
      ),
    },
  ];
};

export const createTagsFields = (data: DeviceData): LabeledField[] => {
  const metadata = data.metadata as TagsMetadata;
  const status = data.status as TagsStatus;
  return [
    { label: 'ID', value: data.deviceId },
    { label: 'Gateway ID', value: data.gatewayId },
    { label: 'Analog 1', value: metadata?.analog1 ?? '' },
    { label: 'Analog 1 Value', value: metadata?.analog1_value ?? '' },
    { label: 'Analog 2', value: metadata?.analog2 ?? '' },
    { label: 'Analog 2 Value', value: metadata?.analog2_value ?? '' },
    { label: 'Digital', value: metadata?.digital ?? '' },
    { label: 'Digital Value', value: metadata?.digital_value ?? '' },
    { label: 'Comment', value: metadata?.comment ?? '' },
    { label: 'Location', value: concatStrings(data?.location?.lat, data?.location?.long) },
    {
      label: 'Battery',
      value: transformBatteryToIcon(
        transformBatvoltToBattery(DeviceType.tag, status?.batvolt),
        '24px',
        status?.batvolt,
      ),
    },
    { label: 'Signal', value: status?.gwRssi },
    {
      label: 'Status',
      value: StatusCell({
        alerts: data.alerts,
        connection: data?.gatewayConnectionState,
      }),
    },
  ];
};

export const createRepeatersFields = (data: DeviceData): LabeledField[] => {
  const metadata = data.metadata as RepeatersMetadata;
  const status = data.status as RepeaterStatus;

  return [
    { label: 'ID', value: data.deviceId },
    { label: 'Gateway ID', value: data?.gatewayId },
    { label: 'Comment', value: metadata?.comment ?? '' },
    { label: 'Location', value: concatStrings(data?.location?.lat, data?.location?.long) },

    {
      label: 'Battery',
      value: transformBatteryToIcon(
        transformBatvoltToBattery(DeviceType.repeater, status?.batvolt),
        '24px',
        status?.batvolt,
      ),
    },
    { label: 'Input signal', value: status?.gwRssi },
    { label: 'Output signal', value: status?.rfRssi },
    {
      label: 'Status',
      value: StatusCell({
        alerts: data.alerts,
        connection: data?.gatewayConnectionState,
      }),
    },
  ];
};
