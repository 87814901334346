import { FC } from 'react';
import CurrentLocationIcon from '@mui/icons-material/MyLocation';
import { SxProps } from '@mui/material';

type LocationButtonProps = {
  onClick: (latitude: number, longitude: number) => void;
  sx?: SxProps;
};

export const LocationButton: FC<LocationButtonProps> = props => {
  const handleClick = () => {
    navigator.geolocation.getCurrentPosition(position => {
      props.onClick(position.coords.latitude, position.coords.longitude);
    });
  };

  return (
    <CurrentLocationIcon
      sx={props.sx || { cursor: 'pointer', alignSelf: 'center' }}
      onClick={handleClick}
    />
  );
};
